import React from 'react';
import { DEFAULT_LOCALE, getLocale } from '../../duck/localization.duck';
import { connect } from 'react-redux';
import { IState } from '../../store/reducer';
import { formApi } from '../../api/sdk/form';
import { getApiVersion } from '../../duck/api.duck';
import {
    currentDirectoryChanged,
    directoryTreeUpdated,
    formListLoaded,
    getCurrentDirectoryId,
    pageCountChanged,
} from '../../duck/form.list.duck';
import { bindActionCreators, Dispatch } from 'redux';
import { interceptNetworkErrors } from '../../duck/alert.duck';

type DirectoryProviderComponentProps = {
    children?: JSX.Element | JSX.Element[];
    apiVersion: number;
    currentDirectoryId: number;
    currentDirectoryChanged: typeof currentDirectoryChanged;
    formListLoaded: typeof formListLoaded;
    interceptNetworkErrors: typeof interceptNetworkErrors;
    locale: string;
    pageCountChanged: typeof pageCountChanged;
};

export const DirectoryContext = React.createContext({
    changeDirectory: async (directoryId: number) => directoryId,
    locale: DEFAULT_LOCALE,
});

class DirectoryProviderComponent extends React.Component<DirectoryProviderComponentProps> {
    context: React.Context<{
        changeDirectory: (directoryId: number) => Promise<number>;
    }>;

    changeDirectory = async (directoryId: number): Promise<number> => {
        const {
            apiVersion,
            currentDirectoryId,
            currentDirectoryChanged,
            formListLoaded,
            interceptNetworkErrors,
            locale,
            pageCountChanged,
        } = this.props;

        if (directoryId !== currentDirectoryId) {
            currentDirectoryChanged(directoryId);
            try {
                const { formList, pages } = await formApi.getFormList(
                    1,
                    apiVersion,
                    directoryId
                );
                formListLoaded({
                    forms: formList,
                    mapTags: true,
                });
                pageCountChanged(pages);
            } catch (e) {
                interceptNetworkErrors({
                    exception: e,
                    locale: locale,
                    message: 'alert.network.form_list_fetch_fail',
                });
            }
        }

        return directoryId;
    };

    render(): JSX.Element {
        return (
            <DirectoryContext.Provider
                value={{
                    changeDirectory: this.changeDirectory,
                    locale: this.props.locale,
                }}
            >
                {this.props.children}
            </DirectoryContext.Provider>
        );
    }
}

export const DirectoryProvider = connect(
    (state: IState) => ({
        apiVersion: getApiVersion(state),
        currentDirectoryId: getCurrentDirectoryId(state),
        locale: getLocale(state),
    }),
    (dispatch: Dispatch) =>
        bindActionCreators(
            {
                currentDirectoryChanged,
                directoryTreeUpdated,
                formListLoaded,
                interceptNetworkErrors,
                pageCountChanged,
            },
            dispatch
        )
)(DirectoryProviderComponent);
