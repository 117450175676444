import React from 'react';
import _truncate from 'lodash/truncate';
import { Badge, ButtonGroup } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { faTrash, faGlobe, faWrench } from '@fortawesome/free-solid-svg-icons';

import { Announcement } from '../../domain/model/announcement/announcement';
import {
    getColorByAnnouncementType,
    isAnnouncementActive,
} from '../../util/announcements';
import { CompactButton } from '../compact.button';

interface AnnouncementListProps {
    announcements: Announcement[];
    onDelete: (Announcement) => Promise<void>;
    onEdit: (Announcement) => void;
}

const DATE_DISPLAY_FORMAT = 'DD.MM.YYYY - HH:mm';

export const AnnouncementList = (props: AnnouncementListProps): JSX.Element => {
    const { announcements, onDelete, onEdit } = props;

    return (
        <div className="announcements">
            {announcements.map((announcement: Announcement) => (
                <React.Fragment key={announcement.id}>
                    <span
                        className={`announcements__type bg-${getColorByAnnouncementType(
                            announcement.type
                        )}`}
                    />
                    <div className="announcements__id">{announcement.id}</div>
                    <div className="announcements__title">
                        {announcement.title}
                    </div>
                    <div className="announcements__message">
                        {_truncate(announcement.message, {
                            length: 120,
                        })}
                    </div>
                    <div className="announcements__dates">
                        <div className="announcements__date-start">
                            {(announcement.dateStart &&
                                dayjs(announcement.dateStart).format(
                                    DATE_DISPLAY_FORMAT
                                )) ||
                                ''}
                        </div>
                        <div className="announcements__date-end">
                            {(announcement.dateEnd &&
                                dayjs(announcement.dateEnd).format(
                                    DATE_DISPLAY_FORMAT
                                )) ||
                                ''}
                        </div>
                    </div>
                    <div className="announcements__actions">
                        <ButtonGroup>
                            <CompactButton
                                id={`announcement-action-edit-${announcement.id}`}
                                color={'info'}
                                onClick={() => {
                                    onEdit(announcement);
                                }}
                                icon={faWrench}
                                label={'announcements.edit.button'}
                            />
                            <CompactButton
                                id={`announcement-action-delete-${announcement.id}`}
                                color={'danger'}
                                onClick={() => {
                                    onDelete(announcement).then();
                                }}
                                label={'announcements.delete.button'}
                                icon={faTrash}
                            />
                        </ButtonGroup>
                        {isAnnouncementActive(announcement) && (
                            <Badge color="success">
                                <FontAwesomeIcon icon={faGlobe} />
                            </Badge>
                        )}
                    </div>
                </React.Fragment>
            ))}
        </div>
    );
};
