import React from 'react';
import { Announcement } from '../../domain/model/announcement/announcement';

interface AnnouncementContentProps {
    announcement: Announcement;
}

export const AnnouncementContent = ({
    announcement,
}: AnnouncementContentProps): JSX.Element => (
    <>
        <h3>{announcement.title}</h3>
        {announcement.message && <p>{announcement.message}</p>}
        {announcement.url && (
            <a
                target={'_blank'}
                rel={'noopener noreferrer'}
                href={announcement.url}
            >
                Details &gt;&gt;
            </a>
        )}
    </>
);
