import { User } from 'oidc-react';

export enum UserRoles {
    ROLE_USER = 'user',
    ROLE_ADMIN = 'admin',
}

export function isAdmin(user: User): boolean {
    return user.profile?.roles?.indexOf(UserRoles.ROLE_ADMIN) > -1;
}
