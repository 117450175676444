abstract class AbstractApi {
    static baseUrl = process.env.REACT_APP_API_BASE_URL;

    _url(path: string): string {
        return AbstractApi.baseUrl + path;
    }
}

class Api extends AbstractApi {
    version(): string {
        return this._url('/version');
    }

    authEnabled(): string {
        return this._url('/version/auth');
    }

    get announcement(): AnnouncementApi {
        return new AnnouncementApi();
    }

    get form(): FormApi {
        return new FormApi();
    }

    get revision(): RevisionApi {
        return new RevisionApi();
    }

    get tags(): TagApi {
        return new TagApi();
    }

    get directory(): DirectoryApi {
        return new DirectoryApi();
    }
}

class FormApi extends AbstractApi {
    _url(path: string): string {
        return super._url('/v2/form' + path);
    }

    base(): string {
        return this._url('');
    }

    binned(): string {
        return this._url('/binned');
    }

    list(page = 1): string {
        if (!page) return this._url('/list');
        return this._url(`/list?p=${page}`);
    }

    listDirectory(page = 1, directoryId = 1): string {
        if (!page) return this._url(`/list/${directoryId}`);
        return this._url(`/list/${directoryId}?p=${page}`);
    }

    id(id: string, path = ''): string {
        return this._url('/' + id + path);
    }

    move(id: string, directoryId = 1) {
        return this.id(id, `/move/${directoryId}`);
    }

    publish(id: string): string {
        return this.id(id, '/publish');
    }

    purge(id: string): string {
        return this.id(id, '/purge');
    }

    restore(id: string): string {
        return this.id(id, '/restore');
    }

    toggleTemplate(id: string): string {
        return this.id(id, '/toggle-is-template');
    }
}

class RevisionApi extends AbstractApi {
    _url(path: string): string {
        return super._url('/v2/revision' + path);
    }

    list(formId: string): string {
        return this._url(`/${formId}/list`);
    }

    stickify(revisionId: string): string {
        return this._url(`/${revisionId}/stickify`);
    }

    unstickify(revisionId: string): string {
        return this._url(`/${revisionId}/unstickify`);
    }

    restore(revisionId: string): string {
        return this._url(`/${revisionId}/restore`);
    }

    preview(revisionId: string): string {
        return this._url(`/${revisionId}/preview`);
    }
}

class TagApi extends AbstractApi {
    list(page = 1): string {
        return this._url(`/v2/tag/list?p=${page}`);
    }

    search(): string {
        return this._url('/v2/tag/search/forms');
    }

    searchSingle(tagId: number, page: number): string {
        return this._url(`/v2/tag/${tagId}/forms?p=${page}`);
    }
}

class AnnouncementApi {
    static baseUrl = process.env.REACT_APP_ANNOUNCEMENT_ENDPOINT;

    _url(path = ''): string {
        return AnnouncementApi.baseUrl + path;
    }

    active(): string {
        return this._url();
    }

    all(): string {
        return this._url('/all');
    }

    id(id: number): string {
        return this._url('/' + id);
    }
}

class DirectoryApi extends AbstractApi {
    _url(path: string): string {
        return super._url('/v2/directory' + path);
    }

    getDirectoryTree(): string {
        return this._url('/');
    }

    create(parentId = 0): string {
        const idString = parentId === 0 ? '' : parentId + '';
        return this._url(`/${idString}`);
    }

    delete(id: number): string {
        return this._url(`/${id}`);
    }

    move(id: number, newParentId: number): string {
        return this._url(`/${id}/move/${newParentId}`);
    }

    update(id: number): string {
        return this._url(`/${id}`);
    }
}

export const Routes = new Api();
