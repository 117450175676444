import { ITagValue } from '../../domain/model/form/tags';
import { request } from '../../lib/http/request';
import { jsonHeaders } from '../request/headers';
import { Routes } from '../routing/route';
import { IFormListItem } from '../../domain/model/form/form';
import { UserAwareSdkClient } from './user.aware.sdk.client';

class TagApi extends UserAwareSdkClient {
    async getTagList(page = 1): Promise<{ tags: ITagValue[] }> {
        const res = await request(Routes.tags.list(page), {
            method: 'GET',
            headers: jsonHeaders(this.user),
        });
        const tags = await res.json();
        tags.map((tag) => {
            tag.id = parseInt(tag.id, 10);
            return tag;
        });

        return {
            tags,
        };
    }

    async getFormsForTag(
        tagId: number,
        page: number
    ): Promise<{ forms: IFormListItem[]; pages: number }> {
        const res = await request(Routes.tags.searchSingle(tagId, page), {
            method: 'GET',
            headers: jsonHeaders(this.user),
        });
        const { data: forms, pages } = await res.json();

        return { forms, pages };
    }

    async getFormsForTags(
        tags: ITagValue[],
        page: number
    ): Promise<{ forms: IFormListItem[]; pages: number }> {
        if (!page) page = 1;
        const res = await request(Routes.tags.search(), {
            method: 'POST',
            headers: jsonHeaders(this.user),
            body: JSON.stringify({
                page,
                tags: tags.map((t) => t.id),
            }),
        });
        const { data: forms, pages } = await res.json();

        return { forms, pages };
    }
}

export const tagApi = new TagApi();
