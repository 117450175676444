export const authTranslations = {
    refresh: {
        success: {
            de: 'Login erneuert.',
        },
        failure: {
            de: 'Login konnte nicht erneuert werden! Bitte Fenster neu laden und ggf. Browser neu starten.',
        },
    },
    user: {
        badge: {
            hint: {
                admin: {
                    de: 'Authentifiziert als Admin.',
                },
                user: {
                    de: 'Authentifiziert.',
                },
                fake: {
                    de: 'Keine Authentifizierung.',
                },
                suffix: {
                    de: ' Klicken um login zu erneuern.',
                },
            },
        },
    },
};
