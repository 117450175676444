import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Announcement } from '../../domain/model/announcement/announcement';
import { announcementApi } from '../../api/sdk/announcement';
import { LocalizationContext } from '../localization/localization.provider';
import { bindActionCreators, Dispatch } from 'redux';
import { alertAdd, interceptNetworkErrors } from '../../duck/alert.duck';
import { connect } from 'react-redux';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { AnnouncementList } from './announcement.list';
import { AnnouncementForm } from './announcement.form';
import { CloseButton } from '../close.button';

interface AnnouncementEditorComponentProps {
    alertAdd: typeof alertAdd;
    isOpen: boolean;
    toggleOpen: (boolean) => void;
    interceptNetworkErrors: typeof interceptNetworkErrors;
}

interface AnnouncementEditorState {
    announcements: Announcement[];
    announcementEdited?: Announcement;
    view: AnnouncementEditorView;
}

enum AnnouncementEditorView {
    LIST,
    EDIT,
}

class AnnouncementEditorComponent extends React.Component<AnnouncementEditorComponentProps> {
    static contextType = LocalizationContext;

    state: AnnouncementEditorState = {
        announcements: [],
        announcementEdited: null,
        view: AnnouncementEditorView.LIST,
    };

    async componentDidMount(): Promise<void> {
        await this.getAnnouncements();
    }

    async getAnnouncements(toListView = false): Promise<void> {
        try {
            const announcements = await announcementApi.getAllAnnouncements();
            const updatedState = {
                announcements,
            };
            if (toListView) {
                updatedState['view'] = AnnouncementEditorView.LIST;
                updatedState['announcementEdited'] = null;
            }

            this.setState(updatedState);
        } catch (e) {
            this.props.interceptNetworkErrors({
                exception: e,
                locale: this.context.locale,
            });
        }
    }

    onDelete = async (announcement: Announcement): Promise<void> => {
        if (
            window.confirm(
                this.context.translate('announcements.delete.confirm')
            )
        ) {
            try {
                await announcementApi.deleteAnnouncement(announcement);
                const newAnnouncements = [...this.state.announcements];
                newAnnouncements.splice(
                    newAnnouncements.indexOf(announcement),
                    1
                );
                this.setState({
                    announcements: newAnnouncements,
                });
            } catch (e) {
                this.props.interceptNetworkErrors({
                    exception: e,
                    locale: this.context.locale,
                });
            }
        }
    };

    onEdit = (announcement: Announcement): void => {
        this.setState({
            view: AnnouncementEditorView.EDIT,
            announcementEdited: announcement,
        });
    };

    onEditFinished = (): void => {
        this.getAnnouncements(true).then();
    };

    render(): JSX.Element {
        const { alertAdd, isOpen, toggleOpen } = this.props;
        const { translate } = this.context;
        const { announcements, announcementEdited, view } = this.state;

        return (
            <Modal
                size="xl"
                isOpen={isOpen}
                className="text-center modal-dialog-centered"
            >
                <ModalHeader
                    className="d-flex justify-content-between"
                    close={<CloseButton toggleOpen={() => toggleOpen(false)} />}
                >
                    {translate('announcements.manage')}
                </ModalHeader>
                <ModalBody>
                    {view === AnnouncementEditorView.LIST && (
                        <AnnouncementList
                            announcements={announcements}
                            onDelete={this.onDelete}
                            onEdit={this.onEdit}
                        />
                    )}
                    {view === AnnouncementEditorView.EDIT && (
                        <AnnouncementForm
                            alertAdd={alertAdd}
                            announcement={announcementEdited}
                            interceptNetworkErrors={interceptNetworkErrors}
                            onEditFinished={this.onEditFinished}
                        />
                    )}
                </ModalBody>
                <ModalFooter>
                    {view === AnnouncementEditorView.LIST && (
                        <Button
                            color={'success'}
                            onClick={() => {
                                this.setState({
                                    view: AnnouncementEditorView.EDIT,
                                });
                            }}
                        >
                            <FontAwesomeIcon icon={faPlus} />
                            <span className="ml-2">
                                {translate('announcements.add')}
                            </span>
                        </Button>
                    )}
                    {view === AnnouncementEditorView.EDIT && (
                        <Button
                            color={'warning'}
                            onClick={() => {
                                this.setState({
                                    view: AnnouncementEditorView.LIST,
                                    announcementEdited: null,
                                });
                            }}
                        >
                            {translate('formAction.cancel')}
                        </Button>
                    )}
                </ModalFooter>
            </Modal>
        );
    }
}

export const AnnouncementEditor = connect(null, (dispatch: Dispatch) =>
    bindActionCreators(
        {
            alertAdd,
            interceptNetworkErrors,
        },
        dispatch
    )
)(AnnouncementEditorComponent);
