import { User } from 'oidc-react';
import { debug } from '../../util/debug';

export const jsonHeaders = (user: User = null): Headers => {
    const headers = {
        'content-type': 'application/json',
    };

    if (user) {
        headers['authorization'] = 'Bearer ' + user.id_token;
    } else {
        debug.info('no user for request!');
    }

    return new Headers(headers);
};

export const composeHeaders = (...headersList: Headers[]): Headers => {
    const headers = new Headers();

    for (const headersListItem of headersList) {
        headersListItem.forEach((value, key) => headers.append(key, value));
    }

    return headers;
};
