/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faArrowLeft,
    faBan,
    faClock,
    faGlobe,
    faSave,
    faStamp,
} from '@fortawesome/free-solid-svg-icons';
import { Button, ButtonGroup } from 'reactstrap';
import { Prompt } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';

import { translations } from '../../../translations/translations';
import { FORM_EDIT_STATUS, getEditStatus } from '../../../duck/form.edit.duck';
import { IState } from '../../../store/reducer';
import { getLocale } from '../../../duck/localization.duck';
import { getApiVersion } from '../../../duck/api.duck';
import { FormPreviewButton } from './form.preview.button';

interface ISidebarOwnProps {
    canManageTemplates: boolean;
    canPublish: boolean;
    canSave: boolean;
    children?: React.ReactChild;
    currentSlug: string;
    hasPreview: boolean;
    hasRevisions: boolean;
    isTemplate: boolean;
    onCancel();
    onPublish: () => any;
    onSubmit: () => any;
    onToggleTemplate: () => void;
    onWantsPreview: (usePublicRevision?: boolean) => void;
    onWantsRevisions: () => any;
}

interface ISidebarConnectProps {
    apiVersion: number;
    locale: string;
    editStatus: FORM_EDIT_STATUS;
}

type ISidebarProps = ISidebarOwnProps & ISidebarConnectProps;

export const SidebarComponent = (props: ISidebarProps): JSX.Element => {
    const {
        apiVersion,
        canManageTemplates,
        canPublish,
        canSave,
        children,
        currentSlug,
        editStatus,
        hasPreview = false,
        hasRevisions,
        isTemplate,
        locale,
        onPublish,
        onSubmit,
        onToggleTemplate,
        onWantsPreview,
        onWantsRevisions,
    } = props;

    return (
        <div className={'sidebar position-sticky'} style={{ top: '20px' }}>
            <ButtonGroup vertical={true}>
                {canPublish && (
                    <Button
                        color="success"
                        outline
                        aria-label={translations.formAction.publish[locale]}
                        onClick={onPublish}
                    >
                        <FontAwesomeIcon icon={faGlobe} className="mr-2" />
                        <span>{translations.formAction.publish[locale]}</span>
                    </Button>
                )}
                {canSave && (
                    <Button
                        color="success"
                        aria-label={translations.formAction.submit[locale]}
                        onClick={onSubmit}
                    >
                        <FontAwesomeIcon icon={faSave} className="mr-2" />
                        <span>{translations.formAction.submit[locale]}</span>
                    </Button>
                )}
                <Prompt
                    when={editStatus > 1}
                    message={translations.alert.cancel_confirm[locale]}
                />
                <LinkContainer
                    to={{
                        pathname: '/',
                        state: {
                            clearFormData: true,
                        },
                    }}
                    exact
                >
                    <Button
                        color={editStatus < 2 ? 'info' : 'danger'}
                        aria-label={
                            editStatus < 2
                                ? translations.formAction.back[locale]
                                : translations.formAction.cancel[locale]
                        }
                        outline={true}
                    >
                        <FontAwesomeIcon
                            icon={editStatus < 2 ? faArrowLeft : faBan}
                            className="mr-2"
                        />
                        <span>
                            {editStatus < 2
                                ? translations.formAction.back[locale]
                                : translations.formAction.cancel[locale]}
                        </span>
                    </Button>
                </LinkContainer>
            </ButtonGroup>

            <ButtonGroup vertical={true} className={'mt-5'}>
                {hasPreview && (
                    <FormPreviewButton
                        apiVersion={apiVersion}
                        currentSlug={currentSlug}
                        locale={locale}
                        onWantsPreview={() => onWantsPreview()}
                    />
                )}
                {hasPreview && (
                    <FormPreviewButton
                        apiVersion={apiVersion}
                        currentSlug={currentSlug}
                        label={translations.formAction.previewPublic}
                        locale={locale}
                        outline={true}
                        onWantsPreview={() => onWantsPreview(true)}
                    />
                )}
                {hasRevisions && (
                    <Button
                        color={'secondary'}
                        outline
                        onClick={onWantsRevisions}
                    >
                        <FontAwesomeIcon icon={faClock} />
                        <span>Revisionen</span>
                    </Button>
                )}
                {canManageTemplates && (
                    <Button
                        color={'warning'}
                        outline
                        onClick={onToggleTemplate}
                    >
                        <FontAwesomeIcon icon={faStamp} />
                        <span>
                            {isTemplate
                                ? translations.formAction.toggleTemplate.off[
                                      locale
                                  ]
                                : translations.formAction.toggleTemplate.on[
                                      locale
                                  ]}
                        </span>
                    </Button>
                )}
            </ButtonGroup>

            {children && <div className={'mt-5'}>{children}</div>}
        </div>
    );
};

export const Sidebar = connect((state: IState) => ({
    apiVersion: getApiVersion(state),
    editStatus: getEditStatus(state),
    locale: getLocale(state),
}))(SidebarComponent);
