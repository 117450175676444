import React from 'react';
import { connect } from 'react-redux';
import { User } from 'oidc-react';
import { bindActionCreators, Dispatch } from 'redux';
import { LinkContainer } from 'react-router-bootstrap';
import {
    faEdit,
    faFileCirclePlus,
    faFolderPlus,
    faShield,
    faShieldHalved,
    faTrash,
} from '@fortawesome/free-solid-svg-icons';

import { IState } from '../../store/reducer';
import { getApiVersion } from '../../duck/api.duck';
import {
    currentDirectoryChanged,
    directoryTreeUpdated,
    formListLoaded,
    getCurrentDirectoryId,
    getDirectoryTree,
    pageCountChanged,
} from '../../duck/form.list.duck';
import { getUser, isAuthenticated } from '../../duck/auth.duck';
import { AlertType, interceptNetworkErrors } from '../../duck/alert.duck';
import { Directory } from '../../domain/model/directory/directory';
import { Spinner } from '../alert/spinner';
import { ApiVersion } from '../../api/sdk/api-version';
import { directoryApi } from '../../api/sdk/directory';
import { DirectoryTree } from './directory.tree';
import { CompactButton } from '../compact.button';
import { DirectoryContext } from './directory.provider';
import { TreeItem } from '../../domain/model/lib/tree.item';
import { translate } from '../../duck/localization.duck';
import * as Routes from '../../routing/route';
import { isAdmin } from '../../domain/model/user/user.roles';

interface DirectoryTreeProps {
    apiVersion: number;
    currentDirectoryId: number;
    currentDirectoryChanged: typeof currentDirectoryChanged;
    directoryTree: Directory;
    directoryTreeUpdated: typeof directoryTreeUpdated;
    formListLoaded: typeof formListLoaded;
    interceptNetworkErrors: typeof interceptNetworkErrors;
    isAuthenticated: boolean;
    pageCountChanged: typeof pageCountChanged;
    user: User;
}

class DirectoryTreeScreenComponent extends React.Component<DirectoryTreeProps> {
    static contextType = DirectoryContext;

    componentDidMount(): void {
        const { apiVersion, currentDirectoryId, isAuthenticated } = this.props;

        if (isAuthenticated && apiVersion >= ApiVersion.DIRECTORIES) {
            directoryApi
                .getDirectoryTree()
                .then((rootDirectory: Directory) => {
                    this.props.directoryTreeUpdated(rootDirectory);
                    this.props.currentDirectoryChanged(currentDirectoryId);
                })
                .catch(interceptNetworkErrors);
        }
    }

    onCreateChild = async (): Promise<void> => {
        const {
            currentDirectoryId,
            directoryTreeUpdated,
            interceptNetworkErrors,
        } = this.props;
        const { locale } = this.context;

        const dirname = window.prompt(
            translate('directory.create.prompt', locale),
            translate('directory.create.default', locale)
        );

        if (dirname === null) {
            return;
        }
        try {
            const updatedTree = await directoryApi.create(
                {
                    name: dirname,
                    children: [],
                    readOnly: false,
                    adminProtection: false,
                },
                currentDirectoryId
            );
            directoryTreeUpdated(updatedTree);
        } catch (e) {
            interceptNetworkErrors({
                exception: e,
                message: 'directory.create.failed',
                locale: locale,
            });
        }
    };

    onDelete = async (): Promise<void> => {
        const {
            currentDirectoryId,
            directoryTreeUpdated,
            interceptNetworkErrors,
        } = this.props;
        const { locale } = this.context;

        if (window.confirm(translate('directory.delete.confirm', locale))) {
            try {
                const newTree = await directoryApi.delete(currentDirectoryId);
                directoryTreeUpdated(newTree);
            } catch (e) {
                interceptNetworkErrors({
                    exception: e,
                    message: 'directory.delete.failed',
                    locale,
                });
            }
        }
    };

    onMoveDrop = async (
        newTree,
        { dragSourceId, dropTargetId }
    ): Promise<void> => {
        const { directoryTreeUpdated, interceptNetworkErrors } = this.props;
        const { locale } = this.context;

        try {
            const updatedTree = await directoryApi.move(
                dragSourceId,
                dropTargetId
            );
            directoryTreeUpdated(updatedTree);
        } catch (e) {
            interceptNetworkErrors({
                exception: e,
                message: 'directory.move.failed',
                locale: locale,
            });
        }
    };

    onRename = async (): Promise<void> => {
        const {
            currentDirectoryId,
            directoryTree,
            directoryTreeUpdated,
            interceptNetworkErrors,
        } = this.props;
        const { locale } = this.context;

        const dir = this._findTreeItemById(directoryTree, currentDirectoryId);
        const newName = window.prompt(
            translate('directory.rename.prompt', locale),
            dir.name
        );

        if (newName === null) {
            return;
        }

        dir.name = newName;
        try {
            const updatedTree = await directoryApi.update(dir);
            directoryTreeUpdated(updatedTree);
        } catch (e) {
            interceptNetworkErrors({
                exception: e,
                message: 'directory.rename.failed',
                locale: locale,
            });
        }
    };

    onToggleProtect = async (): Promise<void> => {
        const {
            currentDirectoryId,
            directoryTree,
            directoryTreeUpdated,
            interceptNetworkErrors,
        } = this.props;
        const { locale } = this.context;

        const dir = this._findTreeItemById(directoryTree, currentDirectoryId);
        dir.adminProtection = !dir.adminProtection;

        try {
            const newTree = await directoryApi.update(dir);
            directoryTreeUpdated(newTree);
        } catch (e) {
            dir.adminProtection = !dir.adminProtection;
            interceptNetworkErrors({
                exception: e,
                message: 'directory.protect.failed',
                locale: locale,
            });
        }
    };

    getOnNodeClick = (node: TreeItem): ((Event) => Promise<void>) => {
        return async () => {
            this.context.changeDirectory(node.id);
        };
    };

    _findTreeItemById = (tree: Directory, id: number): Directory | null => {
        if (tree.id === id) {
            return tree;
        }

        if (tree.children?.length) {
            for (const child of tree.children) {
                const res = this._findTreeItemById(child, id);
                if (res !== null) {
                    return res;
                }
            }
        }

        return null;
    };

    render() {
        const { currentDirectoryId, directoryTree, user } = this.props;
        const currentDirectory =
            directoryTree &&
            this._findTreeItemById(directoryTree, currentDirectoryId);

        const isNotRoot = currentDirectoryId !== 1;
        const isAdminUser = isAdmin(user);
        const userCanEdit = !currentDirectory?.adminProtection || isAdminUser;

        const editable = isNotRoot && userCanEdit;
        const protectable = isNotRoot && isAdminUser;

        const protectionIcon = currentDirectory?.adminProtection
            ? faShield
            : faShieldHalved;
        const protectionClassName = currentDirectory?.adminProtection
            ? 'fa-crossed-out'
            : '';

        return directoryTree === null ? (
            <Spinner />
        ) : (
            <div>
                <div className="directories__toolbar">
                    {editable && (
                        <>
                            <CompactButton
                                id={'directory-rename'}
                                color={'warning'}
                                label={'directory.rename.button'}
                                icon={faEdit}
                                onClick={this.onRename}
                                small
                            />
                            <CompactButton
                                id={'directory-delete'}
                                color={AlertType.DANGER}
                                label={'directory.delete.button'}
                                icon={faTrash}
                                onClick={this.onDelete}
                                small
                            />
                        </>
                    )}
                    <CompactButton
                        id={'directory-add'}
                        color={'success'}
                        label={'directory.create.button'}
                        icon={faFolderPlus}
                        onClick={this.onCreateChild}
                        small
                    />
                    <LinkContainer
                        to={{
                            pathname: Routes.FORM_CREATE_PATH,
                            state: {
                                dir: currentDirectoryId,
                            },
                        }}
                        exact
                    >
                        <CompactButton
                            id={'directory-configuration-add'}
                            color={'success'}
                            label={'directory.add_config_here'}
                            icon={faFileCirclePlus}
                            small
                        />
                    </LinkContainer>
                    {protectable && (
                        <CompactButton
                            id={'directory-protect'}
                            color={AlertType.INFO}
                            label={'directory.protect.button'}
                            icon={protectionIcon}
                            small
                            onClick={this.onToggleProtect}
                            className={protectionClassName}
                        />
                    )}
                </div>
                <DirectoryTree
                    getOnNodeClick={this.getOnNodeClick}
                    onMoveDrop={this.onMoveDrop}
                    currentDirectoryId={currentDirectoryId}
                    directoryTree={directoryTree}
                />
            </div>
        );
    }
}

export const DirectoryTreeScreen = connect(
    (state: IState) => ({
        apiVersion: getApiVersion(state),
        currentDirectoryId: getCurrentDirectoryId(state),
        directoryTree: getDirectoryTree(state),
        isAuthenticated: isAuthenticated(state),
        user: getUser(state),
    }),
    (dispatch: Dispatch) =>
        bindActionCreators(
            {
                currentDirectoryChanged,
                directoryTreeUpdated,
                formListLoaded,
                interceptNetworkErrors,
                pageCountChanged,
            },
            dispatch
        )
)(DirectoryTreeScreenComponent);
