import React from 'react';
import { UncontrolledAlert } from 'reactstrap';

interface IAlertStickyTopProps {
    type: string;
    children?: React.ReactNode;
    onClose?: () => void;
    lifeSeconds?: number;
}

export class AlertStickyTop extends React.Component<IAlertStickyTopProps> {
    timerId: NodeJS.Timeout;

    static defaultProps = {
        lifeSeconds: 10,
    };

    componentDidMount(): void {
        const { onClose, lifeSeconds } = this.props;
        if (lifeSeconds) {
            this.timerId = setTimeout(onClose, 1000 * lifeSeconds);
        }
    }

    componentWillUnmount(): void {
        clearTimeout(this.timerId);
    }

    render(): JSX.Element {
        const { type, onClose, children } = this.props;

        return (
            <div className="sticky-top">
                <UncontrolledAlert color={type} toggle={onClose}>
                    {children}
                </UncontrolledAlert>
            </div>
        );
    }
}
