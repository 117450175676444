import React from 'react';
import { Container } from 'reactstrap';
import { Spinner } from '../components/alert/spinner';
import { AlertStickyTop } from '../components/alert/alert.sticky.top';
import { Route, Switch } from 'react-router';
import * as Routes from '../routing/route';
import { FormListScreen as FormListScreenWithRouter } from './form.list.screen';
import { FormCreateScreenWithRouter } from './form.create.screen';
import { FormEditScreenWithRouter } from './form.edit.screen';
import { FormDuplicateScreenWithRouter } from './form.duplicate.screen';
import { IAlertListItem, alertRemove, getAlertList } from '../duck/alert.duck';
import { connect } from 'react-redux';
import { IState } from '../store/reducer';
import { isAuthenticated } from '../duck/auth.duck';
import { bindActionCreators, Dispatch } from 'redux';
import { Toolbar } from '../components/toolbar/toolbar';
import { getApiVersion } from '../duck/api.duck';

interface MainScreenOwnProps {
    hasBootstrapped: boolean;
}

interface MainScreenConnectProps {
    alertList: IAlertListItem[];
    alertRemove: typeof alertRemove;
    apiVersion: number;
    isAuthenticated: boolean;
}

type MainScreenProps = MainScreenOwnProps & MainScreenConnectProps;

const MainScreenComponent = (props: MainScreenProps): JSX.Element => {
    const {
        alertList,
        alertRemove,
        apiVersion,
        hasBootstrapped,
        isAuthenticated,
    } = props;

    return (
        <main>
            <Container className="mt-5 pb-2">
                {(!hasBootstrapped || !isAuthenticated) && <Spinner />}
                {alertList.map((alert) => (
                    <AlertStickyTop
                        key={alert.id}
                        type={alert.type}
                        onClose={() => alertRemove(alert.id)}
                        lifeSeconds={alert.lifeSeconds ?? null}
                    >
                        {alert.message}
                    </AlertStickyTop>
                ))}
                {isAuthenticated && (
                    <>
                        <Switch>
                            <Route exact path={Routes.INDEX_PATH}>
                                <FormListScreenWithRouter />
                            </Route>
                            <Route exact path={Routes.FORM_CREATE_PATH}>
                                <FormCreateScreenWithRouter />
                            </Route>
                            <Route exact path={Routes.FORM_PATH.toString()}>
                                <FormEditScreenWithRouter />
                            </Route>
                            <Route exact path={Routes.FORM_DUPLICATE_PATH}>
                                <FormDuplicateScreenWithRouter />
                            </Route>
                        </Switch>
                        <Toolbar apiVersion={apiVersion} />
                    </>
                )}
            </Container>
        </main>
    );
};

export const MainScreen = connect(
    (state: IState) => ({
        alertList: getAlertList(state),
        apiVersion: getApiVersion(state),
        isAuthenticated: isAuthenticated(state),
    }),
    (dispatch: Dispatch) =>
        bindActionCreators(
            {
                alertRemove,
            },
            dispatch
        )
)(MainScreenComponent);
