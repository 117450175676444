import React from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo, faLock } from '@fortawesome/free-solid-svg-icons';

import { LocalizationContext } from '../../localization/localization.provider';
import { IAppProps } from '../form.list';
import { ListItemButtonsProps } from './list.item.buttons';
import { translations } from '../../../translations/translations';
import { Help } from '../edit/input/help';

export const RevisionListItemButtons = (
    props: ListItemButtonsProps & IAppProps
): JSX.Element => {
    const { item, getPrimaryAction, getSecondaryAction, getSecondaryLabel } =
        props;
    const primaryAction = getPrimaryAction(item);
    const secondaryAction = getSecondaryAction(item);
    const secondaryLabel = getSecondaryLabel(item);

    return (
        <LocalizationContext.Consumer>
            {({ translate, locale }) => (
                <ButtonGroup vertical>
                    {primaryAction && (
                        <ButtonGroup>
                            <Button
                                color="info"
                                aria-label={translate(
                                    'revisions.restore.button'
                                )}
                                title={translate('revisions.restore.button')}
                                onClick={() => primaryAction(item.revisionId)}
                            >
                                <FontAwesomeIcon
                                    icon={faUndo}
                                    className="mr-1"
                                />
                                <span>
                                    {translate('revisions.restore.button')}
                                </span>
                            </Button>
                            <Help
                                name={'help-revision-actions-restore'}
                                locale={locale}
                                title={
                                    translations.revisions.help.restore.title
                                }
                                body={
                                    translations.revisions.help.restore.content
                                }
                                isInputAddon={true}
                            />
                        </ButtonGroup>
                    )}

                    {secondaryAction && (
                        <ButtonGroup>
                            <Button
                                color="warning"
                                onClick={() => secondaryAction(item.revisionId)}
                                aria-label={translate(secondaryLabel)}
                                title={translate(secondaryLabel)}
                            >
                                <FontAwesomeIcon icon={faLock} />
                                <span>{translate(secondaryLabel)}</span>
                            </Button>
                            <Help
                                name={'help-revision-actions-stickify'}
                                locale={locale}
                                title={
                                    translations.revisions.help.stickify.title
                                }
                                body={
                                    translations.revisions.help.stickify.content
                                }
                                isInputAddon={true}
                            />
                        </ButtonGroup>
                    )}
                </ButtonGroup>
            )}
        </LocalizationContext.Consumer>
    );
};
