import { handleActions, createAction, Action } from 'redux-actions';
import { IState } from '../store/reducer';
import { createSelector } from 'reselect';
/*
 * Constants
 */

/*
 * Model - duck specific model, domain models go to `domain` folder
 */
export interface ApiState {
    version: number;
}

/*
 * Module properties - singletons and other module specific vars
 */

/*
 * State
 */
export const MOUNT_POINT = 'api';

export const DEFAULT_STATE = {
    version: 0,
};

/*
 * Selectors
 */
export const getState = (state: IState): ApiState => state[MOUNT_POINT];

export const getApiVersion = createSelector(
    getState,
    (state: ApiState) => state.version
);

/*
 * Actions
 */
export const apiVersionChanged = createAction<number>(
    MOUNT_POINT + '/API_VERSION_CHANGED'
);

/*
 * Reducers
 */
/* eslint-disable-next-line */
export const apiReducer = handleActions<ApiState, any, any>(
    {
        [apiVersionChanged.toString()]: (
            state: ApiState,
            action: Action<number>
        ) => {
            return {
                ...state,
                version: action.payload,
            };
        },
    },
    { ...DEFAULT_STATE }
);

/*
 * Sagas
 */

/*
 * Helpers
 */
