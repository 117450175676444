import { combineReducers } from 'redux';

import {
    entityStoreReducer as entityStore,
    IEntityStore,
} from '../duck/entity.store.duck';
import {
    formListReducer as formList,
    IFormListState,
} from '../duck/form.list.duck';
import {
    formEditReducer as formEdit,
    IFormEditState,
} from '../duck/form.edit.duck';
import {
    ILocalizationState,
    localizationReducer as localization,
} from '../duck/localization.duck';
import {
    alertListReducer as alertList,
    IAlertListState,
} from '../duck/alert.duck';
import {
    formBuilderReducer as formBuilder,
    IFormBuilderState,
} from '../duck/form.builder.duck';
import { apiReducer as api, ApiState } from '../duck/api.duck';
import { authReducer as auth, IAuthState } from '../duck/auth.duck';

export interface IState {
    formEdit: IFormEditState;
    formList: IFormListState;
    entityStore: IEntityStore;
    localization: ILocalizationState;
    alertList: IAlertListState;
    formBuilder: IFormBuilderState;
    api: ApiState;
    auth: IAuthState;
}

export const rootReducer = combineReducers<IState>({
    formEdit,
    formList,
    entityStore,
    localization,
    alertList,
    formBuilder,
    api,
    auth,
});
