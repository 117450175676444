import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DateFormatted from '../../date/date.formatted';
import { IForm } from '../../../domain/model/form/form';
import {
    faEdit,
    faGlobe,
    faPlusCircle,
} from '@fortawesome/free-solid-svg-icons';
import { Badge } from 'reactstrap';
import { AlertType } from '../../../duck/alert.duck';
import { LocalizationContext } from '../../localization/localization.provider';

interface FormMetaProps {
    canPublish: boolean;
    formData: IForm;
}

export const FormMeta = (props: FormMetaProps): JSX.Element => {
    const {
        canPublish,
        formData: {
            createdAt,
            createdBy,
            publishedAt,
            publishedBy,
            template,
            updatedAt,
            updatedBy,
        },
    } = props;

    return (
        <LocalizationContext.Consumer>
            {({ translate }) => (
                <>
                    {canPublish && (
                        <div className={'form-meta'}>
                            <div className={'form-meta-info lead'}>
                                <span>
                                    {translate('formMeta.created')}&nbsp;
                                </span>
                                <DateFormatted dateString={createdAt} />
                                <span>
                                    &nbsp;{translate('formMeta.by')}&nbsp;
                                </span>
                                <span
                                    className={'text-muted'}
                                    title={
                                        'ID: ' +
                                        ((createdBy && createdBy.id) ||
                                            translate('formMeta.unknown'))
                                    }
                                >
                                    {(createdBy && createdBy.displayName) ||
                                        translate('formMeta.unknown')}
                                </span>
                                <FontAwesomeIcon icon={faPlusCircle} />
                            </div>
                            <div className={'form-meta-info lead'}>
                                <span>
                                    {translate('formMeta.edited')}&nbsp;
                                </span>
                                <DateFormatted dateString={updatedAt} />
                                <span>
                                    &nbsp;{translate('formMeta.by')}&nbsp;
                                </span>
                                <span
                                    className={'text-muted'}
                                    title={
                                        'ID: ' +
                                        ((updatedBy && updatedBy.id) ||
                                            translate('formMeta.unknown'))
                                    }
                                >
                                    {(updatedBy && updatedBy.displayName) ||
                                        translate('formMeta.unknown')}
                                </span>
                                <FontAwesomeIcon icon={faEdit} />
                            </div>
                            <div className={'form-meta-info lead'}>
                                <span>
                                    {translate('formMeta.published')}&nbsp;
                                </span>
                                {publishedAt ? (
                                    <>
                                        <DateFormatted
                                            dateString={publishedAt}
                                        />
                                        <span>
                                            &nbsp;{translate('formMeta.by')}
                                            &nbsp;
                                        </span>
                                        <span
                                            className={'text-muted'}
                                            title={
                                                'ID: ' +
                                                ((publishedBy &&
                                                    publishedBy.id) ||
                                                    translate(
                                                        'formMeta.unknown'
                                                    ))
                                            }
                                        >
                                            {(publishedBy &&
                                                publishedBy.displayName) ||
                                                translate('formMeta.unknown')}
                                        </span>
                                    </>
                                ) : (
                                    <span>{translate('formMeta.never')}</span>
                                )}
                                <FontAwesomeIcon icon={faGlobe} />
                            </div>
                            {template && (
                                <div className={'form-meta-info'}>
                                    <Badge pill color={AlertType.WARNING}>
                                        {translate('formMeta.templateForm')}
                                    </Badge>
                                </div>
                            )}
                        </div>
                    )}
                </>
            )}
        </LocalizationContext.Consumer>
    );
};
