import React, { Fragment } from 'react';
import {
    Button,
    InputGroup,
    InputGroupAddon,
    Label,
    Table,
    UncontrolledCollapse,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _set from 'lodash/set';

import { translations } from '../../../../../translations/translations';
import { IInputComponentProps } from '../../input/input.component.props.interface';
import {
    faArrowRight,
    faAsterisk,
    faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import { IState } from '../../../../../store/reducer';
import { getLocale } from '../../../../../duck/localization.duck';
import { IAssocInputComponentProps } from './assoc.array.input';
import { InputElement, RemoveButton } from './assoc.array.input';
import { InputFieldValidatorType } from '../../../../../domain/model/form/input.field';

const SelectElement = (props) => {
    const options = Object.keys(InputFieldValidatorType).map((key, i) => {
        const typename = InputFieldValidatorType[key];
        return (
            <option key={i} value={typename}>
                {typename}
            </option>
        );
    });
    return (
        <select
            id={`input-${props.name}`}
            name={props.name}
            value={props.value}
            onChange={(ev) => props.onChange(ev.currentTarget.value)}
            className="form-control"
        >
            {options}
        </select>
    );
};

enum ValidatorsArrayInputSubfieldType {
    TYPE = 'type',
    MESSAGE = 'message',
    PATTERN = 'context.pattern',
    FLAGS = 'context.flags',
}

class ValidatorsArrayInputComponent extends React.Component<IAssocInputComponentProps> {
    onRowAdded = () => {
        let newValue;
        const oldValue = this.props.value;
        if (oldValue && oldValue.forEach) {
            newValue = [...this.props.value];
        } else {
            newValue = [];
        }
        newValue.push({
            [ValidatorsArrayInputSubfieldType.TYPE]:
                InputFieldValidatorType.NOT_EMPTY,
            [ValidatorsArrayInputSubfieldType.MESSAGE]: '',
            context: {},
        });
        this.props.onChange({
            currentTarget: {
                value: newValue,
            },
        });
    };

    onRemoveClicked = (index: number) => {
        const newFieldValue = [...this.props.value];
        newFieldValue.splice(index, 1);
        this.props.onChange({
            currentTarget: {
                value: newFieldValue,
            },
        });
    };

    onValueChanged = (
        index: number,
        subfield: ValidatorsArrayInputSubfieldType,
        newValue: string
    ) => {
        let newFieldValue;
        const oldValue = this.props.value;
        if (oldValue && oldValue.length && oldValue.forEach) {
            newFieldValue = [...oldValue];
        } else {
            newFieldValue = [];
        }

        newFieldValue[index] = _set(newFieldValue[index], subfield, newValue);
        this.props.onChange({
            currentTarget: {
                value: newFieldValue,
            },
        });
    };

    renderRowItem(row, index, locale) {
        const { type, message, context = {} } = row;
        const { pattern = '', flags = '' } = context;
        const { name: groupName } = this.props;
        return (
            <Fragment key={index}>
                <tr key={index}>
                    <td>
                        <SelectElement
                            name={`${groupName}-type-${index}`}
                            value={type}
                            onChange={(newValue) =>
                                this.onValueChanged(
                                    index,
                                    ValidatorsArrayInputSubfieldType.TYPE,
                                    newValue
                                )
                            }
                        />
                    </td>
                    <td>
                        <InputElement
                            name={`${groupName}-message-${index}`}
                            value={message}
                            onChange={(newValue) =>
                                this.onValueChanged(
                                    index,
                                    ValidatorsArrayInputSubfieldType.MESSAGE,
                                    newValue
                                )
                            }
                        />
                    </td>
                    <td>
                        {type === InputFieldValidatorType.REGEXP && (
                            <Button
                                id={'toggleRegexOptions-' + index}
                                color="primary"
                                outline
                                /* eslint-disable-next-line */
                                onClick={() => {}}
                            >
                                <FontAwesomeIcon icon={faAsterisk} />
                            </Button>
                        )}
                        <RemoveButton
                            onClick={() => this.onRemoveClicked(index)}
                        />
                    </td>
                </tr>
                {type === InputFieldValidatorType.REGEXP && (
                    <UncontrolledCollapse
                        tag="tr"
                        toggler={'#toggleRegexOptions-' + index}
                    >
                        <td>
                            <FontAwesomeIcon icon={faArrowRight} />
                        </td>
                        <td>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <Label
                                        className="input-group-text"
                                        style={{ minWidth: '150px' }}
                                        htmlFor={`${groupName}-pattern-${index}`}
                                    >
                                        {
                                            translations.formBuilder
                                                .validatorType.regexPattern[
                                                locale
                                            ]
                                        }
                                    </Label>
                                </InputGroupAddon>
                                <InputElement
                                    name={`${groupName}-pattern-${index}`}
                                    value={pattern}
                                    onChange={(newValue) =>
                                        this.onValueChanged(
                                            index,
                                            ValidatorsArrayInputSubfieldType.PATTERN,
                                            newValue
                                        )
                                    }
                                />
                            </InputGroup>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <Label
                                        className="input-group-text"
                                        style={{ minWidth: '150px' }}
                                        htmlFor={`${groupName}-flags-${index}`}
                                    >
                                        {
                                            translations.formBuilder
                                                .validatorType.regexFlags[
                                                locale
                                            ]
                                        }
                                    </Label>
                                </InputGroupAddon>
                                <InputElement
                                    name={`${groupName}-flags-${index}`}
                                    value={flags}
                                    onChange={(newValue) =>
                                        this.onValueChanged(
                                            index,
                                            ValidatorsArrayInputSubfieldType.FLAGS,
                                            newValue
                                        )
                                    }
                                />
                            </InputGroup>
                        </td>
                    </UncontrolledCollapse>
                )}
            </Fragment>
        );
    }

    render() {
        const { locale, value } = this.props;

        const AddButton = (props) => (
            <Button color="success" onClick={this.onRowAdded} {...props}>
                <FontAwesomeIcon icon={faPlus} />
            </Button>
        );
        const rows = value;

        return (
            <div className="w-100">
                <Table bordered>
                    <thead>
                        <tr>
                            <th>
                                {
                                    translations.formBuilder.validatorInput.head
                                        .type[locale]
                                }
                            </th>
                            <th>
                                {
                                    translations.formBuilder.validatorInput.head
                                        .message[locale]
                                }
                            </th>
                            <th>
                                <AddButton />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows &&
                            rows.map((row, item) =>
                                this.renderRowItem(row, item, locale)
                            )}
                    </tbody>
                </Table>
            </div>
        );
    }
}

export const ValidatorsArrayInputConnected = connect(
    (state: IState) => ({
        locale: getLocale(state),
    }),
    null
)(ValidatorsArrayInputComponent);

export const ValidatorsArrayInput = (
    props: IInputComponentProps
): JSX.Element => <ValidatorsArrayInputConnected {...props} />;
