import { handleActions } from 'redux-actions';
import { IForm } from '../domain/model/form/form';
import { IState } from '../store/reducer';

/*
 * Constants
 */

/*
 * Model - duck specific model, domain models go to `domain` folder
 */

/*
 * Module properties - singletons and other module specific vars
 */

/*
 * State
 */
export const MOUNT_POINT = 'entityStore';

export interface IEntityStore {
    form: {
        [id: string]: Partial<IForm>;
    };
}

export const DEFAULT_STATE: IEntityStore = {
    form: {
        '123': {
            id: '123',
        },
    },
};

/*
 * Selectors
 */
export const getState = (state: IState): IEntityStore => state[MOUNT_POINT];

/*
 * Actions
 */

/*
 * Reducers
 */
/* eslint-disable-next-line */
export const entityStoreReducer = handleActions<IEntityStore, any, any>(
    {
        // TODO add actions
    },
    DEFAULT_STATE
);

/*
 * Sagas
 */

/*
 * Helpers
 */
