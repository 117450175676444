import { translations } from '../translations/translations';
import { IInputFieldConfig } from './input.field.config.interface';

export const EloquaFieldConfig: IInputFieldConfig = [
    {
        name: 'eloqua.siteId',
        label: translations.formElement.field.eloqua.siteId,
        readonly: false,
        withCopyButton: true,
        resettable: true,
    },
    {
        name: 'eloqua.visitorKey',
        label: translations.formElement.field.eloqua.visitorKey,
        readonly: false,
        withCopyButton: true,
        resettable: true,
    },
    {
        name: 'eloqua.contactIdKey',
        label: translations.formElement.field.eloqua.contactKey,
        readonly: false,
        withCopyButton: true,
        resettable: true,
    },
    {
        name: 'eloqua.formDataKey',
        label: translations.formElement.field.eloqua.formDataKey,
        readonly: false,
        withCopyButton: true,
        resettable: true,
    },
    {
        name: 'eloqua.extraDataKey',
        label: translations.formElement.field.eloqua.extraDataKey,
        readonly: false,
        withCopyButton: true,
        resettable: true,
    },
];
