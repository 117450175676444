import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { User } from 'oidc-react';

import {
    currentItemFieldValueChanged,
    deleteItemById,
    formBuilderDataInitialized,
    getCurrentItem,
    getEditingOverlayVisibility,
    getFormBuilderItems,
    getMappedFormPartial,
    hideEditingOverlay,
    IFormBuilderItem,
    IFormBuilderItemType,
    initItemAsCurrentById,
    initNewItemAsCurrent,
    isAddButtonDropdownOpen,
    itemsChanged,
    resetCurrentItem,
    saveCurrentItem,
    showEditingOverlay,
    toggleAddButtonDropdown,
} from '../duck/form.builder.duck';
import { IForm } from '../domain/model/form/form';
import { IState } from '../store/reducer';
import { getLocale } from '../duck/localization.duck';
import { translations } from '../translations/translations';
import { mapToFormBuilderData } from '../duck/helpers/map.to.form.builder.data';
import { FormBuilder } from '../components/form/form.builder';
import { ISchemaItem } from '../domain/model/form/form.schema';
import { getUser } from '../duck/auth.duck';
import { TreeItem } from '../domain/model/lib/tree.item';

interface IFormBuilderScreenConnectProps {
    currentItemFieldValueChanged: typeof currentItemFieldValueChanged;
    deleteItemById: typeof deleteItemById;
    formBuilderDataInitialized: typeof formBuilderDataInitialized;
    hideEditingOverlay: typeof hideEditingOverlay;
    initItemAsCurrentById: typeof initItemAsCurrentById;
    initNewItemAsCurrent: typeof initNewItemAsCurrent;
    itemsChanged: typeof itemsChanged;
    resetCurrentItem: typeof resetCurrentItem;
    saveCurrentItem: typeof saveCurrentItem;
    showEditingOverlay: typeof showEditingOverlay;
    toggleAddButtonDropdown: typeof toggleAddButtonDropdown;
}

interface IFormBuilderScreenStateProps {
    addFieldDropdownToggleOpen: boolean;
    currentItem: IFormBuilderItem;
    editingOverlayVisible: boolean;
    items: TreeItem[];
    locale: string;
    mappedFormPartialAndSchema: MappedFormPartialAndSchema;
    user: User;
}

export interface MappedFormPartialAndSchema {
    form: Partial<IForm>;
    schema: ISchemaItem[];
}

export interface IFormBuilderScreenOwnProps {
    formData: IForm;
    ref: React.RefObject<FormBuilderCustomScreenComponent>;
}

export type IFormBuilderScreenProps = IFormBuilderScreenOwnProps &
    IFormBuilderScreenConnectProps &
    IFormBuilderScreenStateProps;

export class FormBuilderCustomScreenComponent extends React.Component<IFormBuilderScreenProps> {
    componentDidMount(): void {
        this.mapItems();
    }

    mapItems(): void {
        const items = mapToFormBuilderData(this.props.formData);
        this.props.formBuilderDataInitialized(items);
    }

    handleChange = (items: IFormBuilderItem[]): void => {
        this.props.itemsChanged(items);
    };

    onFieldCreateRequested = (type: IFormBuilderItemType): void => {
        this.props.initNewItemAsCurrent({
            type,
            locale: this.props.locale,
        });
        this.props.showEditingOverlay();
    };

    onItemFieldChange = (name: string, newValue: string): void => {
        this.props.currentItemFieldValueChanged({
            name,
            newValue,
        });
    };

    onFieldDeleteRequested = (fieldId: number): void => {
        if (
            window.confirm(
                translations.alert.delete_field_confirm[this.props.locale]
            )
        ) {
            this.props.deleteItemById(fieldId);
        }
    };

    onFieldEditRequested = (fieldId: number): void => {
        this.props.initItemAsCurrentById(fieldId);
        this.props.showEditingOverlay();
    };

    onAbort = (): void => {
        this.props.resetCurrentItem();
        this.props.hideEditingOverlay();
        this.props.resetCurrentItem();
    };

    onSaveClicked = (): void => {
        this.props.saveCurrentItem();
        this.props.hideEditingOverlay();
        this.props.resetCurrentItem();
    };

    provideData(): MappedFormPartialAndSchema {
        return this.props.mappedFormPartialAndSchema;
    }

    render(): JSX.Element {
        const {
            addFieldDropdownToggleOpen,
            currentItem,
            editingOverlayVisible,
            formData,
            hideEditingOverlay,
            items,
            locale,
            toggleAddButtonDropdown,
            user,
        } = this.props;

        const actionBarProps = {
            dropdownOpen: addFieldDropdownToggleOpen,
            locale,
            onFieldCreateRequested: this.onFieldCreateRequested,
            toggle: toggleAddButtonDropdown,
        };

        const formOverlayProps = {
            closeFormOverlay: hideEditingOverlay,
            configurableOptions: currentItem.data.props.configurableOptions,
            formData,
            isOpen: editingOverlayVisible,
            item: currentItem,
            locale,
            onSaveClicked: this.onSaveClicked,
            onFieldChange: this.onItemFieldChange,
            user,
        };

        const formBuilderProps = {
            actionBarProps,
            editingOverlayVisible,
            formOverlayProps,
            handleChange: this.handleChange,
            items,
            locale,
            onFieldDeleteRequested: this.onFieldDeleteRequested,
            onFieldEditRequested: this.onFieldEditRequested,
        };

        return <FormBuilder {...formBuilderProps} />;
    }
}
export const FormBuilderScreen = connect(
    (state: IState) => ({
        addFieldDropdownToggleOpen: isAddButtonDropdownOpen(state),
        currentItem: getCurrentItem(state),
        editingOverlayVisible: getEditingOverlayVisibility(state),
        items: getFormBuilderItems(state),
        locale: getLocale(state),
        mappedFormPartialAndSchema: getMappedFormPartial(state),
        user: getUser(state),
    }),
    (dispatch: Dispatch) =>
        bindActionCreators(
            {
                currentItemFieldValueChanged,
                deleteItemById,
                formBuilderDataInitialized,
                hideEditingOverlay,
                initItemAsCurrentById,
                initNewItemAsCurrent,
                itemsChanged,
                resetCurrentItem,
                saveCurrentItem,
                showEditingOverlay,
                toggleAddButtonDropdown,
            },
            dispatch
        ),
    null,
    {
        forwardRef: true,
    }
)(FormBuilderCustomScreenComponent);
