import { IForm } from '../../../domain/model/form/form';
import React, { RefObject } from 'react';

interface FormPreviewProps {
    form: IForm;
    publicRevision: boolean;
}

export class FormPreview extends React.Component<FormPreviewProps> {
    iframeContainer: RefObject<HTMLDivElement> = React.createRef();

    componentDidMount(): void {
        const { form, publicRevision = false } = this.props;

        const container = this.iframeContainer.current;
        container.innerHTML = '';
        const i: HTMLElement & Partial<HTMLIFrameElement> =
            document.createElement('IFRAME');
        const c = document.createElement('DIV');
        i.className = 'preview-frame';
        c.className = 'eloqua-form';
        c.setAttribute('data-eloqua-formid', form.slug);
        if (!publicRevision) {
            c.setAttribute('data-eloqua-revision', form.revisionId);
        }
        c.setAttribute('data-eloqua-preview', 'true');
        i.addEventListener('load', () => {
            i.contentDocument.body.appendChild(c);
            const s: HTMLElement & Partial<HTMLScriptElement> =
                document.createElement('SCRIPT');
            s.src = process.env.REACT_APP_ELQ_GENERATOR_URL;
            i.contentDocument.body.appendChild(s);
        });
        container.appendChild(i);
    }

    render(): JSX.Element {
        return (
            <div className={'preview-container'} ref={this.iframeContainer} />
        );
    }
}
