import React from 'react';
import { IInputComponentProps } from './input.component.props.interface';

export const TextInput = (props: IInputComponentProps): JSX.Element => {
    return (
        <input
            id={`input-${props.name}`}
            name={props.name}
            value={props.value}
            type="text"
            onChange={props.onChange}
            className="form-control"
            disabled={props.readonly || false}
        />
    );
};
