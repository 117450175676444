import React, { ComponentClass, FunctionComponent } from 'react';
import { Button, InputGroup, InputGroupAddon } from 'reactstrap';
import { connect } from 'react-redux';
import { IState } from '../../../../store/reducer';
import { bindActionCreators, Dispatch } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faUndo } from '@fortawesome/free-solid-svg-icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import {
    fieldValueChanged,
    fieldValueResetClicked,
    getFieldValue,
} from '../../../../duck/form.edit.duck';
import { translations } from '../../../../translations/translations';
import { TextInput } from './text.input';
import {
    ISelectOption,
    IInputComponentProps,
    IInputComponentChangeEvent,
} from './input.component.props.interface';
import { Help, IHelpSetupProps, ITranslatable } from './help';
import classnames from 'classnames';

interface IInputFieldConnectProps {
    fieldValueChanged: typeof fieldValueChanged;
    fieldValueResetClicked: typeof fieldValueResetClicked;
    currentValue: string | [];
}

interface IInputFieldProps extends IInputFieldConnectProps {
    name: string;
    label: ITranslatable;
    component?: FunctionComponent | ComponentClass;
    resettable?: boolean;
    readonly?: boolean;
    withCopyButton?: boolean;
    locale: string;
    selectOptions?: ISelectOption[];
    help?: IHelpSetupProps;
}

class InputFieldComponent extends React.Component<IInputFieldProps> {
    onFieldChange = (event: IInputComponentChangeEvent) => {
        const { fieldValueChanged, name: nameFromProps } = this.props;
        const { value: newValue, name: overrideName } = event.currentTarget;
        const name = overrideName ?? nameFromProps;
        fieldValueChanged({ name, newValue });
    };

    resetInput = () => {
        const { fieldValueResetClicked, name } = this.props;
        fieldValueResetClicked(name);
    };

    render(): JSX.Element {
        const {
            currentValue,
            component,
            help,
            label: labelText,
            locale,
            name,
            selectOptions,
            resettable,
            readonly,
            withCopyButton,
        } = this.props;
        const InputComponent = component || TextInput;
        const inputComponentProps: IInputComponentProps = {
            name,
            value: currentValue,
            onChange: this.onFieldChange,
            selectOptions,
            readonly,
        };

        return (
            <InputGroup className={classnames('mb-2', { readonly: readonly })}>
                <InputGroupAddon addonType="prepend">
                    <label
                        className="input-group-text"
                        style={{ minWidth: '180px' }}
                        htmlFor={`input-${name}`}
                    >
                        {labelText[locale]}
                    </label>
                </InputGroupAddon>

                <InputComponent {...inputComponentProps} />

                {help && (help.body || help.link) && (
                    <Help
                        name={name}
                        title={labelText}
                        isInputAddon
                        locale={locale}
                        body={help.body}
                        link={help.link}
                    />
                )}
                {(withCopyButton || resettable) && (
                    <InputGroupAddon addonType="append">
                        {withCopyButton && (
                            <CopyToClipboard text={currentValue}>
                                <Button
                                    outline={true}
                                    color="primary"
                                    aria-label={
                                        translations.formAction.copyToClipboard[
                                            locale
                                        ]
                                    }
                                    title={
                                        translations.formAction.copyToClipboard[
                                            locale
                                        ]
                                    }
                                >
                                    <FontAwesomeIcon icon={faCopy} />
                                </Button>
                            </CopyToClipboard>
                        )}

                        {resettable && (
                            <Button
                                outline={true}
                                color="danger"
                                aria-label={
                                    translations.formAction.reset[locale]
                                }
                                title={translations.formAction.reset[locale]}
                                onClick={this.resetInput}
                            >
                                <FontAwesomeIcon icon={faUndo} />
                            </Button>
                        )}
                    </InputGroupAddon>
                )}
            </InputGroup>
        );
    }
}

export const InputField = connect(
    (state: IState, ownProps) => ({
        currentValue: getFieldValue(state, ownProps),
    }),
    (dispatch: Dispatch) =>
        bindActionCreators(
            {
                fieldValueChanged,
                fieldValueResetClicked,
            },
            dispatch
        )
)(InputFieldComponent);
