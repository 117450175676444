export const networkAlerts = {
    request_fail: {
        de: 'Anfrage fehlgeschlagen',
    },
    form_list_fetch_fail: {
        de: 'Holen der Formularliste fehlgeschlagen',
    },
    tag_list_fetch_fail: {
        de: 'Holen der Tag-Liste fehlgeschlagen',
    },
    form_move_fail: {
        de: 'Verschieben des Formulars fehlgeschlagen',
    },
    err401: {
        de: 'Authentifizierung fehlgeschlagen. Bitte laden Sie die Seite neu. Sollte das Problem danach weiterhin bestehen, schließen Sie das Browserfenster und versuchen Sie es erneut. Prüfen Sie, ob sie über Microsoft Azure mit dem korrekten Account eingeloggt sind.',
    },
    err403: {
        de: 'Sie haben keine Berechtigung für diese Aktion. Bitte prüfen Sie, ob Sie korrekt eingeloggt sind und kontaktieren Sie im Zweifelsfall die Nutzeradministrator:innen.',
    },
};
