/* eslint-disable @typescript-eslint/no-explicit-any */
import { User } from 'oidc-react';
import {
    sdkClientUserProvider,
    UserProvider,
} from './sdk.client.user.provider';
import { request } from '../../lib/http/request';
import { jsonHeaders } from '../request/headers';

/**
 * Uses the SdkClientUserProvider to provide a 'user' property on every client
 * extending this base class.
 */
export class UserAwareSdkClient {
    userProvider: UserProvider = sdkClientUserProvider;

    get user(): User {
        return this.userProvider.getUser();
    }

    async jsonRequest(
        route: string,
        method = 'GET',
        body: unknown = null,
        expectResponseContent = true
    ): Promise<unknown> {
        const options = {
            method,
            headers: jsonHeaders(this.user),
        };

        if (body !== null) {
            options['body'] = JSON.stringify(body);
        }

        const response = await request(route, options);

        if (response.status === 211) {
            const res = await response.json();
            return window.location.replace(res.location);
        }

        if (expectResponseContent) {
            return response.json();
        }

        return response.status;
    }

    async getJson(route: string): Promise<any> {
        return this.jsonRequest(route);
    }

    async postJson(
        route: string,
        body: unknown,
        expectResponseContent = true
    ): Promise<any> {
        return this.jsonRequest(route, 'POST', body, expectResponseContent);
    }

    async deleteJson(
        route: string,
        expectResponseContent = false
    ): Promise<any> {
        return this.jsonRequest(route, 'DELETE', null, expectResponseContent);
    }

    async putJson(route: string, body: unknown): Promise<any> {
        return this.jsonRequest(route, 'PUT', body);
    }
}
