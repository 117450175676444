import { translations } from '../../translations/translations';
import { IInputGroupConfigProps } from './field.fields';

export const SubmitFieldsConfig: IInputGroupConfigProps[] = [
    {
        title: translations.formBuilder.fieldGroup.submit,
        collapsed: false,
        fields: [
            {
                name: 'name',
                label: translations.formBuilder.field.name,
                readonly: false,
            },
            {
                name: 'className',
                label: translations.formBuilder.className,
                readonly: false,
            },
            {
                name: 'text',
                label: translations.formBuilder.field.submitText,
            },
        ],
    },
    {
        title: translations.formBuilder.fieldGroup.progressive,
        collapsed: true,
        fields: [
            {
                name: 'textWhenFilled',
                label: translations.formBuilder.field.submitTextWhenFilled,
                readonly: false,
                help: {
                    body: translations.help.formBuilder.fields
                        .submitAlternativeText,
                },
            },
        ],
    },
];
