import {
    IInputComponentChangeEvent,
    IInputComponentProps,
    ISelectOption,
} from '../edit/input/input.component.props.interface';
import React, { ComponentClass } from 'react';
import { LocalizationContext } from '../../localization/localization.provider';
import { SelectInput } from '../edit/input/select.input';
import { TextInput } from '../edit/input/text.input';
import { InputGroup, InputGroupAddon } from 'reactstrap';
import classnames from 'classnames';

export type InputValue = string | boolean | number;
type InputComponent =
    | React.FunctionComponent<IInputComponentProps>
    | ComponentClass;

interface UnconnectedInputFieldProps {
    name: string;
    value: InputValue;
    component: InputComponent;
    onChange: (string, InputValue) => void;
    labelKey: string;
    hidden?: boolean;
    readonly?: boolean;
    options?: ISelectOption[];
}

export class UnconnectedInputField extends React.Component<UnconnectedInputFieldProps> {
    static contextType = LocalizationContext;

    onFieldChange = (event: IInputComponentChangeEvent): void => {
        const { onChange, name } = this.props;
        const newValue = event.currentTarget.value;
        onChange(name, newValue);
    };

    render(): JSX.Element {
        const { value, component, hidden, labelKey, name, options, readonly } =
            this.props;
        const { translate } = this.context;
        let selectOptions = null;

        if (component === SelectInput && options) {
            selectOptions = options.map((o) => {
                const newOption = { ...o };
                newOption.label = translate(o.label);
                return newOption;
            });
        }

        const InputComponent = component || TextInput;
        const inputComponentProps: IInputComponentProps = {
            name,
            value,
            onChange: this.onFieldChange,
            selectOptions,
            readonly,
        };

        const label = translate(labelKey);

        return (
            <InputGroup className={classnames('mb-2', { 'd-none': hidden })}>
                <InputGroupAddon addonType="prepend">
                    <label
                        className="input-group-text"
                        style={{ minWidth: '180px' }}
                        htmlFor={`input-${name}`}
                    >
                        {label}
                    </label>
                </InputGroupAddon>

                <InputComponent {...inputComponentProps} />
            </InputGroup>
        );
    }
}
