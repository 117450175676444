import React from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    UncontrolledCollapse,
} from 'reactstrap';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { v4 as uuid } from 'uuid';
import { User } from 'oidc-react';

import { IFormBuilderItem } from '../../../../duck/form.builder.duck';
import { IInputFieldConfigProps, InputField } from './input/input.field';
import { translations } from '../../../../translations/translations';
import { IInputGroupConfigProps } from '../../../../configuration/formBuilder/field.fields';
import { CloseButton } from '../../../close.button';
import { isAdmin } from '../../../../domain/model/user/user.roles';
import { IForm } from '../../../../domain/model/form/form';

interface IFormBuilderFormProps {
    closeFormOverlay;
    configurableOptions: IInputGroupConfigProps[];
    formData: IForm;
    isOpen: boolean;
    item: IFormBuilderItem;
    locale: string;
    onSaveClicked;
    onFieldChange;
    user: User;
}

const generateInputFields = (
    fieldProps: IInputFieldConfigProps,
    locale: string,
    isTemplate: boolean,
    user: User
): JSX.Element => {
    fieldProps.readonly = isTemplate ? !isAdmin(user) : fieldProps.readonly;
    return (
        <InputField
            key={fieldProps.name}
            onFieldChange={fieldProps.onFieldChange}
            locale={locale}
            {...fieldProps}
        />
    );
};

const InputCardBody = (props) => {
    const { fields, isTemplate, locale, onFieldChange, user } = props;
    return (
        <CardBody>
            {fields &&
                fields.map((field) => {
                    const inputFieldProps = { onFieldChange, ...field };
                    return generateInputFields(
                        inputFieldProps,
                        locale,
                        isTemplate,
                        user
                    );
                })}
        </CardBody>
    );
};

const InputCard = (props) => {
    const { groupOptions, locale, index, isTemplate, onFieldChange, user } =
        props;

    const toggleId = `toggle-${uuid()}`;
    const { fields, title } = groupOptions;

    return (
        <Card key={index}>
            <CardHeader id={toggleId}>{title[locale]}</CardHeader>
            {groupOptions.collapsed ? (
                <UncontrolledCollapse toggler={`#${toggleId}`}>
                    <InputCardBody
                        fields={fields}
                        isTemplate={isTemplate}
                        locale={locale}
                        onFieldChange={onFieldChange}
                        user={user}
                    />
                </UncontrolledCollapse>
            ) : (
                <InputCardBody
                    fields={fields}
                    isTemplate={isTemplate}
                    locale={locale}
                    onFieldChange={onFieldChange}
                    user={user}
                />
            )}
        </Card>
    );
};

export class FormBuilderFormComponent extends React.Component<IFormBuilderFormProps> {
    render(): JSX.Element {
        const {
            closeFormOverlay,
            configurableOptions,
            onSaveClicked,
            formData,
            item,
            isOpen,
            locale,
            user,
        } = this.props;
        const { name } = item.data.props;

        return (
            <Modal
                size="lg"
                isOpen={isOpen}
                className="text-center modal-dialog-centered"
            >
                <ModalHeader
                    className="d-flex justify-content-between"
                    close={<CloseButton toggleOpen={closeFormOverlay} />}
                >
                    {name}
                </ModalHeader>
                <ModalBody>
                    {configurableOptions &&
                        configurableOptions.map((props, index) => (
                            <InputCard
                                groupOptions={props}
                                isTemplate={formData.template}
                                onFieldChange={this.props.onFieldChange}
                                locale={locale}
                                key={index}
                                user={user}
                            />
                        ))}
                </ModalBody>
                <ModalFooter>
                    <Button onClick={onSaveClicked} color="success">
                        <FontAwesomeIcon icon={faSave} />
                        <span>{translations.formAction.save[locale]}</span>
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}

export const FormBuilderForm = FormBuilderFormComponent;
