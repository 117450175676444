import { translations } from '../../translations/translations';
import { IInputGroupConfigProps } from './field.fields';

export const GroupFieldsConfig: IInputGroupConfigProps[] = [
    {
        title: translations.formBuilder.fieldGroup.group,
        collapsed: false,
        fields: [
            {
                name: 'name',
                label: translations.formBuilder.field.name,
                readonly: false,
            },
            {
                name: 'className',
                label: translations.formBuilder.className,
                readonly: false,
            },
        ],
    },
];
