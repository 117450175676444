import React from 'react';
import _empty from 'lodash/isEmpty';
import _set from 'lodash/set';
import _get from 'lodash/get';

import {
    DEFAULT_STATE as formBuilderDefaultState,
    IFormBuilderItem,
} from '../duck/form.builder.duck';
import { IForm } from '../domain/model/form/form';
import { mapToFormBuilderData } from '../duck/helpers/map.to.form.builder.data';
import { DeliveryHint } from '../components/form/edit/delivery.hint';
import { UnconnectedInputField } from '../components/form/input/unconnected.input.field';
import { TextInput } from '../components/form/edit/input/text.input';
import { LocalizationContext } from '../components/localization/localization.provider';
import { InputFieldType } from '../domain/model/form/input.field';

export interface IFormBuilderScreenProps {
    formData: IForm;
}

interface FormOverrideScreenState {
    currentItem: IFormBuilderItem | null;
    deliveryHint?: string;
    editingOverlayVisible: boolean;
    items: Map<string, DeliveryItem>;
    originalItems: IFormBuilderItem[];
    submitTextOverride?: string;
}

class DeliveryItem {
    className: string;
    inputType: string;
    input: {
        checkedValue?: string;
        placeholder?: string;
        className: string;
        labelParams?: {
            '%privacyPolicyLink%': string;
        };
    };
    label: { className: string; text?: string };
    value: string;
    //submit
    text?: string;
}

function isEmptyObject(object): boolean {
    return Object.values(object).every((e) => typeof e === 'undefined');
}

export class FormOverrideScreen extends React.Component<IFormBuilderScreenProps> {
    static contextType = LocalizationContext;

    state: FormOverrideScreenState = {
        currentItem: formBuilderDefaultState.currentItem,
        deliveryHint: null,
        editingOverlayVisible: false,
        items: new Map(),
        originalItems: [],
        submitTextOverride: null,
    };

    componentDidMount(): void {
        this.mapItems();
    }

    componentDidUpdate(prevProps: Readonly<IFormBuilderScreenProps>): void {
        if (prevProps.formData !== this.props.formData) {
            this.mapItems();
        }
    }

    mapItems(): void {
        const { formData } = this.props;
        const { items } = this.state;

        const mappedItems = mapToFormBuilderData(formData);
        const filteredItems = mappedItems.filter(
            (e) => e.data.props.overridable
        );
        const overrides = new Map(items);

        filteredItems.forEach((item) => {
            overrides.set(item.data.props.name, new DeliveryItem());
        });

        const deliveryHint = this.generateDeliveryHint(overrides, undefined);

        this.setState({
            deliveryHint,
            items: overrides,
            originalItems: filteredItems,
        });
    }

    generateDeliveryHint(
        items: Map<string, DeliveryItem>,
        submitText: string | null | undefined
    ): string {
        const overrides = this.renderOverrides(items, submitText);

        if (overrides) {
            return `
    <script type="application/json">
      ${overrides.split('\n').join('\n      ')}
    </script> 
`;
        } else {
            return '';
        }
    }

    getUpdateItem(
        index: number,
        field: string
    ): (name: string, value: string) => void {
        const { items, originalItems } = this.state;

        return (name: string, newValue: string): void => {
            const updatedItems = new Map(items);
            const item = updatedItems.get(name);

            if (
                _get(originalItems[index], field) !== newValue &&
                newValue !== ''
            ) {
                _set(item, field, newValue);
            } else {
                _set(item, field, undefined);
            }
            updatedItems.set(name, item);

            this.setState({
                items: updatedItems,
                deliveryHint: this.generateDeliveryHint(
                    updatedItems,
                    undefined
                ),
            });
        };
    }

    renderOverrides(
        items: Map<string, DeliveryItem>,
        submitText: string | null | undefined
    ): string | null {
        const clone = new Map(items);
        clone.forEach((item, name) => {
            if (_empty(item) || isEmptyObject(item)) {
                clone.delete(name);
            }
        });
        const object = Object.fromEntries(clone);

        if (
            (typeof submitText !== 'undefined' && submitText !== null) ||
            this.state.submitTextOverride
        ) {
            object.submit = new DeliveryItem();

            if (typeof submitText !== 'undefined' && submitText !== null) {
                object.submit.text = submitText;
            } else if (submitText === null) {
                object.submit = undefined;
            } else if (
                typeof submitText === 'undefined' &&
                this.state.submitTextOverride
            ) {
                object.submit.text = this.state.submitTextOverride;
            }
        }

        return _empty(object) ? null : JSON.stringify(object, null, 2);
    }

    Checkbox({ item, index, items }) {
        return (
            <>
                {item.data.props.inputType === InputFieldType.CHECKBOX && (
                    <UnconnectedInputField
                        name={item.data.props.name}
                        value={
                            items.get(item.data.props.name).input?.labelParams[
                                '%privacyPolicyLink%'
                            ] || ''
                        }
                        component={TextInput}
                        onChange={this.getUpdateItem(
                            index,
                            'input.labelParams.%privacyPolicyLink%'
                        )}
                        labelKey={
                            'formBuilder.field.labelParams.privacyPolicyLink'
                        }
                    />
                )}
            </>
        );
    }

    render(): JSX.Element {
        const { formData } = this.props;
        const { locale, translate } = this.context;
        const { deliveryHint, items, originalItems } = this.state;

        return (
            <>
                {originalItems.map((item, index) => (
                    <React.Fragment key={item.id}>
                        <h4 className={'mt-3'}>{item.data.props.name}</h4>
                        <UnconnectedInputField
                            name={item.data.props.name}
                            value={items.get(item.data.props.name).value || ''}
                            component={TextInput}
                            onChange={this.getUpdateItem(index, 'value')}
                            labelKey={'formBuilder.field.value'}
                        />
                        <UnconnectedInputField
                            name={item.data.props.name}
                            value={
                                items.get(item.data.props.name).className || ''
                            }
                            component={TextInput}
                            onChange={this.getUpdateItem(index, 'className')}
                            labelKey={'formBuilder.className'}
                        />
                        <this.Checkbox
                            item={item}
                            items={items}
                            index={index}
                        />
                    </React.Fragment>
                ))}
                <h4 className={'mt-3'}>{translate('Submit-Button')}</h4>
                <UnconnectedInputField
                    name={'submit-text-override'}
                    value={this.state.submitTextOverride || ''}
                    component={TextInput}
                    onChange={(name, value) => {
                        const newValue = value === '' ? null : value;
                        this.setState({
                            submitTextOverride:
                                newValue === null ? undefined : newValue,
                            deliveryHint: this.generateDeliveryHint(
                                items,
                                newValue
                            ),
                        });
                    }}
                    labelKey={'formBuilder.field.submitText'}
                />

                <h4 className={'mt-5'}>
                    {translate('formElement.section.delivery')}
                </h4>
                <DeliveryHint
                    formId={formData.id}
                    formSlug={formData.slug}
                    formType={formData.type}
                    locale={locale}
                    overrides={deliveryHint}
                />
            </>
        );
    }
}
