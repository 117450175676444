import { User } from 'oidc-react';

export interface UserProvider {
    getUser: () => User;
}

/**
 * A proxy class to allow setting the authenticated user property on every
 * SDK client class.
 */
export class SdkClientUserProvider implements UserProvider {
    user: User = null;

    setUser(user: User): void {
        this.user = user;
    }

    getUser(): User {
        return this.user;
    }
}

export const sdkClientUserProvider = new SdkClientUserProvider();
