import { IWebsite } from '../website/website';
import { IFormDisplay } from './form.display';
import { IFormEloquaConfiguration } from './form.eloqua.configuration';
import { IInputField } from './input.field';
import { ITagValue } from './tags';
import { Editor } from './editor';
import { IFormSpamProtectionConfiguration } from './form.spam.protection.configuration';

export enum FormType {
    BASIC = 'basic',
    PROGRESSIVE = 'progressive',
    CONTACT = 'contact',
}

export enum FormExtension {
    GOOGLE_GEO_SUGGEST = 'google-geo-suggest',
}

enum FormControlType {
    SUBMIT = 'submit',
}

export interface IFormControl {
    type: FormControlType;
    name: string;
    text?: string;
    textWhenFilled?: string;
}

export interface IFormOptions {
    dynamic?: boolean | string;
    hideWhenFilled?: boolean;
}

export interface DeliveryStat {
    [index: string]: number;
}

export interface IForm {
    id?: string;
    name?: string;
    slug?: string;
    type?: FormType;
    website?: IWebsite;
    language?: string;
    extensions?: FormExtension[];
    tags?: ITagValue[];
    display?: IFormDisplay;
    eloqua?: IFormEloquaConfiguration;
    spamProtection?: IFormSpamProtectionConfiguration;
    fields?: IInputField[];
    controls?: IFormControl[];
    updatedAt?: string;
    updatedBy?: Editor;
    createdAt?: string;
    createdBy?: Editor;
    publishedAt?: string;
    publishedBy?: Editor;
    isPublishedRevision?: boolean;
    revisionId?: string;
    sticky?: boolean;
    template?: boolean;
    options?: IFormOptions;
    skipWdl?: boolean;
    deliveryStats?: DeliveryStat[];
    /* @FIXME only applies to Progressive Form. Maybe we should extend the model */
    fieldGroups?: string[];
}

export type IFormListItem = Pick<
    IForm,
    | 'id'
    | 'name'
    | 'website'
    | 'tags'
    | 'updatedAt'
    | 'slug'
    | 'type'
    | 'updatedBy'
    | 'publishedAt'
    | 'isPublishedRevision'
    | 'revisionId'
    | 'sticky'
    | 'template'
>;

/*
 * Model methods
 */
export const findFieldByInputName = (
    fields: IForm['fields'],
    inputName: string
): IInputField => {
    return fields.find((f) => f.name === inputName);
};
