import React from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons/faSync';
import { Button, ButtonGroup } from 'reactstrap';

import { IFormListItem } from '../../domain/model/form/form';
import { TagSearch } from './edit/input/tag.search';
import { RemoteTagSearch } from './edit/input/tag.search.remote';
import { ITagValue } from '../../domain/model/form/tags';
import { LIST_VIEW_MODE, viewModeChanged } from '../../duck/form.list.duck';
import { ListGridItem } from './list/list.grid.item';
import { ListViewSelector } from './list/list.view.selector';
import { ListTableWithTags } from './list/list.table.with.tags';
import { ListTableSimple } from './list/list.table.simple';
import { Pagination, PaginationPosition } from './list/list.pagination';
import { ApiVersion } from '../../api/sdk/api-version';
import { DirectoryTreeScreen } from '../directories/directory.tree.screen';

export enum ButtonsVariant {
    DEFAULT,
    RECYCLE_BIN,
    REVISION_LIST,
}

export type actionCallback = (id?: string) => Promise<void>;

export interface IAppProps {
    apiVersion: number;
    buttons?: ButtonsVariant;
    currentViewMode: LIST_VIEW_MODE;
    getBadges: (form?: IFormListItem) => null | JSX.Element;
    getPrimaryAction: (form?: IFormListItem) => null | actionCallback;
    getSecondaryAction?: (form?: IFormListItem) => null | actionCallback;
    getSecondaryLabel?: (form: IFormListItem) => string;
    hasPagination: boolean;
    items: IFormListItem[];
    locale: string;
    onTagClicked: (tag: ITagValue) => void;
    refresh: () => void;
    viewModeChanged: typeof viewModeChanged;
}

export class FormList extends React.Component<IAppProps> {
    view = 'table';

    render(): JSX.Element {
        const {
            apiVersion,
            currentViewMode,
            hasPagination,
            items,
            locale,
            getPrimaryAction,
            refresh,
            viewModeChanged,
        } = this.props;

        return (
            <div>
                <div className="mb-5 d-flex">
                    {apiVersion < ApiVersion.PAGINATED ? (
                        <TagSearch />
                    ) : (
                        <RemoteTagSearch />
                    )}
                    <ButtonGroup>
                        <ListViewSelector
                            locale={locale}
                            currentViewMode={currentViewMode}
                            viewModeChanged={viewModeChanged}
                        />
                        <Button onClick={refresh} color="secondary">
                            <FontAwesomeIcon icon={faSync} />
                        </Button>
                    </ButtonGroup>
                </div>

                <div
                    className={classnames({
                        'list-view-columns':
                            apiVersion >= ApiVersion.DIRECTORIES,
                    })}
                >
                    {apiVersion >= ApiVersion.DIRECTORIES && (
                        <DirectoryTreeScreen />
                    )}

                    <div>
                        {hasPagination && (
                            <Pagination
                                position={PaginationPosition.TOP}
                                {...this.props}
                            />
                        )}

                        {currentViewMode === LIST_VIEW_MODE.TABLE && (
                            <ListTableWithTags {...this.props} />
                        )}
                        {currentViewMode === LIST_VIEW_MODE.SIMPLE_TABLE && (
                            <ListTableSimple {...this.props} />
                        )}
                        {currentViewMode === LIST_VIEW_MODE.GRID && (
                            <div className="grid-list">
                                {items.map((item, index) => (
                                    <ListGridItem
                                        key={index}
                                        index={index}
                                        item={item}
                                        locale={locale}
                                        getPrimaryAction={getPrimaryAction}
                                        onTagClicked={this.props.onTagClicked}
                                    />
                                ))}
                            </div>
                        )}
                        {hasPagination && (
                            <Pagination
                                position={PaginationPosition.BOTTOM}
                                {...this.props}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
