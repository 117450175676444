import React from 'react';
import { IInputComponentProps } from './input.component.props.interface';
import { CustomInput } from 'reactstrap';

export class SwitchInput extends React.Component<IInputComponentProps> {
    protected onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const isChecked = event.currentTarget.checked;

        this.props.onChange({
            currentTarget: {
                value: isChecked,
            },
        });
    };

    render(): JSX.Element {
        const { name, readonly, value } = this.props;
        const boolVal = Boolean(value);

        return (
            <div className="form-control">
                <CustomInput
                    id={`input-${name}`}
                    name={name}
                    type="switch"
                    checked={boolVal}
                    onChange={this.onChange}
                    className=""
                    disabled={readonly || false}
                />
            </div>
        );
    }
}
