import React from 'react';
import { Button, Table } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { translations } from '../../../../../translations/translations';
import { IInputComponentProps } from '../../input/input.component.props.interface';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import { IState } from '../../../../../store/reducer';
import { getLocale } from '../../../../../duck/localization.duck';

interface RemoveButtonProps {
    onClick: () => void;
}

interface AssocArrayInputElementProps extends IInputComponentProps {
    onChange: (string) => void;
}

interface AssocArrayInputElementRowDefinition {
    label: string;
    value: string;
}

export const RemoveButton = (props: RemoveButtonProps): JSX.Element => (
    <Button outline color="danger" onClick={props.onClick}>
        <FontAwesomeIcon icon={faTrash} />
    </Button>
);

export const InputElement = (
    props: AssocArrayInputElementProps
): JSX.Element => (
    <input
        id={`input-${props.name}`}
        name={props.name}
        value={props.value}
        type="text"
        onChange={(ev) => props.onChange(ev.currentTarget.value)}
        className="form-control"
    />
);

enum AssocArrayInputSubfieldType {
    VALUE = 'value',
    LABEL = 'label',
}

export interface IAssocInputComponentProps extends IInputComponentProps {
    locale?: string;
}

export class AssocArrayInputComponent extends React.Component<IAssocInputComponentProps> {
    onRowAdded = (): void => {
        let newValue;
        const oldValue = this.props.value;
        if (oldValue && oldValue.forEach) {
            newValue = [...this.props.value];
        } else {
            newValue = [];
        }
        newValue.push({
            [AssocArrayInputSubfieldType.LABEL]: 'Label',
            [AssocArrayInputSubfieldType.VALUE]: 'beispielwert',
        });
        this.props.onChange({
            currentTarget: {
                value: newValue,
            },
        });
    };

    onRemoveClicked = (index: number): void => {
        const newFieldValue = [...this.props.value];
        newFieldValue.splice(index, 1);
        this.props.onChange({
            currentTarget: {
                value: newFieldValue,
            },
        });
    };

    onValueChanged = (
        index: number,
        subfield: AssocArrayInputSubfieldType,
        newValue: string
    ): void => {
        let newFieldValue;
        const oldValue = this.props.value;
        if (oldValue && oldValue.length && oldValue.forEach) {
            newFieldValue = [...oldValue];
        } else {
            newFieldValue = [];
        }
        newFieldValue[index][subfield] = newValue;
        this.props.onChange({
            currentTarget: {
                value: newFieldValue,
            },
        });
    };

    renderRowItem(
        row: AssocArrayInputElementRowDefinition,
        index: number
    ): JSX.Element {
        const { label, value } = row;
        const { name: groupName } = this.props;
        return (
            <tr key={index}>
                <td>
                    <InputElement
                        name={`${groupName}-label-${index}`}
                        value={label}
                        onChange={(newValue) =>
                            this.onValueChanged(
                                index,
                                AssocArrayInputSubfieldType.LABEL,
                                newValue
                            )
                        }
                    />
                </td>
                <td>
                    <InputElement
                        name={`${groupName}-value-${index}`}
                        value={value}
                        onChange={(newValue) =>
                            this.onValueChanged(
                                index,
                                AssocArrayInputSubfieldType.VALUE,
                                newValue
                            )
                        }
                    />
                </td>
                <td>
                    <RemoveButton onClick={() => this.onRemoveClicked(index)} />
                </td>
            </tr>
        );
    }

    render(): JSX.Element {
        const { locale, value } = this.props;

        const AddButton = (props) => (
            <Button color="success" onClick={this.onRowAdded} {...props}>
                <FontAwesomeIcon icon={faPlus} />
            </Button>
        );
        const rows = value;

        return (
            <div className="w-100">
                <Table bordered>
                    <thead>
                        <tr>
                            <th>
                                {
                                    translations.formBuilder.assocArrayInput
                                        .head.label[locale]
                                }
                            </th>
                            <th>
                                {
                                    translations.formBuilder.assocArrayInput
                                        .head.value[locale]
                                }
                            </th>
                            <th>
                                <AddButton />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows &&
                            rows.map((row, item) =>
                                this.renderRowItem(row, item)
                            )}
                    </tbody>
                </Table>
            </div>
        );
    }
}

export const AssocArrayInputConnected = connect(
    (state: IState) => ({
        locale: getLocale(state),
    }),
    null
)(AssocArrayInputComponent);

export const AssocArrayInput = (props: IInputComponentProps): JSX.Element => (
    <AssocArrayInputConnected {...props} />
);
