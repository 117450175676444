import { User } from 'oidc-react';
import { IState } from '../store/reducer';
import { createSelector } from 'reselect';
import { Action, createAction, handleActions } from 'redux-actions';

/*
 * Constants
 */
export const MOUNT_POINT = 'auth';

/*
 * Model - duck specific model, domain models go to `domain` folder
 */

/*
 * Module properties - singletons and other module specific vars
 */

/*
 * State
 */

export interface IAuthState {
    authenticated: boolean;
    user?: User;
    requiresAuthentication: boolean;
}

export const DEFAULT_STATE = {
    authenticated: false,
    user: null,
    requiresAuthentication:
        typeof process.env.REACT_APP_AUTH_ENABLED !== 'undefined'
            ? JSON.parse(process.env.REACT_APP_AUTH_ENABLED)
            : true,
};

/*
 * Selectors
 */
export const getState = (state: IState): IAuthState => state[MOUNT_POINT];

export const isAuthenticated = createSelector(
    getState,
    (state: IAuthState) => state.authenticated
);

export const getUser = createSelector(
    getState,
    (state: IAuthState) => state.user
);

export const isAuthenticationEnabled = createSelector(
    getState,
    (state: IAuthState) => state.requiresAuthentication
);

/*
 * Actions
 */
export const authSucceeded = createAction<User>(
    MOUNT_POINT + '/AUTH_SUCCEEDED'
);

export const authDisabled = createAction(MOUNT_POINT + '/AUTH_DISABLED');

/*
 * Reducers
 */
/* eslint-disable-next-line */
export const authReducer = handleActions<IAuthState, any, any>(
    {
        [authSucceeded.toString()]: (
            state: IAuthState,
            action: Action<User | null>
        ) => {
            const user = action.payload;

            return {
                ...state,
                user,
                authenticated:
                    !state.requiresAuthentication || !!(user && user.id_token),
            };
        },
        [authDisabled.toString()]: (state: IAuthState) => {
            return {
                ...state,
                requiresAuthentication: false,
            };
        },
    },
    DEFAULT_STATE
);

/*
 * Sagas
 */

/*
 * Helpers
 */
