import React from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, UncontrolledTooltip } from 'reactstrap';

import { LocalizationContext } from './localization/localization.provider';

export const CompactButton = ({
    id,
    label,
    icon,
    color = 'primary',
    href = null,
    onClick = null,
    small = false,
    className = '',
}): JSX.Element => {
    return (
        <LocalizationContext.Consumer>
            {({ translate }) => (
                <Button
                    id={id}
                    color={color}
                    className={classnames('btn-compact', className, {
                        'btn-compact--small': small,
                    })}
                    aria-label={translate(label)}
                    href={href}
                    onClick={onClick}
                >
                    <FontAwesomeIcon icon={icon} />
                    <UncontrolledTooltip target={id} placement={'bottom'}>
                        {translate(label)}
                    </UncontrolledTooltip>
                </Button>
            )}
        </LocalizationContext.Consumer>
    );
};
