import { UserAwareSdkClient } from './user.aware.sdk.client';
import { Routes } from '../routing/route';
import { Directory } from '../../domain/model/directory/directory';

class DirectoryApi extends UserAwareSdkClient {
    getDirectoryTree(): Promise<Directory> {
        return this.getJson(Routes.directory.getDirectoryTree());
    }

    create(dir: Directory, parentDirectoryId = 0): Promise<Directory> {
        return this.postJson(Routes.directory.create(parentDirectoryId), dir);
    }

    delete(id: number): Promise<Directory> {
        return this.deleteJson(Routes.directory.delete(id), true);
    }

    update(dir: Directory): Promise<Directory> {
        return this.putJson(Routes.directory.update(dir.id), dir);
    }

    move(movedDirId: number, newParentId: number): Promise<Directory> {
        return this.putJson(
            Routes.directory.move(movedDirId, newParentId),
            null
        );
    }
}

export const directoryApi = new DirectoryApi();
