import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentAlt } from '@fortawesome/free-solid-svg-icons/faCommentAlt';
import { Alert, Button, Spinner } from 'reactstrap';
import { Announcement } from '../../domain/model/announcement/announcement';
import { connect } from 'react-redux';
import { IState } from '../../store/reducer';
import {
    AnnouncementFetchStatus,
    announcementsFetchStarted,
    getAnnouncementFetchStatus,
    getAnnouncements,
    interceptNetworkErrors,
    setAnnouncements,
} from '../../duck/alert.duck';
import { getLocale } from '../../duck/localization.duck';
import { bindActionCreators, Dispatch } from 'redux';
import { isAuthenticated } from '../../duck/auth.duck';
import {
    getColorByAnnouncementType,
    maybeFetchAnnouncements,
} from '../../util/announcements';
import { AnnouncementContent } from '../alert/announcement.content';

interface AnnouncementListProps {
    announcements: Announcement[];
    announcementsFetchStarted: typeof announcementsFetchStarted;
    announcementFetchStatus: AnnouncementFetchStatus;
    interceptNetworkErrors: typeof interceptNetworkErrors;
    isAuthenticated: boolean;
    locale: string;
    setAnnouncements: typeof setAnnouncements;
}

interface AnnouncementListState {
    isOpen: boolean;
}

export class AnnouncementListComponent extends React.Component<AnnouncementListProps> {
    state: AnnouncementListState = {
        isOpen: false,
    };

    async componentDidMount(): Promise<void> {
        await maybeFetchAnnouncements(this.props);
    }

    onOpen = async (): Promise<void> => {
        this.setState({ isOpen: !this.state.isOpen });
        await maybeFetchAnnouncements(this.props);
    };

    render(): JSX.Element {
        const { isOpen } = this.state;
        const { announcements, announcementFetchStatus } = this.props;

        return (
            <div className={'nav-announcement-list'}>
                <Button
                    color={'info'}
                    className={'mr-1'}
                    onClick={this.onOpen}
                    title={'Ankündigungen'}
                >
                    <FontAwesomeIcon icon={faCommentAlt} />
                </Button>
                {isOpen && (
                    <div className={'nav-announcement-list__modal bg-dark'}>
                        {announcementFetchStatus ===
                            AnnouncementFetchStatus.FETCHING && <Spinner />}
                        {announcements.map((announcement) => (
                            <Alert
                                key={announcement.id}
                                color={getColorByAnnouncementType(
                                    announcement.type
                                )}
                                className={'announcement-preview'}
                            >
                                <AnnouncementContent
                                    announcement={announcement}
                                />
                            </Alert>
                        ))}
                    </div>
                )}
            </div>
        );
    }
}

export const AnnouncementList = connect(
    (state: IState) => ({
        announcements: getAnnouncements(state),
        announcementFetchStatus: getAnnouncementFetchStatus(state),
        isAuthenticated: isAuthenticated(state),
        locale: getLocale(state),
    }),
    (dispatch: Dispatch) =>
        bindActionCreators(
            {
                announcementsFetchStarted,
                interceptNetworkErrors,
                setAnnouncements,
            },
            dispatch
        )
)(AnnouncementListComponent);
