import { handleActions, createAction, Action } from 'redux-actions';
import { createSelector } from 'reselect';
import { get as _get, set as _set, merge as _merge } from 'lodash';

import { FormExtension, FormType, IForm } from '../domain/model/form/form';
import { IState } from '../store/reducer';
import { ITagValue } from '../domain/model/form/tags';
import { MOUNT_POINT as FORM_LIST_MOUNT_POINT } from './form.list.duck';
import { ISchemaItem } from '../domain/model/form/form.schema';

/*
 * Constants
 */
export const MOUNT_POINT = 'formEdit';

/*
 * Model - duck specific model, domain models go to `domain` folder
 */
export enum FORM_EDIT_STATUS {
    NONE = 0,
    SAVED = 1,
    UNSAVED = 2,
    EDITED = 2,
    SAVE_ERROR = 3,
}

export interface IFormCard {
    name: string;
    collapsed: boolean;
}

export interface IFormEditState {
    initialFormData: IForm;
    formData: IForm;
    hasLoaded: boolean;
    formCards: IFormCard[];
    editStatus: FORM_EDIT_STATUS;
    revisionModalOpen: boolean;
}

/*
 * Module properties - singletons and other module specific vars
 */

/*
 * State
 */
export const DEFAULT_STATE = {
    editStatus: 0,
    initialFormData: {},
    formData: {
        name: '',
        slug: '',
        type: FormType.BASIC,
        website: {
            name: '',
            url: '',
        },
        language: '',
        extensions: [FormExtension.GOOGLE_GEO_SUGGEST],
        tags: [{ name: 'TEMPLATE' }],
        eloqua: {
            siteId: '1516083671',
            visitorKey: '2335d03c-d7f8-4b4b-8c75-4c4de91d9575',
            contactIdKey: '1e00fee3-73ec-4a03-a838-1620fa9e487e',
            formDataKey: '5728a785-e63e-4216-a5eb-75ed0793d4fd',
            extraDataKey: '',
        },
        display: {
            schema: [],
            attributes: {
                action: 'https://s1516083671.t.eloqua.com/e/f2',
                method: '',
                target: '',
                enctype: '',
                id: '',
                className: '',
            },
            fieldGroups: [],
        },
        spamProtection: {
            spamProtectionEnabled: false,
            honeypotFieldName: '',
        },
        fields: [],
        id: '',
        fieldGroups: [],
        dynamicContactForm: 'false',
        hideWhenFilled: false,
        skipWdl: false,
        options: {},
        settings: {},
        directoryId: 1,
    },
    hasLoaded: false,
    formCards: [
        {
            name: 'formAttributes',
            collapsed: true,
        },
        {
            name: 'eloquaConf',
            collapsed: true,
        },
        {
            name: 'hiddenFields',
            collapsed: true,
        },
        {
            name: 'misc',
            collapsed: true,
        },
        {
            name: 'honeypotExtractor',
            collapsed: true,
        },
    ],
    revisionModalOpen: false,
};

/*
 * Selectors
 */
export const getState = (state: IState): IFormEditState => state[MOUNT_POINT];

export const getEditStatus = createSelector(
    getState,
    (state: IFormEditState) => state.editStatus
);

export const getCurrentFormData = createSelector(
    getState,
    (state: IFormEditState) => state.formData
);

export const hasLoaded = createSelector(
    getState,
    (state: IFormEditState) => state.hasLoaded
);

export const getFieldValue = createSelector(
    (state, props) => ({
        value: _get(getState(state).formData, props.name),
        name: props.name,
    }),
    ({ name, value }) => {
        return value || DEFAULT_STATE.formData[name] || '';
    }
);

export const getFormCardCollapsedStatus = createSelector(
    (state, props) =>
        props.name &&
        getState(state).formCards.find((el) => el.name === props.name),
    (cardOptions: IFormCard) => (cardOptions && cardOptions.collapsed) || false
);

export const getTags = createSelector(
    (state: IState) => getState(state).formData.tags,
    (state: IState) => state[FORM_LIST_MOUNT_POINT].tags.store,
    (tags: ITagValue[], suggestions: ITagValue[]) => ({ tags, suggestions })
);

export const isRevisionModalOpen = createSelector(
    getState,
    (state: IFormEditState) => state.revisionModalOpen
);

/*
 * Actions
 */

export const loadAnimationToggle = createAction<boolean>(
    MOUNT_POINT + '/LOAD_ANIMATION_TOGGLE'
);

export const formEdited = createAction<IForm>(MOUNT_POINT + '/FORM_EDITED');

export const formDataReceived = createAction<IForm>(
    MOUNT_POINT + '/FORM_DATA_RECEIVED'
);

export const formReset = createAction(MOUNT_POINT + '/FORM_RESET');

export const formDuplicateIDReset = createAction(
    MOUNT_POINT + '/FORM_DUPLICATE_ID_RESET'
);

export const fieldValueChanged = createAction<{
    name: string;
    newValue: string;
}>(MOUNT_POINT + '/FIELD_VALUE_CHANGED');

export const fieldValueResetClicked = createAction<string>(
    MOUNT_POINT + '/FIELD_VALUE_RESET_CLICKED'
);

export const formFieldsDefinitionChanged = createAction<{
    form: Partial<IForm>;
    schema: ISchemaItem[];
}>(MOUNT_POINT + '/FORM_FIELDS_DEFINITION_CHANGED');

export const toggleCollapse = createAction<string>(
    MOUNT_POINT + '/FORM_CARD_TOGGLE_COLLAPSE'
);

export const tagsChanged = createAction<{ tag?: ITagValue; index?: number }>(
    MOUNT_POINT + '/TAGS_CHANGED'
);

export const updateEditStatus = createAction<number>(
    MOUNT_POINT + '/UPDATE_EDIT_STATUS'
);

export const toggleRevisionModal = createAction<boolean>(
    MOUNT_POINT + '/TOGGLE_REVISION_MODAL'
);

/*
 * Reducers
 */
/* eslint-disable-next-line */
export const formEditReducer = handleActions<IFormEditState, any, any>(
    {
        [loadAnimationToggle.toString()]: (
            state: IFormEditState,
            action: Action<boolean>
        ) => {
            const toggle = action.payload;
            const newState = { ...state };
            newState.hasLoaded = !toggle;

            return newState;
        },

        [formEdited.toString()]: (
            state: IFormEditState,
            action: Action<IForm>
        ) => {
            const formData: IForm = action.payload;
            const newState = { ...state };

            newState.formData = { ...formData };
            newState.editStatus = FORM_EDIT_STATUS.EDITED;

            return newState;
        },

        [formDataReceived.toString()]: (
            state: IFormEditState,
            action: Action<IForm>
        ) => {
            const formData: IForm = action.payload;
            const newState = { ...state };

            newState.initialFormData = { ...formData };
            newState.formData = { ...formData };
            newState.hasLoaded = true;

            return newState;
        },

        [formReset.toString()]: () => ({ ...DEFAULT_STATE }),

        [formDuplicateIDReset.toString()]: (state: IFormEditState) => {
            const newState = { ...state };
            delete newState.formData.id;
            return newState;
        },

        [fieldValueChanged.toString()]: (
            state: IFormEditState,
            action: Action<{ name: string; newValue: string }>
        ) => {
            const { name: fieldName, newValue } = action.payload;
            const newState = _merge({}, state);

            _set(newState.formData, fieldName, newValue);

            if (fieldName === 'type') {
                if (newValue === state.initialFormData.type) {
                    newState.formData.options = state.initialFormData.options;
                } else {
                    newState.formData.options = DEFAULT_STATE.formData.options;
                }
            }

            /* Check if there were actual changes and mark "dirty" */
            if (
                JSON.stringify(newState.formData) ===
                JSON.stringify(newState.initialFormData)
            ) {
                newState.editStatus = FORM_EDIT_STATUS.NONE;
            } else {
                newState.editStatus = FORM_EDIT_STATUS.EDITED;
            }

            return newState;
        },

        [fieldValueResetClicked.toString()]: (
            state: IFormEditState,
            action: Action<string>
        ) => {
            const fieldName: string = action.payload;
            const initialValue = _get(state.initialFormData, fieldName);
            const newState = _merge({}, state);

            _set(newState.formData, fieldName, initialValue);
            if (_get(state.formData, fieldName) !== initialValue) {
                newState.editStatus = FORM_EDIT_STATUS.EDITED;
            }

            return newState;
        },

        [formFieldsDefinitionChanged.toString()]: (
            state: IFormEditState,
            action: Action<{ form: Partial<IForm>; schema: ISchemaItem[] }>
        ) => {
            const {
                form: {
                    fields: fieldsDefinition,
                    controls: controlsDefinition,
                },
                schema: schemaDefinition,
            } = action.payload;

            const newState = { ...state };

            newState.formData.fields = fieldsDefinition;
            newState.formData.controls = controlsDefinition;
            newState.formData.display.schema = schemaDefinition;

            newState.editStatus = FORM_EDIT_STATUS.EDITED;

            return newState;
        },

        [toggleCollapse.toString()]: (
            state: IFormEditState,
            action: Action<string>
        ) => {
            const cardName = action.payload;
            const newState = { ...state };

            const formCardsConfig = newState.formCards.map((el) => {
                if (el.name === cardName) {
                    el.collapsed = !el.collapsed;
                }
                return el;
            });

            return {
                formCards: formCardsConfig,
                ...newState,
            };
        },

        [tagsChanged.toString()]: (
            state: IFormEditState,
            action: Action<{ tag?: ITagValue; index?: number }>
        ) => {
            const { tag, index } = action.payload;
            let tags = [...state.formData.tags];
            const newState = { ...state };
            if (index !== undefined && index !== null) {
                /* remove tag at the given index */
                tags.splice(index, 1);
            } else if (
                tag &&
                !(
                    state.formData.tags.findIndex(
                        (el) => el.name === tag.name
                    ) > -1
                )
            ) {
                tags = [].concat(tags, tag);
            }

            newState.formData.tags = tags;
            newState.editStatus = FORM_EDIT_STATUS.EDITED;
            return newState;
        },

        [updateEditStatus.toString()]: (
            state: IFormEditState,
            action: Action<number>
        ) => {
            const newState = { ...state };
            newState.editStatus = action.payload;
            return newState;
        },

        [toggleRevisionModal.toString()]: (
            state: IFormEditState,
            action: Action<boolean>
        ) => {
            return {
                ...state,
                revisionModalOpen: action.payload,
            };
        },
    },
    { ...DEFAULT_STATE }
);

/*
 * Sagas
 */

/*
 * Helpers
 */
