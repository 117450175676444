import { FetchErrorException, HttpException } from './exception';

export const request = async (
    input: RequestInfo,
    init?: RequestInit
): Promise<Response> => {
    let response;

    try {
        response = await fetch(input, { ...init, credentials: 'include' });
    } catch (e) {
        throw new FetchErrorException(e);
    }

    if (!response.ok) {
        throw HttpException.createFromResponse(response);
    }

    return response;
};
