/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ComponentClass } from 'react';
import { InputGroup, InputGroupAddon } from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import classnames from 'classnames';

import { IState } from '../../../../../store/reducer';
import { TextInput } from '../../input/text.input';
import {
    ISelectOption,
    IInputComponentProps,
    IInputComponentChangeEvent,
} from '../../input/input.component.props.interface';
import {
    getCurrentItem,
    getCurrentItemFieldValueById,
    currentItemFieldValueChanged,
    IFormBuilderItem,
} from '../../../../../duck/form.builder.duck';
import { Help, IHelpSetupProps } from '../../input/help';

interface IInputDispatchProps {
    fieldValueChanged: typeof currentItemFieldValueChanged;
}

interface IInputStateProps {
    item: IFormBuilderItem;
    currentValue: string | ISelectOption[];
    locale: string;
}

export interface IInputFieldInheritedProps {
    key?: string;
    locale: string;
    hidden?: boolean;
    onFieldChange;
}

export interface IInputFieldConfigProps {
    component?: React.FunctionComponent<IInputComponentProps> | ComponentClass;
    help?: IHelpSetupProps;
    label: any;
    name: string;
    options?: ISelectOption[];
    readonly?: boolean;
    onFieldChange?;
}

export interface IInputFieldConfigSetupProps extends IInputFieldConfigProps {
    isSpecialField?: boolean;
}

export type IInputFieldOwnProps = IInputFieldInheritedProps &
    IInputFieldConfigProps;

export type InputFieldProps = IInputDispatchProps &
    IInputFieldConfigProps &
    IInputStateProps &
    IInputFieldInheritedProps;

export class InputFieldComponent extends React.Component<InputFieldProps> {
    onFieldChange = (event: IInputComponentChangeEvent): void => {
        const { onFieldChange, name } = this.props;
        const newValue = event.currentTarget.value;
        onFieldChange(name, newValue);
    };

    render(): JSX.Element {
        const {
            currentValue,
            component,
            help,
            hidden,
            label: labelText,
            name,
            locale,
            options,
            readonly,
        } = this.props;
        const InputComponent = component || TextInput;
        const inputComponentProps: IInputComponentProps = {
            name,
            value: currentValue,
            onChange: this.onFieldChange,
            selectOptions: options,
            readonly,
        };

        return (
            <InputGroup className={classnames('mb-2', { 'd-none': hidden })}>
                <InputGroupAddon addonType="prepend">
                    <label
                        className="input-group-text"
                        style={{ minWidth: '180px' }}
                        htmlFor={`input-${name}`}
                    >
                        {labelText[locale]}
                    </label>
                </InputGroupAddon>

                <InputComponent {...inputComponentProps} />

                {help && (help.body || help.link) && (
                    <Help
                        name={name}
                        title={labelText}
                        isInputAddon
                        locale={locale}
                        body={help.body}
                        link={help.link}
                    />
                )}
            </InputGroup>
        );
    }
}

export const InputField = connect(
    (state: IState, ownProps: IInputFieldOwnProps) => ({
        item: getCurrentItem(state),
        currentValue: getCurrentItemFieldValueById(state, ownProps),
    }),
    (dispatch: Dispatch) =>
        bindActionCreators(
            {
                fieldValueChanged: currentItemFieldValueChanged,
            },
            dispatch
        )
)(InputFieldComponent);
