import { request } from '../../lib/http/request';
import { Routes } from '../routing/route';
import { jsonHeaders } from '../request/headers';

export enum ApiVersion {
    BASE = 1,
    PAGINATED = 2,
    REVISIONS = 2.2,
    TEMPLATES = 2.3,
    DIRECTORIES = 2.4,
    SPAM_PROTECT = 2.5,
}

export async function getAvailableApiVersion(): Promise<{
    version: number;
    authEnabled: boolean;
}> {
    const versionGetter = getVersion();
    const authEnabledGetter = getAuthEnabled();

    await Promise.all([versionGetter, authEnabledGetter]);
    const version = await versionGetter;
    const authEnabled = await authEnabledGetter;
    return { version, authEnabled };
}

async function getVersion(): Promise<number> {
    let version;
    try {
        const res = await request(Routes.version(), {
            method: 'GET',
            headers: jsonHeaders(),
        });

        if (!res.ok) {
            throw new Error('no valid version response');
        }

        version = await res.json();
    } catch (e) {
        version = ApiVersion.BASE;
    }

    return version;
}

async function getAuthEnabled(): Promise<boolean> {
    try {
        const res = await request(Routes.authEnabled(), {
            method: 'GET',
            headers: jsonHeaders(),
        });

        if (!res.ok) {
            return false;
        }

        return res.json();
    } catch (e) {
        return false;
    }
}
