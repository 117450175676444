import React from 'react';
import ReactTags from 'react-tag-autocomplete';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { IInputComponentProps } from './input.component.props.interface';
import { IState } from '../../../../store/reducer';
import { getTags, tagsChanged } from '../../../../duck/form.edit.duck';
import { ITagValue } from '../../../../domain/model/form/tags';
import { tagAdded } from '../../../../duck/form.list.duck';

const tagClassNames = {
    root: 'react-tags form-control',
    rootFocused: 'is-focused',
    selected: 'react-tags__selected',
    selectedTag: 'react-tags__selected-tag',
    selectedTagName: 'react-tags__selected-tag-name',
    search: 'react-tags__search',
    searchInput: 'react-tags__search-input',
    suggestions: 'react-tags__suggestions',
    suggestionActive: 'is-active',
    suggestionDisabled: 'is-disabled',
};

interface ITagInputConnectProps extends IInputComponentProps {
    tagsChanged: typeof tagsChanged;
    tagAdded: typeof tagAdded;
}

interface ITagInputProps extends ITagInputConnectProps {
    tagOptions: {
        tags: ITagValue[];
        suggestions: ITagValue[];
    };
}

export class TagInputComponent extends React.Component<
    ITagInputProps,
    unknown
> {
    render(): JSX.Element {
        const { tagOptions, tagsChanged, tagAdded } = this.props;
        const { tags, suggestions } = tagOptions;

        return (
            <ReactTags
                tags={tags}
                suggestions={suggestions}
                onDelete={(index) => tagsChanged({ index })}
                onAddition={(tag) => {
                    tag.id = tag.id || null;
                    tagsChanged({ tag });
                    tagAdded(tag);
                }}
                classNames={tagClassNames}
                delimiters={[',', 'Enter']}
                allowNew={true}
                autoresize={false}
            />
        );
    }
}

export const TagInput = connect(
    (state: IState) => ({
        tagOptions: getTags(state),
    }),
    (dispatch: Dispatch) =>
        bindActionCreators(
            {
                tagsChanged,
                tagAdded,
            },
            dispatch
        )
)(TagInputComponent);
