export const directoryTranslations = {
    add_config_here: {
        de: 'Konfiguration im aktuellen Verzeichnis erstellen',
    },
    create: {
        button: {
            de: 'Unterverzeichnis erstellen',
        },
        default: {
            de: 'Verzeichnis',
        },
        failed: {
            de: 'Verzeichnis konnte nicht angelegt werden.',
        },
        prompt: {
            de: 'Bitte einen Namen für das neue Verzeichnis wählen:',
        },
    },
    delete: {
        button: {
            de: 'Verzeichnis löschen',
        },
        confirm: {
            de: 'Verzeichnis löschen? Diese Aktion kann nicht rückgängig gemacht werden.',
        },
        failed: {
            de: 'Verzeichnis konnte nicht gelöscht werden',
        },
    },
    move: {
        failed: {
            de: 'Verschieben fehlgeschlagen.',
        },
    },
    protect: {
        badge: {
            de: 'Geschütztes Verzeichnis',
        },
        button: {
            de: 'Verzeichnisschutz ein- / ausschalten',
        },
        failed: {
            de: 'Umschalten des Verzeichnisschutzes fehlgeschlagen',
        },
    },
    rename: {
        button: {
            de: 'Umbenennen',
        },
        failed: {
            de: 'Umbenennen fehlgeschlagen.',
        },
        prompt: {
            de: 'Neuer Name:',
        },
    },
};
