import { handleActions, createAction, Action } from 'redux-actions';
import { IState } from '../store/reducer';
import { createSelector } from 'reselect';
import _intersection from 'lodash/intersection';
import _unionWith from 'lodash/unionWith';
import _isEqual from 'lodash/isEqual';
import _compact from 'lodash/compact';

import { IFormListItem } from '../domain/model/form/form';
import { ITagValue } from '../domain/model/form/tags';
import {
    getListViewModePreference,
    setListViewModePreference,
} from './helpers/getListViewModePreference';
import { Directory } from '../domain/model/directory/directory';

/*
 * Constants
 */
export const MOUNT_POINT = 'formList';

const tagSelectionChangedHandler = (
    state: IFormListState,
    action: Action<{ tag?: ITagValue; index?: number }>
) => {
    const { tag, index } = action.payload;
    let newState = { ...state };

    newState = updateTags(newState, tag, index);
    newState.currentPage = 1;

    return newState;
};

/**
 * @deprecated
 *
 * @param state
 * @param action
 */
const legacyTagSelectionChangedHandler = (
    state: IFormListState,
    action: Action<{ tag?: ITagValue; index?: number }>
) => {
    let newState = tagSelectionChangedHandler(state, action);

    newState = updateVisibleItems(newState);

    return newState;
};

/*
 * Model - duck specific model, domain models go to `domain` folder
 */
export enum SearchMode {
    DEFAULT,
    TAGS,
}

/*
 * Module properties - singletons and other module specific vars
 */

/*
 * Model - duck specific model, domain models go to `domain` folder
 */

export type SlugList = {
    [slug: string]: string;
};

export enum LIST_VIEW_MODE {
    TABLE = 'table',
    GRID = 'grid',
    SIMPLE_TABLE = 'simple-table',
}

/*
 * Module properties - singletons and other module specific vars
 */

/*
 * State
 */
export interface IFormListState {
    items: IFormListItem[];
    tags: {
        store: ITagValue[];
        current: ITagValue[];
    };
    // for validation purposes (avoid duplicate slugs)
    slugs: SlugList;
    hasLoaded: boolean;
    viewMode: LIST_VIEW_MODE;
    pages: number;
    currentPage: number;
    searchMode: SearchMode;
    // directories
    directoryTree: Directory | null;
    currentDirectoryId: number;

    /** @deprecated */
    visibleItems: IFormListItem[];
}

export const DEFAULT_STATE: IFormListState = {
    items: [],
    tags: {
        store: [],
        current: [],
    },
    slugs: {},
    hasLoaded: false,
    viewMode: getListViewModePreference(),
    pages: 0,
    currentPage: 1,
    searchMode: SearchMode.DEFAULT,
    directoryTree: null,
    currentDirectoryId: 1,

    /** @deprecated */
    visibleItems: [],
};

/*
 * Selectors
 */
export const getState = (state: IState): IFormListState => state[MOUNT_POINT];

export const getFormList = createSelector(
    getState,
    (state: IFormListState) => state.items
);

/** @deprecated */
export const getVisibleItems = createSelector(
    getState,
    (state: IFormListState) => state.visibleItems
);

export const getTagSuggestions = createSelector(
    getState,
    (state: IFormListState) => state.tags.store
);

export const getCurrentTags = createSelector(
    getState,
    (state: IFormListState) => state.tags.current
);

export const hasListLoaded = createSelector(
    getState,
    (state: IFormListState) => state.hasLoaded
);

export const getSlugs = createSelector(
    getState,
    (state: IFormListState) => state.slugs
);

export const getViewMode = createSelector(
    getState,
    (state: IFormListState) => state.viewMode
);

export const getPageCount = createSelector(
    getState,
    (state: IFormListState) => state.pages
);

export const getCurrentPage = createSelector(
    getState,
    (state: IFormListState) => state.currentPage
);

export const getSearchMode = createSelector(
    getState,
    (state: IFormListState) => state.searchMode
);

export const getDirectoryTree = createSelector(
    getState,
    (state: IFormListState) => state.directoryTree
);

export const getCurrentDirectoryId = createSelector(
    getState,
    (state: IFormListState) => state.currentDirectoryId
);

/*
 * Actions
 */
export const formListLoaded = createAction<{
    forms: IFormListItem[];
    mapTags?: boolean;
}>(MOUNT_POINT + '/FORM_LIST_LOADED');

export const tagSelectionChanged = createAction<{
    tag?: ITagValue;
    index?: number;
}>(MOUNT_POINT + '/TAG_SELECTION_CHANGED');

/** @deprecated */
export const tagsChanged = createAction<{
    tag?: ITagValue;
    index?: number;
}>(MOUNT_POINT + '/TAGS_CHANGED');

export const tagSelectionOverride = createAction<ITagValue[]>(
    MOUNT_POINT + '/TAG_SELECTION_OVERRIDE'
);

export const tagAdded = createAction<ITagValue>(MOUNT_POINT + '/TAG_ADDED');

export const tagListChanged = createAction<ITagValue[]>(
    MOUNT_POINT + '/TAG_LIST_CHANGED'
);

export const viewModeChanged = createAction<LIST_VIEW_MODE>(
    MOUNT_POINT + '/VIEW_MODE_CHANGED'
);

export const pageCountChanged = createAction<number>(
    MOUNT_POINT + '/PAGE_COUNT_CHANGED'
);

export const pageChanged = createAction<number>(MOUNT_POINT + '/PAGE_CHANGED');

export const searchModeChanged = createAction<SearchMode>(
    MOUNT_POINT + '/SEARCH_MODE_CHANGED'
);

export const directoryTreeUpdated = createAction<Directory | null>(
    MOUNT_POINT + '/DIRECTORY_TREE_UPDATED'
);

export const currentDirectoryChanged = createAction<number>(
    MOUNT_POINT + '/CURRENT_DIRECTORY_CHANGED'
);

/*
 * Reducers
 */
/* eslint-disable-next-line */
export const formListReducer = handleActions<IFormListState, any, any>(
    {
        [formListLoaded.toString()]: (
            state: IFormListState,
            action: Action<{ forms: IFormListItem[]; mapTags?: boolean }>
        ) => {
            const { forms, mapTags = false } = action.payload;

            let newState = {
                ...state,
                items: [...forms],
                slugs: mapSlugs(forms),
                hasLoaded: true,
            };

            /** @deprecated */
            if (mapTags) {
                newState = {
                    ...newState,
                    ...parseAndConvertFormListItems({ ...state }, forms),
                };
            }

            return newState;
        },

        [tagsChanged.toString()]: legacyTagSelectionChangedHandler,

        [tagSelectionChanged.toString()]: tagSelectionChangedHandler,

        [tagSelectionOverride.toString()]: (
            state: IFormListState,
            action: Action<ITagValue[]>
        ) => {
            return {
                ...state,
                tags: {
                    ...state.tags,
                    current: [...action.payload],
                },
            };
        },

        [tagAdded.toString()]: (
            state: IFormListState,
            action: Action<ITagValue>
        ) => {
            const newState = { ...state };
            let tagList = [...newState.tags.store];
            const tag = action.payload;

            if (tagList.findIndex((el) => el.name === tag.name) === -1) {
                /* this tag is not present in the list yet, so we will add it */
                tagList = [].concat(tagList, tag);
            }

            newState.tags.store = tagList;
            return newState;
        },

        [tagListChanged.toString()]: (
            state: IFormListState,
            action: Action<ITagValue[]>
        ) => {
            const tags = action.payload;
            let currentTags = [...state.tags.current];

            if (state.tags.current.some((i) => !i.name)) {
                const updatedTags = currentTags.map((tag: ITagValue) => {
                    return tags.find((e) => e.id === tag.id);
                });
                currentTags = [...updatedTags];
            }

            return {
                ...state,
                tags: {
                    current: currentTags,
                    store: tags,
                },
            };
        },

        [viewModeChanged.toString()]: (
            state: IFormListState,
            action: Action<LIST_VIEW_MODE>
        ) => {
            const newState = { ...state };
            const newViewMode = action.payload;
            setListViewModePreference(newViewMode);

            newState.viewMode = newViewMode;
            return newState;
        },

        [pageCountChanged.toString()]: (
            state: IFormListState,
            action: Action<number>
        ) => {
            return {
                ...state,
                pages: action.payload,
            };
        },

        [pageChanged.toString()]: (
            state: IFormListState,
            action: Action<number>
        ) => {
            return {
                ...state,
                currentPage: action.payload,
            };
        },

        [searchModeChanged.toString()]: (
            state: IFormListState,
            action: Action<SearchMode>
        ) => {
            return {
                ...state,
                currentPage: 1,
                searchMode: action.payload,
            };
        },

        [directoryTreeUpdated.toString()]: (
            state: IFormListState,
            action: Action<Directory | null>
        ) => {
            return {
                ...state,
                directoryTree: action.payload,
            };
        },

        [currentDirectoryChanged.toString()]: (
            state: IFormListState,
            action: Action<number>
        ) => {
            return {
                ...state,
                currentDirectoryId: action.payload,
            };
        },
    },
    { ...DEFAULT_STATE }
);

/*
 * Sagas
 */

/*
 * Helpers
 */
function mapSlugs(formList: IFormListItem[]): SlugList {
    const slugs = {};

    formList.forEach((item) => {
        slugs[item.slug] = item.id;
    });

    return slugs;
}

const parseAndConvertFormListItems = (state, formList) => {
    let newTagStore = [...state.tags.store];

    const visibleFormList = formList.map((item): IFormListItem => {
        if (item.tags && item.tags.length) {
            newTagStore = _unionWith(newTagStore, item.tags, _isEqual);
        }
        return { ...item };
    });

    return {
        visibleItems: [...visibleFormList],
        tags: {
            ...state.tags,
            store: [...newTagStore],
        },
    };
};

const updateTags = (state, newTag, index) => {
    let tags = [...state.tags.current];

    if (index !== undefined && index !== null) {
        /* remove tag at the given index */
        tags.splice(index, 1);
    } else if (
        newTag &&
        !(state.tags.current.findIndex((el) => el.name === newTag.name) > -1)
    ) {
        // add tag
        tags = [].concat(tags, newTag);

        // also add it to the pool of suggested tags
        let store = [...state.tags.store];
        const storeIndex = store.findIndex((el) => el.name === newTag.name);
        if (storeIndex === -1) {
            store = [].concat(store, newTag);
            state.tags.store = store;
        }
    }

    state.tags.current = tags;
    return state;
};

/**
 * @deprecated: list visibility / filtering / pagination is handled via the backend
 *
 * @param state
 */
const updateVisibleItems = (state) => {
    const search = state.tags.current.map((tag) => tag.name);

    if (!search.length) {
        state.visibleItems = [...state.items];
    } else {
        let newVisibleItems = state.items.map((item) => {
            const itemTags = item.tags.map((tag) => tag.name);

            if (_intersection(itemTags, search).length !== search.length) {
                return null;
            }

            return item;
        });
        newVisibleItems = _compact(newVisibleItems);

        state.visibleItems = [...newVisibleItems];
    }

    return state;
};
