import { createSelector } from 'reselect';
import { Action, createAction, handleActions } from 'redux-actions';
import _set from 'lodash/set';
import _get from 'lodash/get';

import { IForm } from '../domain/model/form/form';
import {
    InputFieldType,
    InputFieldValidator,
} from '../domain/model/form/input.field';
import { ISelectOption } from '../components/form/edit/input/input.component.props.interface';
import { IState } from '../store/reducer';
import { IInputFieldOwnProps } from '../components/form/edit/formBuilder/input/input.field';
import {
    FieldFieldsConfig,
    IInputGroupConfigProps,
    PickFieldsByFieldType,
    PickSpecialFieldsByFieldName,
} from '../configuration/formBuilder/field.fields';
import { GroupFieldsConfig } from '../configuration/formBuilder/group.fields';
import { SubmitFieldsConfig } from '../configuration/formBuilder/submit.fields';
import { mapToFormModel } from './helpers/map.to.form.model';
import { ISchemaItem } from '../domain/model/form/form.schema';
import { HtmlFieldsConfig } from '../configuration/formBuilder/html.fields';
import { TreeItem } from '../domain/model/lib/tree.item';

/**
 * For historical reasons and simplicity, the form builder component maintains
 * its own state of the fields and groups configuration and only maps to/from
 * the global "formData" state upon loading and saving.
 */

/*
 * Constants
 */

export enum IFormBuilderItemType {
    GROUP = 'group',
    FIELD = 'field',
    SUBMIT = 'submit',
    HTML = 'html',
}

export const SUPPORTED_ITEM_TYPES = [
    IFormBuilderItemType.GROUP,
    IFormBuilderItemType.FIELD,
    IFormBuilderItemType.SUBMIT,
    IFormBuilderItemType.HTML,
];

export interface ILabelParameters {
    privacyPolicyLink?: string;
    privacyPolicyText?: string;
    privacyPolicyProvider?: string;
}

/*
 * Model - duck specific model, domain models go to `domain` folder
 */

export interface IFormBuilderItem extends TreeItem {
    id: number;
    parent: number;
    text: string;
    droppable: boolean;
    data: {
        type: IFormBuilderItemType;
        // @FIXME: what.
        // locale: string;
        props: IFormBuilderItemProps;
    };
}

export interface IFormBuilderItemProps {
    name: string;
    inputName?: string;
    inputType?: InputFieldType | '';
    checkedValue?: string;
    className?: string;
    eloquaName?: string;
    placeholder?: string;
    value?: string;
    validators?: InputFieldValidator[];
    validatorOverride?: boolean;
    selectOptions?: ISelectOption[];
    maxChars?: number;
    labelParams?: ILabelParameters;
    labelText?: string;
    labelClassName?: string;
    inputClassName?: string;
    overridable?: boolean;
    urlParameterName?: string;
    // Progressive
    fieldGroup?: string;
    // Submit
    text?: string;
    textWhenFilled?: string;
    // Request / special settings
    settings?: {
        formnames?: string;
        formnamelist?: string;
        takeValuesFromFields?: string;
    };
    // Setup / Meta
    configurableOptions?: IInputGroupConfigProps[];
}

/*
 * Module properties - singletons and other module specific vars
 */

/*
 * State
 */

const MOUNT_POINT = 'formBuilder';

export interface IFormBuilderState {
    addButtonDropdownOpen: boolean;
    items: IFormBuilderItem[];
    editingOverlayVisible: boolean;
    currentItem: IFormBuilderItem;
}

export const DEFAULT_STATE = {
    addButtonDropdownOpen: false,
    items: [],
    editingOverlayVisible: false,
    currentItem: {
        id: null,
        parent: null,
        text: '',
        droppable: false,
        data: {
            type: null,
            props: {
                name: '',
            },
            // @FIXME: what.
            locale: null,
        },
    },
};

/*
 * Selectors
 */

export const getState = (state: IState): IFormBuilderState =>
    state[MOUNT_POINT];

export const isAddButtonDropdownOpen = createSelector(
    getState,
    (state: IFormBuilderState) => state.addButtonDropdownOpen
);

export const getFormBuilderItems = createSelector(
    getState,
    (state: IFormBuilderState) => {
        return state.items;
    }
);

export const getEditingOverlayVisibility = createSelector(
    getState,
    (state: IFormBuilderState) => state.editingOverlayVisible
);

export const getCurrentItem = createSelector(
    getState,
    (state: IFormBuilderState) => state.currentItem
);

export const getCurrentItemFieldValueById = createSelector(
    (state: IState, props: IInputFieldOwnProps) => ({
        value: _get(getState(state).currentItem.data.props, props.name),
        name: props.name,
    }),
    ({ value }) => value
);

export const getMappedFormPartial = createSelector(
    getState,
    (
        state: IFormBuilderState
    ): { form: Partial<IForm>; schema: ISchemaItem[] } =>
        mapToFormModel(state.items)
);

/*
 * Actions
 */

export const toggleAddButtonDropdown = createAction(
    MOUNT_POINT + '/TOGGLE_ADD_BUTTON_DD'
);

export const formBuilderDataInitialized = createAction<IFormBuilderItem[]>(
    MOUNT_POINT + '/FORM_BUILDER_DATA_INITIALIZED'
);

export const itemsChanged = createAction<IFormBuilderItem[]>(
    MOUNT_POINT + '/ITEMS_CHANGED'
);

export const currentItemFieldValueChanged = createAction<{
    name: string;
    newValue: string;
}>(MOUNT_POINT + '/CURRENT_ITEM_FIELD_VALUE_CHANGED');

export const resetCurrentItem = createAction(
    MOUNT_POINT + '/RESET_CURRENT_ITEM'
);

export const initNewItemAsCurrent = createAction<{
    type: IFormBuilderItemType;
    locale: string;
}>(MOUNT_POINT + '/INIT_NEW_ITEM_AS_CURRENT');

export const initItemAsCurrentById = createAction<number>(
    MOUNT_POINT + '/INIT_ITEM_AS_CURRENT_BY_ID'
);

export const saveCurrentItem = createAction(MOUNT_POINT + '/SAVE_CURRENT_ITEM');

export const deleteItemById = createAction<number>(
    MOUNT_POINT + '/DELETE_ITEM_BY_ID'
);

export const showEditingOverlay = createAction(
    MOUNT_POINT + '/SHOW_EDITING_OVERLAY'
);

export const hideEditingOverlay = createAction(
    MOUNT_POINT + '/HIDE_EDITING_OVERLAY'
);

/*
 * Reducers
 */
/* eslint-disable-next-line */
export const formBuilderReducer = handleActions<IFormBuilderState, any, any>(
    {
        [toggleAddButtonDropdown.toString()]: (state: IFormBuilderState) => {
            const newState = { ...state };
            newState.addButtonDropdownOpen = !state.addButtonDropdownOpen;
            return newState;
        },

        [formBuilderDataInitialized.toString()]: (
            state: IFormBuilderState,
            action: Action<IFormBuilderItem[]>
        ) => {
            const newState = { ...state };
            newState.items = action.payload;

            return newState;
        },

        [itemsChanged.toString()]: (
            state: IFormBuilderState,
            action: Action<IFormBuilderItem[]>
        ) => {
            const newState = { ...state };
            newState.items = action.payload;

            return newState;
        },

        [currentItemFieldValueChanged.toString()]: (
            state: IFormBuilderState,
            action: Action<{ name: string; newValue: string }>
        ) => {
            const newState = { ...state };
            const { name, newValue } = action.payload;
            _set(newState.currentItem.data.props, name, newValue);
            if (name === 'inputType') {
                newState.currentItem.data.props.configurableOptions =
                    getConfigurableOptions(newState.currentItem, newValue);
            }
            if (name === 'name') {
                _set(newState.currentItem, 'text', newValue);
            }
            return newState;
        },

        [resetCurrentItem.toString()]: (state: IFormBuilderState) => {
            const newState = { ...state };
            newState.currentItem =
                DEFAULT_STATE.currentItem as IFormBuilderItem;
            return newState;
        },

        [initNewItemAsCurrent.toString()]: (
            state: IFormBuilderState,
            action: Action<{ type: IFormBuilderItemType; locale: string }>
        ) => {
            const newState = { ...state };
            const { type, locale } = action.payload;
            newState.currentItem = createNewFormbuilderItem(
                type,
                locale,
                newState.items
            );
            newState.currentItem.data.props.configurableOptions =
                getConfigurableOptions(newState.currentItem, null);

            return newState;
        },

        [initItemAsCurrentById.toString()]: (
            state: IFormBuilderState,
            action: Action<number>
        ) => {
            const newState = { ...state };
            const { node: existingItem } = findNodeInTreeById(
                newState.items,
                action.payload
            );
            newState.currentItem = existingItem as IFormBuilderItem;
            newState.currentItem.data.props.configurableOptions =
                getConfigurableOptions(existingItem as IFormBuilderItem);

            return newState;
        },

        [saveCurrentItem.toString()]: (state: IFormBuilderState) => {
            const newState = { ...state };

            const id = newState.currentItem.id;
            const { node: existingItem, index } = findNodeInTreeById(
                newState.items,
                id
            );
            if (existingItem !== null) {
                newState.items[index] = newState.currentItem;
            } else {
                newState.items = newState.items.concat(newState.currentItem);
            }

            return newState;
        },

        [deleteItemById.toString()]: (
            state: IFormBuilderState,
            action: Action<number>
        ) => {
            const newState = { ...state };

            const { index } = findNodeInTreeById(
                newState.items,
                action.payload
            );
            if (index > -1) {
                newState.items.splice(index, 1);
            }

            const children = newState.items.filter(
                (e) => e.parent === action.payload
            );
            if (children.length) {
                children.forEach((c) => {
                    const index = newState.items.indexOf(c);
                    newState.items.splice(index, 1);
                });
            }

            return newState;
        },

        [showEditingOverlay.toString()]: (state: IFormBuilderState) => {
            const newState = { ...state };
            newState.editingOverlayVisible = true;
            return newState;
        },

        [hideEditingOverlay.toString()]: (state: IFormBuilderState) => {
            const newState = { ...state };
            newState.editingOverlayVisible = false;
            return newState;
        },
    },
    { ...DEFAULT_STATE }
);

/*
 * Sagas
 */

/*
 * Helpers
 */

type NodeFinderResult = {
    node?: IFormBuilderItem;
    index: number;
};

export const findNodeInTreeById = (
    tree: IFormBuilderItem[],
    id: number
): NodeFinderResult => {
    const index = tree.findIndex((e) => e.id === id);

    return {
        node: tree[index] || null,
        index,
    };
};

/**
 * Create an ID and set default values and parameters to initialise a new item
 * into state
 *
 * @param {IFormBuilderItemType} type
 * @param {string} locale
 * @param {IFormBuilderItem[]} tree
 *
 * @returns IFormBuilderItem
 */
const createNewFormbuilderItem = (
    type: IFormBuilderItemType,
    locale: string,
    tree: IFormBuilderItem[]
): IFormBuilderItem => {
    let name = 'New Item';
    if (type === IFormBuilderItemType.GROUP) name = 'Group';
    if (type === IFormBuilderItemType.HTML) name = 'HTML Block';

    const newItem: IFormBuilderItem = {
        id: tree.length + 1,
        text: '',
        droppable: type === IFormBuilderItemType.GROUP,
        data: {
            type: type,
            // locale,
            props: {
                name,
                value: '',
                className:
                    type === IFormBuilderItemType.GROUP ? 'form-row' : '',
            },
        },
        parent: 0,
    };

    newItem.data.props.configurableOptions = [];

    if (type === IFormBuilderItemType.SUBMIT) {
        newItem.text = 'Submit';
        newItem.data.props.name = 'Submit';
        newItem.data.props.text = 'Senden';
    }

    return newItem;
};

const tagHiddenFields = (fields, item: IFormBuilderItem, inputType) => {
    return fields.map((option) => {
        const updatedOption = { ...option };

        let isPickedByType = true;
        if (inputType) {
            isPickedByType =
                PickFieldsByFieldType[inputType].findIndex(
                    (key) => key === option.name
                ) > -1;
        }

        const isSpecialField = !!option.isSpecialField;
        const isPickedBySpecialFieldConfig = isSpecialField
            ? PickSpecialFieldsByFieldName[item.data.props.name] &&
              PickSpecialFieldsByFieldName[item.data.props.name].findIndex(
                  (key) => key === option.name
              ) > -1
            : false;
        const isVisible = isSpecialField
            ? isPickedBySpecialFieldConfig
            : isPickedByType;

        updatedOption.hidden = !isVisible;
        return updatedOption;
    });
};

export const getConfigurableOptions = (
    item: IFormBuilderItem,
    explicitInputType?: string
): IInputGroupConfigProps[] => {
    const { type } = item.data;
    const inputType = explicitInputType || item.data.props.inputType;
    switch (type) {
        case IFormBuilderItemType.FIELD:
            return updateFieldsAndGroups(item, inputType);
        case IFormBuilderItemType.GROUP:
            return GroupFieldsConfig;
        case IFormBuilderItemType.SUBMIT:
            return SubmitFieldsConfig;
        case IFormBuilderItemType.HTML:
            return HtmlFieldsConfig;
    }
};

function updateFieldsAndGroups(
    item: IFormBuilderItem,
    inputType: string
): IInputGroupConfigProps[] {
    return FieldFieldsConfig.map((optionGroup) => {
        const newOptionGroup = { ...optionGroup };
        newOptionGroup.fields = tagHiddenFields(
            optionGroup.fields,
            item,
            inputType
        );
        return newOptionGroup;
    });
}
