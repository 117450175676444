import React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import {
    Button,
    Modal,
    ModalBody,
    ModalHeader,
    UncontrolledTooltip,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderOpen } from '@fortawesome/free-solid-svg-icons';

import { IFormListItem } from '../../domain/model/form/form';
import { CloseButton } from '../close.button';
import { IState } from '../../store/reducer';
import {
    getCurrentDirectoryId,
    getDirectoryTree,
} from '../../duck/form.list.duck';
import { interceptNetworkErrors } from '../../duck/alert.duck';
import { DirectoryTree } from './directory.tree';
import { Directory } from '../../domain/model/directory/directory';
import { formApi } from '../../api/sdk/form';
import { DirectoryContext } from './directory.provider';
import { translations } from '../../translations/translations';
import { getApiVersion } from '../../duck/api.duck';
import { ApiVersion } from '../../api/sdk/api-version';
import { TreeItem } from '../../domain/model/lib/tree.item';

interface FormMoveOwnProps {
    item: IFormListItem;
}

interface FormMoveConnectProps {
    apiVersion: number;
    currentDirectoryId: number;
    directoryTree: Directory;
    interceptNetworkErrors: typeof interceptNetworkErrors;
}

type FormMoveProps = FormMoveConnectProps & FormMoveOwnProps;

interface FormMoveState {
    modalOpen: boolean;
}

class FormMoveComponent extends React.Component<FormMoveProps> {
    state: FormMoveState = {
        modalOpen: false,
    };

    static contextType = DirectoryContext;

    onSelectTargetDirectory = async (directory: TreeItem) => {
        const { interceptNetworkErrors, item } = this.props;
        try {
            await formApi.moveForm(item.id, directory.id);
            this.context.changeDirectory(directory.id);
        } catch (e) {
            interceptNetworkErrors({
                exception: e,
                locale: this.context.locale,
                message: 'alert.network.form_move_fail',
            });
        }
        this.setState({ modalOpen: false });
    };

    Button = () => {
        const { item } = this.props;

        return (
            <Button
                id={`form-move-btn-${item.id}`}
                color="warning"
                className="btn-compact"
                aria-label={
                    translations.formAction.move.button[this.context.locale]
                }
                onClick={() => {
                    this.setState({ modalOpen: true });
                }}
            >
                <FontAwesomeIcon icon={faFolderOpen} />
                <UncontrolledTooltip
                    target={`form-move-btn-${item.id}`}
                    placement={'bottom'}
                >
                    {translations.formAction.move.button[this.context.locale]}
                </UncontrolledTooltip>
            </Button>
        );
    };

    Modal = () => {
        const { currentDirectoryId, directoryTree } = this.props;
        const { modalOpen } = this.state;

        return (
            <Modal
                isOpen={modalOpen}
                className="text-center modal-dialog-centered"
            >
                <ModalHeader
                    className="d-flex justify-content-between"
                    close={
                        <CloseButton
                            toggleOpen={() =>
                                this.setState({ modalOpen: false })
                            }
                        />
                    }
                >
                    {translations.formAction.move.header[this.context.locale]}
                </ModalHeader>
                <ModalBody>
                    <DirectoryTree
                        getOnNodeClick={(directoryId) => {
                            return async () => {
                                this.onSelectTargetDirectory(directoryId);
                            };
                        }}
                        onMoveDrop={() => null}
                        currentDirectoryId={currentDirectoryId}
                        directoryTree={directoryTree}
                    />
                </ModalBody>
            </Modal>
        );
    };

    render() {
        return (
            this.props.apiVersion >= ApiVersion.DIRECTORIES && (
                <>
                    <this.Button />
                    <this.Modal />
                </>
            )
        );
    }
}

export const FormMove = connect(
    (state: IState) => ({
        apiVersion: getApiVersion(state),
        currentDirectoryId: getCurrentDirectoryId(state),
        directoryTree: getDirectoryTree(state),
    }),
    (dispatch: Dispatch) =>
        bindActionCreators(
            {
                interceptNetworkErrors,
            },
            dispatch
        )
)(FormMoveComponent);
