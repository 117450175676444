import React from 'react';
import { ScaleLoader } from 'react-spinners';

export class Spinner extends React.Component {
    render(): JSX.Element {
        return (
            <div className="spinner">
                <ScaleLoader />
            </div>
        );
    }
}
