import { translations } from '../../translations/translations';
import { IInputGroupConfigProps } from './field.fields';

export const HtmlFieldsConfig: IInputGroupConfigProps[] = [
    {
        title: translations.formBuilder.fieldGroup.html,
        collapsed: false,
        fields: [
            {
                name: 'value',
                label: translations.formBuilder.htmlContent,
                readonly: false,
            },
        ],
    },
];
