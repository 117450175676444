import React from 'react';
import { Table } from 'reactstrap';

import { translations } from '../../../translations/translations';
import DateFormatted from '../../date/date.formatted';
import { ListItemButtons } from './list.item.buttons';
import { IAppProps } from '../form.list';

export const ListTableWithTags = (props: IAppProps): JSX.Element => {
    const { locale, onTagClicked, items } = props;

    return (
        <Table className="list-table">
            <thead>
                <tr>
                    <th>{translations.formElement.field.slug[locale]}</th>
                    <th>{translations.formElement.field.name[locale]}</th>
                    <th>
                        {translations.formElement.field.website.url[locale]}
                    </th>
                    <th>{translations.formElement.field.updated[locale]}</th>
                    <th />
                </tr>
            </thead>
            {items.map((item, index) => {
                const bgColor = index % 2 === 0 ? '#f0f0f0' : '#dcdcdc';
                // const darkerBg = (index % 2 === 0) ? '#e6e6e6' : '#d2d2d2';
                return (
                    <tbody
                        key={index}
                        style={{ backgroundColor: bgColor }}
                        className="gh-list--striped"
                    >
                        <tr>
                            <th scope="row">{item.slug || '-'}</th>
                            <td>{item.name}</td>
                            <td>
                                <a
                                    href={item.website.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {item.website.url}
                                </a>
                            </td>
                            <td>
                                <DateFormatted dateString={item.updatedAt} />
                            </td>
                            <td>
                                <ListItemButtons item={item} {...props} />
                            </td>
                        </tr>
                        {item.tags && !!item.tags.length && (
                            <tr>
                                <td style={{ backgroundColor: '#fff' }} />
                                <td
                                    colSpan={4}
                                    className="list-table__row__tags"
                                >
                                    {item.tags.map((tag, i) => (
                                        <button
                                            key={i}
                                            color="info"
                                            onClick={() => {
                                                onTagClicked(tag);
                                            }}
                                            className="react-tags__selected-tag"
                                        >
                                            {tag.name}
                                        </button>
                                    ))}
                                </td>
                            </tr>
                        )}
                    </tbody>
                );
            })}
        </Table>
    );
};
