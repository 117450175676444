import classnames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { IState } from '../../../store/reducer';
import {
    getCurrentPage,
    getPageCount,
    pageChanged,
} from '../../../duck/form.list.duck';
import { bindActionCreators, Dispatch } from 'redux';

const PaginationPages = (props: {
    pages: number;
    paginated: number;
    onPageSelect: (page: number) => void;
}): JSX.Element => {
    const { pages, paginated, onPageSelect } = props;
    return (
        <>
            {Array(pages)
                .fill(null)
                .map((e, i) => (
                    <li
                        className={classnames('page-item', {
                            active: paginated === i + 1,
                        })}
                        aria-current={paginated === i + 1 ? 'page' : null}
                        key={i + 1}
                    >
                        <button
                            key={i + 1}
                            className={classnames('page-link', {
                                'pagination__button--active':
                                    paginated === i + 1,
                            })}
                            onClick={() => {
                                onPageSelect(i + 1);
                            }}
                        >
                            {i + 1}
                        </button>
                    </li>
                ))}
        </>
    );
};

interface PaginationConnectProps {
    currentPage: number;
    pageCount: number;
    pageChanged: typeof pageChanged;
}

export enum PaginationPosition {
    TOP,
    BOTTOM,
}

interface PaginationOwnProps {
    position?: PaginationPosition;
}

export type PaginationProps = PaginationConnectProps & PaginationOwnProps;

const PaginationComponent = (props: PaginationProps): JSX.Element => {
    const {
        pageCount: pages,
        currentPage: paginated,
        pageChanged,
        position = null,
    } = props;

    return (
        <nav
            className={classnames('nav--pagination', {
                top: position === PaginationPosition.TOP,
                bottom: position === PaginationPosition.BOTTOM,
            })}
            aria-label="Pagination"
        >
            <ul className="pagination justify-content-center">
                <li
                    className={classnames('page-item', {
                        disabled: paginated < 2,
                    })}
                >
                    <button
                        className="page-link"
                        onClick={() => {
                            pageChanged(paginated - 1);
                        }}
                        disabled={paginated < 2}
                        aria-label="Previous page"
                    >
                        <span aria-hidden={true}>&lt;</span>
                    </button>
                </li>

                <PaginationPages
                    pages={pages}
                    paginated={paginated}
                    onPageSelect={pageChanged}
                />

                <li
                    className={classnames('page-item', {
                        disabled: paginated === pages,
                    })}
                >
                    <button
                        className="page-link"
                        onClick={() => {
                            pageChanged(paginated + 1);
                        }}
                        disabled={paginated === pages}
                        aria-label="Next page"
                    >
                        <span aria-hidden>&gt;</span>
                    </button>
                </li>
            </ul>
        </nav>
    );
};

export const Pagination = connect(
    (state: IState) => ({
        currentPage: getCurrentPage(state),
        pageCount: getPageCount(state),
    }),
    (dispatch: Dispatch) =>
        bindActionCreators(
            {
                pageChanged,
            },
            dispatch
        )
)(PaginationComponent);
