import React from 'react';
import _remove from 'lodash/remove';

import { AlertStickyTop } from './alert.sticky.top';
import store, { StoreType } from 'store2';
import { Announcement } from '../../domain/model/announcement/announcement';
import { AnnouncementType } from '../../domain/model/announcement/announcement.type';
import { connect } from 'react-redux';
import {
    AnnouncementFetchStatus,
    announcementsFetchStarted,
    getAnnouncementFetchStatus,
    getAnnouncements,
    interceptNetworkErrors,
    setAnnouncements,
} from '../../duck/alert.duck';
import { isAuthenticated } from '../../duck/auth.duck';
import { IState } from '../../store/reducer';
import { bindActionCreators, Dispatch } from 'redux';
import { getLocale } from '../../duck/localization.duck';
import { maybeFetchAnnouncements } from '../../util/announcements';
import { AnnouncementContent } from './announcement.content';

interface AnnouncementsProps {
    announcements: Announcement[];
    announcementsFetchStarted: typeof announcementsFetchStarted;
    announcementFetchStatus: AnnouncementFetchStatus;
    isAuthenticated: boolean;
    interceptNetworkErrors: typeof interceptNetworkErrors;
    locale: string;
    setAnnouncements: typeof setAnnouncements;
}

interface AnnouncementsState {
    visibleAnnouncements: Announcement[];
}

const SEEN_ITEMS_KEY = 'seen';

export class AnnouncementsComponent extends React.Component<AnnouncementsProps> {
    store: StoreType;
    state: AnnouncementsState = {
        visibleAnnouncements: [],
    };

    constructor(props: AnnouncementsProps) {
        super(props);
        this.store = Announcements.getStore();
        if (!this.store.has(SEEN_ITEMS_KEY)) {
            this.store.set(SEEN_ITEMS_KEY, []);
        }

        this.state.visibleAnnouncements = this.filterSeenAnnouncements(
            props.announcements
        );
    }

    async componentDidMount(): Promise<void> {
        await maybeFetchAnnouncements(this.props, this.updateAnnouncements);
    }

    componentDidUpdate(prevProps: AnnouncementsProps): void {
        if (
            this.props.announcements.length !== prevProps.announcements.length
        ) {
            this.updateAnnouncements(this.props.announcements);
        }
    }

    updateAnnouncements = (announcements: Announcement[]): void => {
        this.setState({
            visibleAnnouncements: this.filterSeenAnnouncements(announcements),
        });
    };

    filterSeenAnnouncements(announcements: Announcement[]): Announcement[] {
        const seen: number[] = this.store(SEEN_ITEMS_KEY);
        return announcements.filter((e) => {
            return !seen.some((s) => s === e.id);
        });
    }

    static getStore(): StoreType {
        return store.namespace('elqconf-announcements');
    }

    getType(announcement: Announcement): string {
        switch (announcement.type) {
            case AnnouncementType.URGENT:
                return 'danger';
            case AnnouncementType.WARNING:
                return 'warning';
            case AnnouncementType.DEFAULT:
            default:
                return 'success';
        }
    }

    onClose = (announcement: Announcement): void => {
        this.store(SEEN_ITEMS_KEY, (seenItems: number[]): number[] => {
            seenItems.push(announcement.id);
            return seenItems;
        });

        const visibleAnnouncements = [...this.state.visibleAnnouncements];
        _remove(visibleAnnouncements, (e) => e.id === announcement.id);

        this.setState({
            visibleAnnouncements,
        });
    };

    render(): React.ReactElement[] {
        const { visibleAnnouncements } = this.state;

        return visibleAnnouncements.map((announcement) => (
            <AlertStickyTop
                key={announcement.id}
                type={this.getType(announcement)}
                lifeSeconds={0}
                onClose={() => {
                    this.onClose(announcement);
                }}
            >
                <AnnouncementContent announcement={announcement} />
            </AlertStickyTop>
        ));
    }
}

export const Announcements = connect(
    (state: IState) => ({
        announcements: getAnnouncements(state),
        isAuthenticated: isAuthenticated(state),
        locale: getLocale(state),
        announcementFetchStatus: getAnnouncementFetchStatus(state),
    }),
    (dispatch: Dispatch) =>
        bindActionCreators(
            {
                announcementsFetchStarted,
                interceptNetworkErrors,
                setAnnouncements,
            },
            dispatch
        )
)(AnnouncementsComponent);
