import React from 'react';
import { User } from 'oidc-react';
import { connect } from 'react-redux';
import { IState } from '../../store/reducer';
import { getUser } from '../../duck/auth.duck';
import { isAdmin } from '../../domain/model/user/user.roles';

interface AdminOnlyComponentProps {
    children?: JSX.Element | JSX.Element[];
    user?: User;
}

class AdminOnlyComponent extends React.Component<AdminOnlyComponentProps> {
    render(): JSX.Element {
        const { user, children } = this.props;

        return <>{user && isAdmin(user) && children}</>;
    }
}

export const AdminOnly = connect((state: IState) => ({
    user: getUser(state),
}))(AdminOnlyComponent);
