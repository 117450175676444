import React from 'react';
import ReactTags from 'react-tag-autocomplete';
import { connect } from 'react-redux';
import { IState } from '../../../../store/reducer';
import {
    getCurrentTags,
    getTagSuggestions,
    tagsChanged,
} from '../../../../duck/form.list.duck';
import { bindActionCreators, Dispatch } from 'redux';
import { ITagValue } from '../../../../domain/model/form/tags';
import { translations } from '../../../../translations/translations';
import { getLocale } from '../../../../duck/localization.duck';

const tagClassNames = {
    root: 'react-tags form-control',
    rootFocused: 'is-focused',
    selected: 'react-tags__selected',
    selectedTag: 'react-tags__selected-tag',
    selectedTagName: 'react-tags__selected-tag-name',
    search: 'react-tags__search',
    searchInput: 'react-tags__search-input',
    suggestions: 'react-tags__suggestions',
    suggestionActive: 'is-active',
    suggestionDisabled: 'is-disabled',
};

interface ITagInputConnectProps {
    tagsChanged: typeof tagsChanged;
}

interface ITagInputProps extends ITagInputConnectProps {
    locale: string;
    currentTags: ITagValue[];
    tagSuggestions: ITagValue[];
}

/**
 * @deprecated: Present for API v1 compatibility, will be removed on short notice.
 */
export class TagSearchComponent extends React.Component<ITagInputProps> {
    render(): JSX.Element {
        const { currentTags, tagSuggestions, tagsChanged, locale } = this.props;

        return (
            <ReactTags
                tags={currentTags}
                suggestions={tagSuggestions}
                onDelete={(index) => tagsChanged({ index })}
                onAddition={(tag) => tagsChanged({ tag })}
                classNames={tagClassNames}
                delimiters={[',', 'Enter', 'Tab', ' ']}
                allowNew={false}
                autoresize={false}
                placeholderText={translations.formList.tagSearch[locale]}
            />
        );
    }
}

/**
 * @deprecated: Present for API v1 compatibility, will be removed on short notice.
 */
export const TagSearch = connect(
    (state: IState) => ({
        tagSuggestions: getTagSuggestions(state),
        currentTags: getCurrentTags(state),
        locale: getLocale(state),
    }),
    (dispatch: Dispatch) =>
        bindActionCreators(
            {
                tagsChanged,
            },
            dispatch
        )
)(TagSearchComponent);
