export class NetworkException {
    public readonly message: string;
}

export class UnsupportedApiVersionException extends NetworkException {
    constructor(public readonly message: string) {
        super();
    }
}

export class FetchErrorException extends NetworkException {
    constructor(public readonly error: Error) {
        super();
    }
}

export class HttpException extends NetworkException {
    constructor(
        public readonly message: string,
        public readonly code: number,
        public readonly response: Response
    ) {
        super();
    }

    static createFromResponse(response: Response): HttpException | null {
        if (response.ok) {
            return null;
        }

        switch (response.status) {
            case BadRequestException.CODE:
                return new BadRequestException(response.statusText, response);
            case UnauthorizedException.CODE:
                return new UnauthorizedException(response.statusText, response);
            case ForbiddenException.CODE:
                return new ForbiddenException(response.statusText, response);
            case NotFoundException.CODE:
                return new NotFoundException(response.statusText, response);
            case UnprocessableEntityException.CODE:
                return new UnprocessableEntityException(
                    response.statusText,
                    response
                );
            default:
                return new HttpException(
                    response.statusText,
                    response.status,
                    response
                );
        }
    }
}

export class BadRequestException extends HttpException {
    static CODE = 400;

    constructor(message: string, response: Response) {
        super(message, BadRequestException.CODE, response);
    }
}

export class UnauthorizedException extends HttpException {
    static CODE = 401;

    constructor(message: string, response: Response) {
        super(message, UnauthorizedException.CODE, response);
    }
}

export class ForbiddenException extends HttpException {
    static CODE = 403;

    constructor(message: string, response: Response) {
        super(message, ForbiddenException.CODE, response);
    }
}

export class NotFoundException extends HttpException {
    static CODE = 404;

    constructor(message: string, response: Response) {
        super(message, NotFoundException.CODE, response);
    }
}

export class UnprocessableEntityException extends HttpException {
    static CODE = 422;

    constructor(message: string, response: Response) {
        super(message, UnprocessableEntityException.CODE, response);
    }
}
