import React, { ReactNode } from 'react';
import { Collapse, Card, CardHeader, CardBody } from 'reactstrap/lib';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { IState } from '../../../store/reducer';
import {
    getFormCardCollapsedStatus,
    toggleCollapse,
} from '../../../duck/form.edit.duck';
import { getLocale } from '../../../duck/localization.duck';
import { Help, IHelpSetupProps } from './input/help';

interface IFormCardConnectProps {
    toggleCollapse: typeof toggleCollapse;
    locale: string;
}

interface IFormCardProps extends IFormCardConnectProps {
    title: string;
    name?: string;
    isCollapsed: boolean;
    collapsible?: boolean;
    children?: ReactNode;
    help?: IHelpSetupProps;
}

export class FormCardComponent extends React.Component<IFormCardProps> {
    toggleCollapse = (): void => {
        if (this.props.collapsible && this.props.name) {
            this.props.toggleCollapse(this.props.name);
        }
    };

    render(): JSX.Element {
        const { help, isCollapsed, locale, name } = this.props;

        return (
            <Card className="mb-5">
                <CardHeader tag="h3" onClick={this.toggleCollapse}>
                    {this.props.title}
                    {help && (help.body || help.link) && (
                        <Help
                            name={name}
                            locale={locale}
                            body={help.body}
                            link={help.link}
                        />
                    )}
                </CardHeader>
                {name ? (
                    <Collapse isOpen={!isCollapsed}>
                        <CardBody>{this.props.children}</CardBody>
                    </Collapse>
                ) : (
                    <CardBody>{this.props.children}</CardBody>
                )}
            </Card>
        );
    }
}

export const FormCard = connect(
    (state: IState, ownProps) => ({
        isCollapsed: getFormCardCollapsedStatus(state, ownProps),
        locale: getLocale(state),
    }),
    (dispatch: Dispatch) =>
        bindActionCreators(
            {
                toggleCollapse,
            },
            dispatch
        )
)(FormCardComponent);
