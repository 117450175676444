import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import React from 'react';

interface CloseButtonProps {
    toggleOpen: () => void;
}

export const CloseButton = ({ toggleOpen }: CloseButtonProps): JSX.Element => (
    <Button onClick={toggleOpen} outline color="danger">
        <FontAwesomeIcon icon={faTimes} />
    </Button>
);
