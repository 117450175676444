import React from 'react';
import { IFormListItem } from '../../../domain/model/form/form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faGlobe, faStar } from '@fortawesome/free-solid-svg-icons';
import { LocalizationContext } from '../../localization/localization.provider';

interface RevisionListBadgesProps {
    revision: IFormListItem;
    currentForm: IFormListItem;
}

export const RevisionListBadges = (
    props: RevisionListBadgesProps
): JSX.Element => {
    const { revision, currentForm } = props;
    return (
        <LocalizationContext.Consumer>
            {({ translate }) => (
                <>
                    {revision.isPublishedRevision && (
                        <FontAwesomeIcon
                            icon={faGlobe}
                            title={translate('revisions.badges.published')}
                        />
                    )}
                    {revision.revisionId === currentForm.revisionId && (
                        <FontAwesomeIcon
                            icon={faClock}
                            title={translate('revisions.badges.latest')}
                        />
                    )}
                    {revision.sticky && (
                        <FontAwesomeIcon
                            icon={faStar}
                            title={translate('revisions.badges.sticky')}
                        />
                    )}
                </>
            )}
        </LocalizationContext.Consumer>
    );
};
