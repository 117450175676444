import { UserAwareSdkClient } from './user.aware.sdk.client';
import { IFormListItem } from '../../domain/model/form/form';
import { Routes } from '../routing/route';

class RevisionApi extends UserAwareSdkClient {
    getRevisionsForForm = async (formId: string): Promise<IFormListItem[]> => {
        return this.getJson(Routes.revision.list(formId));
    };

    restore = async (revisionId: string): Promise<IFormListItem[]> => {
        return this.getJson(Routes.revision.restore(revisionId));
    };

    stickify = async (revisionId: string): Promise<IFormListItem[]> => {
        return this.getJson(Routes.revision.stickify(revisionId));
    };

    unstickify = async (revisionId: string): Promise<IFormListItem[]> => {
        return this.getJson(Routes.revision.unstickify(revisionId));
    };
}

export const revisionApi = new RevisionApi();
