import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { User } from 'oidc-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faGlobe } from '@fortawesome/free-solid-svg-icons';

import { formApi } from '../api/sdk/form';
import { FormList } from '../components/form/form.list';
import { IFormListItem } from '../domain/model/form/form';
import { Spinner } from '../components/alert/spinner';
import {
    formListLoaded,
    getCurrentPage,
    getFormList,
    getPageCount,
    getSearchMode,
    getViewMode,
    getVisibleItems,
    LIST_VIEW_MODE,
    pageChanged,
    pageCountChanged,
    SearchMode,
    searchModeChanged,
    tagsChanged,
    tagSelectionChanged,
    viewModeChanged,
} from '../duck/form.list.duck';
import { IState } from '../store/reducer';
import { getLocale } from '../duck/localization.duck';
import { translations } from '../translations/translations';
import {
    alertAdd,
    interceptNetworkErrors,
    AlertType,
} from '../duck/alert.duck';
import { formReset } from '../duck/form.edit.duck';
import { getApiVersion } from '../duck/api.duck';
import { RouteWithLocationProps } from '../lib/types/route-with-location';
import { withPaginationUpdater } from '../components/form/list/with.pagination.updater';
import { Announcements } from '../components/alert/announcements';
import { getUser, isAuthenticated } from '../duck/auth.duck';
import { LocalizationContext } from '../components/localization/localization.provider';
import { ApiVersion } from '../api/sdk/api-version';

export interface IFormListScreenConnectProps {
    alertAdd: typeof alertAdd;
    apiVersion: number;
    currentPage: number;
    formList: IFormListItem[];
    formListLoaded: typeof formListLoaded;
    legacyFormList: IFormListItem[];
    locale: string;
    formReset: typeof formReset;
    interceptNetworkErrors: typeof interceptNetworkErrors;
    pageChanged: typeof pageChanged;
    pageCount: number;
    pageCountChanged: typeof pageCountChanged;
    searchMode: SearchMode;
    searchModeChanged: typeof searchModeChanged;
    tagsChanged: typeof tagSelectionChanged;
    tagSelectionChanged: typeof tagSelectionChanged;
    viewMode: LIST_VIEW_MODE;
    viewModeChanged: typeof viewModeChanged;
    hasLoaded: boolean;
    user: User | null;
    isAuthenticated: boolean;
}

export type IFormListScreenProps = IFormListScreenConnectProps &
    RouteComponentProps &
    RouteWithLocationProps;

interface FormListState {
    loaded: boolean;
}

export class FormListScreenComponent extends React.Component<IFormListScreenProps> {
    static contextType = LocalizationContext;

    state: FormListState = {
        loaded: false,
    };

    componentDidMount(): void {
        const { apiVersion, location }: IFormListScreenProps = this.props;

        if (location.state && location.state.clearFormData) {
            this.props.formReset();
        }

        if (this.props.isAuthenticated && apiVersion > 0) {
            this.maybeUpdatePagination().then();
        }
    }

    async componentDidUpdate(
        prevProps: Readonly<IFormListScreenProps>
    ): Promise<void> {
        await this.maybeUpdatePagination(prevProps);
    }

    async maybeUpdatePagination(
        prevProps?: Readonly<IFormListScreenProps>
    ): Promise<void> {
        const { apiVersion, searchMode } = this.props;

        if (apiVersion > 0 && searchMode === SearchMode.DEFAULT) {
            const { currentPage, isAuthenticated } = this.props;

            const previousPage = prevProps?.currentPage;
            const previousApiVersion = prevProps?.apiVersion;
            const previousSearchMode = prevProps?.searchMode;

            if (
                !prevProps ||
                previousApiVersion !== apiVersion ||
                previousPage !== currentPage ||
                previousSearchMode !== searchMode ||
                (!prevProps?.isAuthenticated && isAuthenticated)
            ) {
                await this.onPageChanged();
            }
        }
    }

    isTagSearch(search = this.props.location.search): boolean {
        return /tags=(\d,?)+/.test(search);
    }

    onDelete = async (formId: string): Promise<void> => {
        const { locale, alertAdd } = this.props;

        if (window.confirm(translations.alert.delete_confirm[locale])) {
            try {
                await formApi.deleteFormById(formId);
                alertAdd({
                    type: AlertType.SUCCESS,
                    message: translations.alert.delete_success[locale],
                });
            } catch (e) {
                console.error(e);
                alertAdd({
                    type: AlertType.DANGER,
                    message:
                        e.message || translations.alert.request_fail[locale],
                });
            }

            await this.onPageChanged();
        }
    };

    onPageChanged = async (): Promise<void> => {
        const { apiVersion, currentPage, locale } = this.props;

        this.setState({ loaded: false });

        try {
            const { formList, pages } = await formApi.getFormList(
                currentPage,
                this.props.apiVersion
            );

            await this.props.formListLoaded({
                forms: formList,
                mapTags: apiVersion < ApiVersion.PAGINATED,
            });
            this.props.pageCountChanged(pages);
        } catch (e) {
            this.props.interceptNetworkErrors({
                exception: e,
                locale,
                message: 'alert.network.form_list_fetch_fail',
            });
        } finally {
            this.setState({ loaded: true });
        }
    };

    render(): JSX.Element {
        const {
            apiVersion,
            locale,
            formList,
            legacyFormList,
            pageCount,
            tagsChanged,
            tagSelectionChanged,
            viewMode,
            viewModeChanged,
            isAuthenticated,
        } = this.props;
        const { loaded } = this.state;
        const { translate } = this.context;

        return (
            <>
                <Announcements />
                {(!isAuthenticated || !loaded) && <Spinner />}
                <FormList
                    apiVersion={apiVersion}
                    locale={locale}
                    getBadges={(form: IFormListItem): null | JSX.Element => {
                        if (form.publishedAt) {
                            return (
                                <>
                                    <FontAwesomeIcon icon={faGlobe} />
                                    {!form.isPublishedRevision && (
                                        <FontAwesomeIcon
                                            icon={faEdit}
                                            title={translate(
                                                'revisions.badges.unsavedChanges'
                                            )}
                                        />
                                    )}
                                </>
                            );
                        }
                    }}
                    items={
                        apiVersion < ApiVersion.PAGINATED
                            ? legacyFormList
                            : formList
                    }
                    getPrimaryAction={() => this.onDelete}
                    onTagClicked={(tag) => {
                        const callback =
                            apiVersion > ApiVersion.BASE
                                ? tagSelectionChanged
                                : tagsChanged;
                        callback({ tag });
                    }}
                    currentViewMode={viewMode}
                    viewModeChanged={viewModeChanged}
                    hasPagination={
                        apiVersion > ApiVersion.BASE && pageCount > 1
                    }
                    refresh={this.onPageChanged}
                />
            </>
        );
    }
}

const FormListScreenConnected = connect(
    (state: IState) => ({
        apiVersion: getApiVersion(state),
        currentPage: getCurrentPage(state),
        formList: getFormList(state),
        legacyFormList: getVisibleItems(state),
        locale: getLocale(state),
        pageCount: getPageCount(state),
        searchMode: getSearchMode(state),
        viewMode: getViewMode(state),
        isAuthenticated: isAuthenticated(state),
        user: getUser(state),
    }),
    (dispatch: Dispatch) =>
        bindActionCreators(
            {
                alertAdd,
                formListLoaded,
                formReset,
                interceptNetworkErrors,
                pageChanged,
                pageCountChanged,
                tagsChanged,
                tagSelectionChanged,
                searchModeChanged,
                viewModeChanged,
            },
            dispatch
        )
)(FormListScreenComponent);

const FormListScreenWithRouter = withRouter(FormListScreenConnected);

export const FormListScreen = withPaginationUpdater(FormListScreenWithRouter);
