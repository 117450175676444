export const formBuilderTranslations = {
    className: {
        de: 'CSS-Klasse',
    },
    unknownInputType: {
        de: 'sonstige',
    },
    hasValue: {
        de: 'Vorgabewert',
    },
    itemType: {
        field: {
            de: 'Feld',
        },
        group: {
            de: 'Gruppe',
        },
        submit: {
            de: 'Submit-Button',
        },
        html: {
            de: 'HTML Block',
        },
    },
    fieldGroup: {
        basic: {
            de: 'Grundeinstellungen',
        },
        styling: {
            de: 'Styling / CSS',
        },
        typeSpecific: {
            de: 'feldtyp-spezifische Einstellungen',
        },
        validation: {
            de: 'Validierung',
        },
        group: {
            de: 'Gruppeneinstellungen',
        },
        submit: {
            de: 'Submit-Konfiguration',
        },
        progressive: {
            de: 'Progressive-Profiling-Optionen',
        },
        formTypeSpecific: {
            de: 'Dynamisches Kontaktformular / Progressive-Profiling-Optionen',
        },
        html: {
            de: 'HTML-Block',
        },
    },
    field: {
        name: {
            de: 'Name',
        },
        inputType: {
            de: 'Eingabefeldtyp',
        },
        placeholder: {
            de: 'Platzhaltertext',
        },
        inputName: {
            de: 'Eingabefeldname (falls abweichend)',
        },
        selectOptions: {
            de: 'Select-Auswahloptionen',
        },
        value: {
            de: 'Default-Wert',
        },
        maxChars: {
            de: 'Zeichenbegrenzung (Zahl)',
        },
        checkedValue: {
            de: '"checked"-Wert (falls abw.)',
        },
        labelParams: {
            de: 'Label-Parameter',
            privacyPolicyLink: {
                de: 'Link zur Datenschutzerklärung',
            },
            privacyPolicyText: {
                de: 'Link-Text Datenschutzerklärung',
            },
            privacyPolicyProvider: {
                de: 'Aussteller der Datenschutzerklärung',
            },
            link: {
                de: 'Link zur Datenschutzerklärung',
            },
            linkText: {
                de: 'Link-Text (Datenschutzerklärung)',
            },
        },
        labelText: {
            de: 'Label',
        },
        labelClassName: {
            de: 'Label-CSS-Klasse',
        },
        inputClassName: {
            de: 'Input-Element CSS-Klasse',
        },
        validators: {
            de: 'Validierung',
        },
        eloquaName: {
            de: 'Eloqua-Name (für WDL)',
        },
        overridable: {
            de: 'Überschreibbares Feld',
        },
        urlParameterName: {
            de: 'URL-Parameter',
        },
        submitText: {
            de: 'Button-Beschriftung',
        },
        submitTextWhenFilled: {
            de: 'Beschriftung bei verstecktem Formular',
        },
        fieldGroup: {
            de: 'Progressive-Profiling-Feldgruppe',
        },
        requestDynamicOptions: {
            de: 'Mapping Dynamisches Request-Feld',
        },
        requestDynamicCustomList: {
            de: 'Request-Feld: Custom-Liste',
        },
        takeValuesFromFields: {
            de: 'takeValuesFromFields Sonderoption',
        },
        validatorOverride: {
            de: 'Vorgabevalidierung ersetzen (statt sie zu ergänzen)',
        },
    },
    validatorType: {
        not_empty: {
            de: 'Pflichtfeld',
        },
        email: {
            de: 'E-Mail-Adresse',
        },
        numeric: {
            de: 'Numerisch',
        },
        alphabetic: {
            de: 'Alphabetisch',
        },
        phone: {
            de: 'Telefonnummer',
        },
        regexp: {
            de: 'Regulärer Ausdruck',
        },
        regexPattern: {
            de: 'Regex-Muster',
        },
        regexFlags: {
            de: 'Regex-Flags',
        },
    },
    assocArrayInput: {
        head: {
            label: {
                de: 'Label / Anzeigetext',
            },
            value: {
                de: 'Wert',
            },
            remove: {
                de: 'Option entfernen',
            },
        },
    },
    indexedArrayInput: {
        head: {
            label: {
                de: 'Gruppenschlüssel',
            },
        },
    },
    validatorInput: {
        head: {
            type: {
                de: 'Validierungstyp',
            },
            message: {
                de: 'Fehlermeldung',
            },
        },
    },
    htmlContent: {
        de: 'HTML-Inhalt (nicht validiert!)',
    },
};
