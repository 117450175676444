import { translations } from '../translations/translations';
import { IInputFieldConfig } from './input.field.config.interface';
import { IndexedArrayInput } from '../components/form/edit/formBuilder/input/indexed.array.input';
import { SwitchInput } from '../components/form/edit/input/switch.input';

export const MiscContactFieldConfig: IInputFieldConfig = [
    {
        name: 'options.dynamic',
        label: translations.formElement.field.misc.options.dynamic,
        readonly: false,
        withCopyButton: false,
        resettable: true,
        help: {
            link: 'http://haufe-eloqua-tests.ghdev.de/docs/v3/de/extras/dynamic-contact-form',
        },
    },
];

export const MiscProgressiveFieldConfig: IInputFieldConfig = [
    {
        name: 'fieldGroups',
        label: translations.formElement.field.misc.fieldGroups,
        readonly: false,
        withCopyButton: false,
        resettable: false,
        component: IndexedArrayInput,
    },
    {
        name: 'options.hideWhenFilled',
        label: translations.formElement.field.misc.options.hideWhenFilled,
        readonly: false,
        withCopyButton: false,
        resettable: true,
        component: SwitchInput,
        help: {
            link: 'http://haufe-eloqua-tests.ghdev.de/docs/v3/de/form-types#progressive-hide-when-filled',
        },
    },
];
