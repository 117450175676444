import React from 'react';
import DatePicker from 'react-datepicker';
import { IInputComponentProps } from './input.component.props.interface';

import 'react-datepicker/dist/react-datepicker.css';

export const DatetimeInput = (props: IInputComponentProps): JSX.Element => {
    const { name, onChange, value } = props;

    function onValueChange(value) {
        onChange({ currentTarget: { value } });
    }

    return (
        <DatePicker
            name={name}
            selected={value}
            onChange={onValueChange}
            showTimeSelect
            clearButtonTitle={'Clear'}
            dateFormat={'dd.MM.yyyy HH:mm'}
            timeFormat={'HH:mm'}
            isClearable
        />
    );
};
