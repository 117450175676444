class Debug {
    debugActive: boolean;

    constructor() {
        let debugActive = false;
        try {
            debugActive = JSON.parse(process.env.REACT_APP_DEBUG_OUTPUT);
        } catch {
            // noop
        }
        this.debugActive = debugActive;
    }

    get log(): (...any) => void {
        if (this.debugActive) {
            return console.log;
        } else {
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            return () => {};
        }
    }

    get warn(): (...any) => void {
        if (this.debugActive) {
            return console.warn;
        } else {
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            return () => {};
        }
    }

    get error(): (...any) => void {
        if (this.debugActive) {
            return console.error;
        } else {
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            return () => {};
        }
    }

    get info(): (...any) => void {
        return this.log;
    }

    get debug(): (...any) => void {
        return this.log;
    }
}

export const debug = new Debug();
