/* eslint-disable @typescript-eslint/no-explicit-any */
import { Announcement } from '../domain/model/announcement/announcement';
import dayjs from 'dayjs';
import { AnnouncementType } from '../domain/model/announcement/announcement.type';
import {
    AlertType,
    AnnouncementFetchStatus,
    interceptNetworkErrors as interceptNetworkErrorsReducer,
    announcementsFetchStarted as announcementsFetchStartedReducer,
    setAnnouncements as setAnnouncementsReducer,
} from '../duck/alert.duck';
import { announcementApi } from '../api/sdk/announcement';

export function isAnnouncementActive(announcement: Announcement): boolean {
    const { dateStart = null, dateEnd = null } = announcement;

    const now = dayjs();

    const endIsInFuture =
        dateEnd === null || dateEnd === '' || dayjs(dateEnd).isAfter(now);
    const startIsInPast =
        dateStart === null ||
        dateStart === '' ||
        dayjs(dateStart).isBefore(now);
    return endIsInFuture && startIsInPast;
}

export function getColorByAnnouncementType(
    announcementType: AnnouncementType
): string {
    switch (announcementType) {
        case AnnouncementType.WARNING:
            return announcementType;
        case AnnouncementType.URGENT:
            return AlertType.DANGER;
        case AnnouncementType.DEFAULT:
            return AlertType.SUCCESS;
    }
}

export async function maybeFetchAnnouncements(
    {
        isAuthenticated,
        announcementFetchStatus,
        announcementsFetchStarted,
        setAnnouncements,
        interceptNetworkErrors,
        locale,
    }: {
        isAuthenticated: boolean;
        announcementFetchStatus: AnnouncementFetchStatus;
        announcementsFetchStarted: typeof announcementsFetchStartedReducer;
        setAnnouncements: typeof setAnnouncementsReducer;
        interceptNetworkErrors: typeof interceptNetworkErrorsReducer;
        locale: string;
    },
    callback?: (announcements: Announcement[]) => any
): Promise<void> {
    if (
        isAuthenticated &&
        announcementFetchStatus === AnnouncementFetchStatus.INIT
    ) {
        announcementsFetchStarted();
        try {
            const announcements =
                await announcementApi.getActiveAnnouncements();
            setAnnouncements(announcements);
            callback && callback(announcements);
        } catch (e) {
            interceptNetworkErrors({
                exception: e,
                locale: locale,
            });
        }
    }
}
