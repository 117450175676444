import React from 'react';
import qs from 'query-string';
import _isEqual from 'lodash/isEqual';
import _uniq from 'lodash/uniq';
import {
    getCurrentPage,
    getCurrentTags,
    getSearchMode,
    pageChanged,
    SearchMode,
    searchModeChanged,
    tagSelectionChanged,
} from '../../../duck/form.list.duck';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { IState } from '../../../store/reducer';
import { getApiVersion } from '../../../duck/api.duck';
import { bindActionCreators, Dispatch } from 'redux';
import { ITagValue } from '../../../domain/model/form/tags';
import { ApiVersion } from '../../../api/sdk/api-version';

interface PaginationUpdaterConnectProps {
    apiVersion: number;
    currentPage: number;
    currentTags: ITagValue[];
    pageChanged: typeof pageChanged;
    searchMode: SearchMode;
    searchModeChanged: typeof searchModeChanged;
    tagSelectionChanged: typeof tagSelectionChanged;
}

type PaginationUpdaterProps = PaginationUpdaterConnectProps &
    RouteComponentProps;

interface CustomQuery {
    page: number;
    tags: ITagValue[];
    searchMode: SearchMode;
}

const customQuery = {
    page: 0,
    tags: [],
    searchMode: SearchMode.DEFAULT,
};

/**
 * Synchronizes pagination / search state with URL query parameters.
 */
export function withPaginationUpdater(
    Component: React.ComponentType
): React.ComponentClass {
    class PaginationUpdaterComponent extends React.Component<PaginationUpdaterProps> {
        componentDidMount(): void {
            const { apiVersion } = this.props;

            if (apiVersion > 1) {
                this.init();
            }
        }

        componentDidUpdate(prevProps: Readonly<PaginationUpdaterProps>): void {
            const {
                apiVersion,
                currentPage,
                currentTags,
                location,
                searchMode,
            } = this.props;

            if (
                apiVersion !== prevProps.apiVersion &&
                prevProps.apiVersion === 0 &&
                apiVersion > ApiVersion.BASE
            ) {
                return this.init();
            }

            if (apiVersion > ApiVersion.BASE) {
                if (location.search !== prevProps.location.search) {
                    return this.updateStateFromLocation();
                }

                const query = this.getParsedQuery();

                if (
                    currentPage !== prevProps.currentPage ||
                    searchMode !== prevProps.searchMode ||
                    this.haveTagsChanged(query.tags, currentTags)
                ) {
                    return this.updateLocationFromState();
                }
            }
        }

        init(): void {
            this.updateStateFromLocation();
        }

        getParsedQuery(): CustomQuery {
            const { location } = this.props;
            const originalQuery = qs.parse(location.search, {
                arrayFormat: 'comma',
                parseNumbers: true,
            });
            const queryObject: CustomQuery = { ...customQuery };

            let tags: number[] = [];

            if (typeof originalQuery.tags === 'number') {
                tags = [originalQuery.tags];
            } else if (originalQuery.tags?.length) {
                tags = [...(originalQuery.tags as number[])];
            }

            if (tags.length) {
                queryObject.tags =
                    tags?.map((i) => ({
                        id: i,
                        name: '',
                    })) || [];
            }

            queryObject.page = (originalQuery.p as number) || 0;

            queryObject.searchMode = tags.length
                ? SearchMode.TAGS
                : SearchMode.DEFAULT;

            return queryObject;
        }

        updateLocationFromState(): void {
            const { currentPage, currentTags, history, location, searchMode } =
                this.props;

            const newQuery = {};

            if (searchMode === SearchMode.TAGS) {
                newQuery['tags'] = _uniq(currentTags.map((t) => t.id));
            }

            if (currentPage > 1) {
                newQuery['p'] = currentPage;
            }

            const queryString =
                '?' +
                qs.stringify(newQuery, {
                    arrayFormat: 'comma',
                });

            if (queryString !== location.search) {
                history.push({
                    ...location,
                    search: queryString,
                });
            }
        }

        updateStateFromLocation(): void {
            const {
                currentPage,
                currentTags,
                pageChanged,
                searchMode,
                searchModeChanged,
                tagSelectionChanged,
            } = this.props;
            const query = this.getParsedQuery();

            if (query.searchMode !== searchMode) {
                searchModeChanged(query.searchMode);
            }

            if (
                query.searchMode === SearchMode.TAGS &&
                this.haveTagsChanged(query.tags, currentTags)
            ) {
                query.tags.forEach((tag) => {
                    tagSelectionChanged({ tag });
                });
            }

            if (query.page !== currentPage && query.page !== 0) {
                pageChanged(query.page);
            }

            if (!query.page && currentPage !== 1) {
                pageChanged(1);
            }
        }

        haveTagsChanged(queryTags: ITagValue[], currentTags: ITagValue[]) {
            const currentTagIds = currentTags.map((tag) => tag.id);
            const queryTagIds = queryTags.map((tag) => tag.id);

            return !_isEqual(queryTagIds, currentTagIds);
        }

        render() {
            return <Component />;
        }
    }

    const PaginationUpdaterBase = connect(
        (state: IState) => ({
            apiVersion: getApiVersion(state),
            currentPage: getCurrentPage(state),
            currentTags: getCurrentTags(state),
            searchMode: getSearchMode(state),
        }),
        (dispatch: Dispatch) =>
            bindActionCreators(
                {
                    pageChanged,
                    searchModeChanged,
                    tagSelectionChanged,
                },
                dispatch
            )
    )(PaginationUpdaterComponent);

    return withRouter(PaginationUpdaterBase);
}
