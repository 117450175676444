import { IHTMLElementAttributes } from './html.attributes';

export enum SchemaItemType {
    INPUT_FIELD = 'input-field',
    GROUP = 'group',
    HTML = 'html',
    SUBMIT = 'submit',
}

export interface ISchemaItem {
    type: SchemaItemType;
    name: string;
    attributes: ISchemaItemAttributes;
    html?: string;
    items?: ISchemaItem[];
    fieldName?: string;
}

export interface ISchemaItemAttributes {
    wrapper: IHTMLElementAttributes;
    input: IHTMLElementAttributes;
    label: IHTMLElementAttributes;
}
