import { LIST_VIEW_MODE } from '../form.list.duck';

const LOCAL_STORAGE_KEY_PREFIX = 'elqconf-';

const LOCAL_STORAGE_KEY_LIST_VIEW_PREF = 'viewmode-userpref';

const lskey = LOCAL_STORAGE_KEY_PREFIX + LOCAL_STORAGE_KEY_LIST_VIEW_PREF;

export const getListViewModePreference = (): LIST_VIEW_MODE => {
    const lsPreference = window.localStorage.getItem(lskey);
    if (lsPreference) {
        switch (lsPreference) {
            case 'table':
                return LIST_VIEW_MODE.TABLE;
            case 'grid':
                return LIST_VIEW_MODE.GRID;
            case 'simple-table':
            default:
                return LIST_VIEW_MODE.SIMPLE_TABLE;
        }
    } else {
        return LIST_VIEW_MODE.SIMPLE_TABLE;
    }
};

export const setListViewModePreference = (preference: LIST_VIEW_MODE): void => {
    window.localStorage.setItem(lskey, preference);
};
