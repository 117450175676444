import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { BrowserRouter } from 'react-router-dom';

import { IState } from './store/reducer';

import * as Routes from './routing/route';
import { Navbar } from './components/navbar/navbar';

import { getAvailableApiVersion } from './api/sdk/api-version';
import { apiVersionChanged, getApiVersion } from './duck/api.duck';

import pkg from '../package.json';
import { AuthProviderScreen } from './screen/auth.provider';
import { MainScreen } from './screen/main';
import {
    isAuthenticationEnabled,
    authSucceeded,
    authDisabled,
} from './duck/auth.duck';
import { LocalizationProvider } from './components/localization/localization.provider';
import dayjs from 'dayjs';
import { DirectoryProvider } from './components/directories/directory.provider';

interface IAppConnectProps {
    apiVersion: number;
    apiVersionChanged: typeof apiVersionChanged;
    authDisabled: typeof authDisabled;
    authSucceeded: typeof authSucceeded;
    requiresAuthentication: boolean;
}

interface IAppState {
    hasBootstrapped: boolean;
}

class App extends React.Component<IAppConnectProps, IAppState> {
    state = {
        hasBootstrapped: false,
    } as IAppState;

    async componentDidMount() {
        const { version: apiVersion, authEnabled } =
            await getAvailableApiVersion();
        this.props.apiVersionChanged(apiVersion);

        if (this.props.requiresAuthentication && !authEnabled) {
            this.props.authDisabled();
        }

        if (!this.props.requiresAuthentication || !authEnabled) {
            this.props.authSucceeded(null);
        }

        await this.bootstrap();
    }

    async bootstrap() {
        this.setState({
            hasBootstrapped: true,
        });
    }

    render() {
        const { hasBootstrapped } = this.state;
        const { apiVersion, requiresAuthentication } = this.props;

        return (
            <LocalizationProvider>
                <DirectoryProvider>
                    <BrowserRouter basename={Routes.APP_BASE_PATH}>
                        <div className="configurator">
                            {hasBootstrapped && requiresAuthentication ? (
                                <AuthProviderScreen>
                                    <Navbar />
                                    <MainScreen
                                        hasBootstrapped={hasBootstrapped}
                                    />
                                </AuthProviderScreen>
                            ) : (
                                <>
                                    <Navbar />
                                    <MainScreen
                                        hasBootstrapped={hasBootstrapped}
                                    />
                                </>
                            )}

                            <footer className="navbar navbar-dark bg-dark">
                                <div className="navbar-text">
                                    2019-{dayjs().year()}:&nbsp;
                                    <a href="https://gebruederheitz.de">
                                        /gebrüderheitz
                                    </a>
                                    &nbsp;for Haufe Group
                                </div>
                                <div className="navbar-text">
                                    v{pkg.version} – API v{apiVersion}
                                </div>
                                <div className="navbar-text">
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://elq.ghdev.de"
                                    >
                                        ghDev Eloqua Portal
                                    </a>
                                </div>
                            </footer>
                        </div>
                    </BrowserRouter>
                </DirectoryProvider>
            </LocalizationProvider>
        );
    }
}

export const AppContainer = connect(
    (state: IState) => ({
        apiVersion: getApiVersion(state),
        requiresAuthentication: isAuthenticationEnabled(state),
    }),
    (dispatch: Dispatch) =>
        bindActionCreators(
            {
                apiVersionChanged,
                authSucceeded,
                authDisabled,
            },
            dispatch
        )
)(App);
