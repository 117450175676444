import React from 'react';
import { Badge, Table } from 'reactstrap';

import { translations } from '../../../translations/translations';
import DateFormatted from '../../date/date.formatted';
import { ListItemButtons, ListItemButtonsProps } from './list.item.buttons';
import { FormTypeBadge } from './list.grid.item';
import { ButtonsVariant, IAppProps } from '../form.list';
import { RubbishListItemButtons } from './list.item.buttons.rubbish';
import { RevisionListItemButtons } from './list.item.buttons.revisions';
import { AlertType } from '../../../duck/alert.duck';

export const ListTableSimple = (props: IAppProps): JSX.Element => {
    const {
        getBadges,
        locale,
        onTagClicked,
        items,
        buttons = ButtonsVariant.DEFAULT,
    } = props;

    let ButtonComponent: (
        props: ListItemButtonsProps & IAppProps
    ) => JSX.Element = null;

    switch (buttons) {
        case ButtonsVariant.REVISION_LIST:
            ButtonComponent = RevisionListItemButtons;
            break;
        case ButtonsVariant.RECYCLE_BIN:
            ButtonComponent = RubbishListItemButtons;
            break;
        case ButtonsVariant.DEFAULT:
        default:
            ButtonComponent = ListItemButtons;
    }

    return (
        <Table className="list-table list-table--simple">
            <thead>
                <tr>
                    <th>
                        <p>{translations.formElement.field.slug[locale]}</p>
                        <p>{translations.formElement.field.name[locale]}</p>
                    </th>
                    <th>
                        <p>{translations.formElement.field.formType[locale]}</p>
                        <p>Live</p>
                    </th>
                    <th>{translations.formElement.field.tags[locale]}</th>
                    <th>{translations.formElement.field.updated[locale]}</th>
                    <th />
                </tr>
            </thead>
            {items.map((item, index) => {
                let bgColor = index % 2 === 0 ? '#f0f0f0' : '#dcdcdc';
                if (item.template) {
                    bgColor = index % 2 === 0 ? '#f0e4e4' : '#dcd3d3';
                }
                return (
                    <tbody
                        key={index}
                        style={{ backgroundColor: bgColor }}
                        className="gh-list--striped"
                    >
                        <tr>
                            <th scope="row">
                                <p>{item.slug || '-'}</p>
                                <p>{item.name}</p>
                            </th>
                            <td>
                                <p>
                                    <FormTypeBadge item={item} />
                                </p>
                                <p className={'text-info'}>{getBadges(item)}</p>
                            </td>
                            <td className="tags">
                                {item.tags &&
                                    !!item.tags.length &&
                                    item.tags.map((tag, i) => (
                                        <button
                                            key={i}
                                            color="info"
                                            onClick={() => {
                                                onTagClicked(tag);
                                            }}
                                            className="react-tags__selected-tag"
                                        >
                                            {tag.name}
                                        </button>
                                    ))}
                            </td>
                            <td className="updates">
                                <p>
                                    <DateFormatted
                                        dateString={item.updatedAt}
                                    />
                                </p>
                                <p>
                                    {item.updatedBy &&
                                        item.updatedBy.displayName}
                                </p>
                            </td>
                            <td className="buttons text-align-center">
                                <ButtonComponent
                                    compact
                                    item={item}
                                    {...props}
                                />
                                {item.template && (
                                    <div className={'w-100 mt-1 text-center'}>
                                        <Badge pill color={AlertType.PRIMARY}>
                                            {
                                                translations.formMeta
                                                    .templateForm[locale]
                                            }
                                        </Badge>
                                    </div>
                                )}
                            </td>
                        </tr>
                    </tbody>
                );
            })}
        </Table>
    );
};
