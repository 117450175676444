import React from 'react';
import { Button, Table } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { translations } from '../../../../../translations/translations';
import { IInputComponentProps } from '../../input/input.component.props.interface';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import { IState } from '../../../../../store/reducer';
import { getLocale } from '../../../../../duck/localization.duck';
import { RemoveButton, InputElement } from './assoc.array.input';

export interface IIndexedArrayInputComponentProps extends IInputComponentProps {
    locale?: string;
}

export class IndexedArrayInputComponent extends React.Component<IIndexedArrayInputComponentProps> {
    onRowAdded = (): void => {
        let newValue;
        const oldValue = this.props.value;
        if (oldValue && oldValue.forEach) {
            newValue = [...this.props.value];
        } else {
            newValue = [];
        }
        newValue.push('');
        this.props.onChange({
            currentTarget: {
                value: newValue,
            },
        });
    };

    onRemoveClicked = (index: number): void => {
        const newFieldValue = [...this.props.value];
        newFieldValue.splice(index, 1);
        this.props.onChange({
            currentTarget: {
                value: newFieldValue,
            },
        });
    };

    onValueChanged = (index: number, newValue: string): void => {
        let newFieldValue;
        const oldValue = this.props.value;
        if (oldValue && oldValue.length && oldValue.forEach) {
            newFieldValue = [...oldValue];
        } else {
            newFieldValue = [];
        }
        newFieldValue[index] = newValue;
        this.props.onChange({
            currentTarget: {
                value: newFieldValue,
            },
        });
    };

    renderRowItem = (value: string, index: number): JSX.Element => {
        const { name: groupName } = this.props;
        return (
            <tr key={index}>
                <td>
                    <InputElement
                        name={`input-${groupName}-${index}`}
                        value={value}
                        onChange={(newValue) =>
                            this.onValueChanged(index, newValue)
                        }
                    />
                </td>
                <td>
                    <RemoveButton onClick={() => this.onRemoveClicked(index)} />
                </td>
            </tr>
        );
    };

    render(): JSX.Element {
        const { locale, value } = this.props;

        const AddButton = (props) => (
            <Button color="success" onClick={this.onRowAdded} {...props}>
                <FontAwesomeIcon icon={faPlus} />
            </Button>
        );
        const rows = value;

        return (
            <div className="w-100">
                <Table bordered>
                    <thead>
                        <tr>
                            <th>
                                {
                                    translations.formBuilder.indexedArrayInput
                                        .head.label[locale]
                                }
                            </th>
                            <th>
                                <AddButton />
                            </th>
                        </tr>
                    </thead>
                    <tbody>{rows && rows.map(this.renderRowItem)}</tbody>
                </Table>
            </div>
        );
    }
}

export const IndexedArrayInputConnected = connect(
    (state: IState) => ({
        locale: getLocale(state),
    }),
    null
)(IndexedArrayInputComponent);

export const IndexedArrayInput = (props: IInputComponentProps): JSX.Element => (
    <IndexedArrayInputConnected {...props} />
);
