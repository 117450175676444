export default {
    fields: {
        htmlFormAttributes: {
            action: {
                de: 'Vorgabe: https://s1516083671.t.eloqua.com/e/f2 – Dieser Wert sollte eigentlich immer gleich bleiben.',
            },
            target: {
                de: '"blank" verwenden, um die Formularübermittlung in einem neuen Tab/Fenster des Users zu öffnen; "parent" bzw. "top" um aus einem iFrame heraus auf der Elternseite zu übermitteln. Mit dem Vorgabewert "self" wird der aktuelle Tab/Frame des Users verwendet.',
            },
        },
        meta: {
            language: {
                de: `Bei der Einstellung "Automatisch" versucht das Skript die am besten geeignete Sprache zu finden: 
(1) Aufgrund des Meta-Tags '<meta data-namespace="eloqua.form" data-lang="{{WERT: de oder en, sonst de}}" />' ·
(2) Aufgrund des "lang"Attributes im HTML-Tag: '<html lang="{{WERT: de oder en; sonst de}}">' ·
(3) auf Basis der Browserkonfiguration des Users.`,
            },
        },
    },
    formBuilder: {
        fields: {
            fieldGroup: {
                de: 'Einer der Werte, der im Feld "Progressive-Feldgruppen" oben unter "Progressive-Profiling-Einstellungen" definiert wurde.',
            },
            requestDynamicCustomList: {
                de: 'Nur, wenn unter "Mapping Dynamisches Request-Feld" der Wert "Custom-Liste" gewählt wurde',
            },
            submitAlternativeText: {
                de: 'Nur wirksam, wenn es sich um den Formulartyp "progressive" handelt und in den Formular-Progressive-Profiling-Einstellungen die Option "hideWhenFilled" aktiviert ist.',
            },
            takeValuesFromFields: {
                de: 'Sonderoption für das Feld "cominform": Komma-separierte List der Felder, deren Werte in das versteckte Feld cominform geschrieben werden sollen (für die Übergabe an SalesForce).',
            },
            overridable: {
                de: 'In einer Template-Konfiguration lässt sich mit diesem Schalter einstellen, ob Konsumenten das Feld überschreiben können oder nicht. In einer regulären Konfiguration hat dieses Feld keinerlei Auswirkungen.',
            },
            urlParameterName: {
                de: 'Name des URL-Parameters, dessen Wert in diesem Feld vorausgefüllt werden soll. Leeres Feld deaktiviert das URL-Parameter-Parsing.',
            },
        },
    },
};
