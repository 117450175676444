import _pick from 'lodash/pick';

import {
    findFieldByInputName,
    IForm,
    IFormControl,
} from '../../domain/model/form/form';
import {
    ISchemaItem,
    SchemaItemType,
} from '../../domain/model/form/form.schema';
import { IInputField } from '../../domain/model/form/input.field';
import { IFormBuilderItem, IFormBuilderItemType } from '../form.builder.duck';

export const findFormBuilderItemType = (
    schemaItem: ISchemaItem
): IFormBuilderItemType | null => {
    switch (schemaItem.type) {
        case SchemaItemType.GROUP:
            return IFormBuilderItemType.GROUP;
        case SchemaItemType.INPUT_FIELD:
            return IFormBuilderItemType.FIELD;
        case SchemaItemType.SUBMIT:
            return IFormBuilderItemType.SUBMIT;
        default:
            return null;
    }
};

export const mapToFormBuilderData = (formModel: IForm): IFormBuilderItem[] => {
    const fields = formModel.fields;
    const schema = formModel.display.schema;
    const controls = formModel.controls;

    let items: IFormBuilderItem[] = [];
    let currentItemId = 0;

    schema.forEach((schemaItem) => {
        const { items: createdItems, lastItemId } =
            parseSchemaItemToFormBuilderItemRecursive(
                schemaItem,
                items,
                fields,
                controls,
                currentItemId,
                0
            );

        items = createdItems;
        currentItemId = lastItemId;
    });

    return items;
};

const parseSchemaItemToFormBuilderItemRecursive = (
    currentSchemaItem: ISchemaItem,
    items: IFormBuilderItem[],
    fields: IInputField[],
    controls: IFormControl[],
    currentItemId: number,
    parentId = 0
): { items: IFormBuilderItem[]; lastItemId: number } => {
    const name = currentSchemaItem.name || '';
    let lastItemId = currentItemId + 1;

    const item: IFormBuilderItem = {
        id: lastItemId,
        parent: parentId,
        text: name,
        droppable: currentSchemaItem.type === SchemaItemType.GROUP,
        data: {
            type: findFormBuilderItemType(currentSchemaItem),
            // locale: 'de',
            props: {
                name: name,
                className: currentSchemaItem.attributes.wrapper.className,
            },
        },
    };

    const itemProps = item.data.props;

    if (currentSchemaItem.type === SchemaItemType.INPUT_FIELD) {
        const field: IInputField = findFieldByInputName(
            fields,
            currentSchemaItem.fieldName
        );
        if (field) {
            const name = field.name || '';
            item.text = name;
            itemProps.name = name;
            itemProps.inputName = field.inputName || '';
            itemProps.inputType = field.type || '';
            itemProps.labelText = field.label || '';
            itemProps.value = field.defaultValue ?? '';
            itemProps.eloquaName = (field.eloqua && field.eloqua.name) || '';
            itemProps.fieldGroup = field.fieldGroup || '';

            itemProps.validators = field.validators || [];
            itemProps.validatorOverride = field.validatorOverride ?? undefined;

            itemProps.placeholder = field.placeholder || '';
            itemProps.selectOptions = field.options || [];
            itemProps.checkedValue = field.checkedValue || '';
            itemProps.maxChars = field.maxCharacters || undefined;
            itemProps.labelParams = field.labelParams || {};

            itemProps.settings = _pick(field.settings, [
                'formnames',
                'formnamelist',
                'takeValuesFromFields',
            ]);

            itemProps.labelClassName =
                currentSchemaItem.attributes.input.className || '';
            itemProps.inputClassName =
                currentSchemaItem.attributes.label.className || '';
            itemProps.overridable = field.overridable;
            itemProps.urlParameterName = field.urlParameterName || '';
        }
    }

    if (currentSchemaItem.type === SchemaItemType.SUBMIT) {
        const submitConfig = controls.find(
            (el) => el.name === currentSchemaItem.name
        );
        const name = (submitConfig && submitConfig.name) || 'Submit';
        itemProps.name = name;
        item.text = name;
        itemProps.text = (submitConfig && submitConfig.text) || '';
        itemProps.textWhenFilled =
            (submitConfig && submitConfig.textWhenFilled) || '';
    }

    if (currentSchemaItem.type === SchemaItemType.HTML) {
        const name = 'HTML Block';
        item.text = name;
        itemProps.name = name;
        itemProps.value = currentSchemaItem.html || '';
        item.data.type = IFormBuilderItemType.HTML;
    }

    item.data.props = itemProps;
    items.push(item);

    if (currentSchemaItem.type === SchemaItemType.GROUP) {
        currentSchemaItem.items.forEach((subSchemaItem) => {
            const { items: createdItems, lastItemId: updatedItemId } =
                parseSchemaItemToFormBuilderItemRecursive(
                    subSchemaItem,
                    items,
                    fields,
                    controls,
                    lastItemId,
                    item.id
                );

            lastItemId = updatedItemId;
            items = createdItems;
        });
    }

    return { items, lastItemId };
};
