import React from 'react';
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Input,
    Label,
    UncontrolledButtonDropdown,
} from 'reactstrap';

import { LIST_VIEW_MODE } from '../../../duck/form.list.duck';
import { translations } from '../../../translations/translations';
import { IAppProps } from '../form.list';

export const ListViewSelector = (props: Partial<IAppProps>): JSX.Element => {
    const { currentViewMode, locale, viewModeChanged } = props;

    return (
        <UncontrolledButtonDropdown>
            <DropdownToggle caret className="gh-bg-medium">
                {translations.formList.views.view[locale]}
            </DropdownToggle>
            <DropdownMenu>
                <DropdownItem
                    active={currentViewMode === LIST_VIEW_MODE.TABLE}
                    onClick={() => {
                        viewModeChanged(LIST_VIEW_MODE.TABLE);
                    }}
                >
                    <Label check>
                        <Input
                            type="radio"
                            name="viewSelect-radio"
                            value="table"
                            checked={currentViewMode === LIST_VIEW_MODE.TABLE}
                            onChange={() => {
                                viewModeChanged(LIST_VIEW_MODE.TABLE);
                            }}
                        />
                        {translations.formList.views.table[locale]}
                    </Label>
                </DropdownItem>
                <DropdownItem
                    active={currentViewMode === LIST_VIEW_MODE.GRID}
                    onClick={() => {
                        viewModeChanged(LIST_VIEW_MODE.GRID);
                    }}
                >
                    <Label check>
                        <Input
                            type="radio"
                            name="viewSelect-radio"
                            value="dynamic"
                            checked={currentViewMode === LIST_VIEW_MODE.GRID}
                            onChange={() => {
                                viewModeChanged(LIST_VIEW_MODE.GRID);
                            }}
                        />
                        {translations.formList.views.grid[locale]}
                    </Label>
                </DropdownItem>
                <DropdownItem
                    active={currentViewMode === LIST_VIEW_MODE.SIMPLE_TABLE}
                    onClick={() => {
                        viewModeChanged(LIST_VIEW_MODE.SIMPLE_TABLE);
                    }}
                >
                    <Label check>
                        <Input
                            type="radio"
                            name="viewSelect-radio"
                            value="dynamic"
                            checked={
                                currentViewMode === LIST_VIEW_MODE.SIMPLE_TABLE
                            }
                            onChange={() => {
                                viewModeChanged(LIST_VIEW_MODE.SIMPLE_TABLE);
                            }}
                        />
                        {translations.formList.views.simple_table[locale]}
                    </Label>
                </DropdownItem>
            </DropdownMenu>
        </UncontrolledButtonDropdown>
    );
};
