import React from 'react';
import { AuthProvider, User, UserManager } from 'oidc-react';
import { debug } from '../util/debug';
import { sdkClientUserProvider } from '../api/sdk/sdk.client.user.provider';
import { authSucceeded, getUser } from '../duck/auth.duck';
import { connect } from 'react-redux';
import { IState } from '../store/reducer';
import { bindActionCreators, Dispatch } from 'redux';
import Oidc from 'oidc-client';
import jwtDecode from 'jwt-decode';
import { alertAdd, AlertType } from '../duck/alert.duck';

Oidc.Log.logger = debug;

interface AuthProviderScreenProps {
    user: User | null;
    authSucceeded: typeof authSucceeded;
    children?: JSX.Element | JSX.Element[];
}

interface AuthProviderState {
    alertAdd: typeof alertAdd;
    userReceived: boolean;
    userManager: UserManager | null;
}

export const AuthProviderContext = React.createContext({
    renewLogin: async () => false,
});

class AuthProviderScreenComponent extends React.Component<AuthProviderScreenProps> {
    state = {
        userReceived: false,
        userManager: null,
    } as AuthProviderState;

    async componentDidMount() {
        const userManager = new UserManager({
            authority: process.env.REACT_APP_AUTH_AUTHORITY,
            client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
            redirect_uri: process.env.REACT_APP_AUTH_REDIRECT_BASE,
            response_type: 'id_token',
            silent_redirect_uri:
                process.env.REACT_APP_AUTH_REDIRECT_BASE +
                '/oidc-silent-renew.html',
        });

        userManager.events.addAccessTokenExpired(this.onTokenExpired);
        userManager.events.addAccessTokenExpiring(this.onTokenExpiring);
        userManager.events.addSilentRenewError(this.onSilentRenewError);
        userManager.events.addUserLoaded(this.onUserLoaded);
        await userManager.clearStaleState();

        try {
            userManager.getUser().then(this.onUserRetrieved);
        } catch (e) {
            console.error('WATCH THIS SPACE');
        }

        this.setState({ userManager });
    }

    isTokenExpired(token: string): boolean {
        try {
            const decoded = jwtDecode(token);
            const expiration = decoded['payload']['exp'];
            const time = new Date(parseInt(expiration, 10));
            return time <= new Date();
        } catch (e) {
            return false;
        }
    }

    onSilentRenewError = () => {
        const { alertAdd } = this.state;
        debug.warn('silent renew error!');
        alertAdd({
            type: AlertType.DANGER,
            message:
                'Your session could not be automatically renewed. Please try to reload the page.',
        });
    };

    onTokenExpiring = async () => {
        debug.log('access token expiring');
        await this.silentRenew();
    };

    onTokenExpired = async () => {
        debug.log('access token expired');
        await this.silentRenew();
    };

    onUserLoaded = (user: User) => {
        debug.log('User loaded', { user, roles: user?.profile?.roles });
    };

    onUserRetrieved = async (user?: User) => {
        debug.log('user retrieved', { roles: user?.profile?.roles, user });
        // clear URL parameters
        window.history.replaceState({}, '', window.location.pathname);

        if (
            !this.state.userReceived ||
            (user !== null && this.props.user !== user)
        ) {
            // set user on the API classes
            sdkClientUserProvider.setUser(user);

            // prevent further (redundant) user retrievals
            this.setState({
                userReceived: true,
            });
            // commit user to state, send auth token in backend requests,
            this.props.authSucceeded(user);
        }

        // If we do not have a valid user (e.g. retrieved from storage),
        // attempt to renew the login.
        if (!user || !user.id_token || this.isTokenExpired(user.id_token)) {
            debug.log('Invalid or expired user, attempting silent renewal');
            const success = await this.silentRenew();

            if (!success) {
                debug.log('silent renewal failed, logging in fresh');
                await this.state.userManager.signinRedirect();
            }
        }
    };

    silentRenew = async (): Promise<boolean> => {
        debug.log('Starting silent auth renewal...');
        const { userManager } = this.state;

        await userManager.clearStaleState();
        try {
            await userManager.removeUser();
            const user = await userManager.signinSilent();

            debug.log('user from silent signin', { user });
            sdkClientUserProvider.setUser(user);
            this.props.authSucceeded(user);
            debug.log('Authentication renewed.');
            return true;
        } catch (e) {
            debug.error('failed to renew login');
            this.props.authSucceeded(null);
            return false;
        }
    };

    render() {
        return (
            <AuthProvider
                authority={process.env.REACT_APP_AUTH_AUTHORITY}
                clientId={process.env.REACT_APP_AUTH_CLIENT_ID}
                redirectUri={process.env.REACT_APP_AUTH_REDIRECT_BASE}
                scope={process.env.REACT_APP_AUTH_SCOPES}
                onSignIn={this.onUserRetrieved}
                userManager={this.state.userManager}
                automaticSilentRenew={true}
                silentRedirectUri={
                    process.env.REACT_APP_AUTH_REDIRECT_BASE +
                    '/oidc-silent-renew.html'
                }
            >
                <AuthProviderContext.Provider
                    value={{ renewLogin: this.silentRenew }}
                >
                    {this.props.children}
                </AuthProviderContext.Provider>
            </AuthProvider>
        );
    }
}

export const AuthProviderScreen = connect(
    (state: IState) => ({
        user: getUser(state),
    }),
    (dispatch: Dispatch) =>
        bindActionCreators(
            {
                authSucceeded,
                alertAdd,
            },
            dispatch
        )
)(AuthProviderScreenComponent);
