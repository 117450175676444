import React, { RefObject } from 'react';
import { Col, Form, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';

import { FormType, IForm } from '../../domain/model/form/form';
import { translations } from '../../translations/translations';
import { FormCard } from './edit/card';
import { Sidebar } from './edit/sidebar';
import { SpinnerModal } from './edit/spinner.modal';
import { InputField } from './edit/input/input.field';
import { MetaFieldConfig } from '../../configuration/meta.fields';
import { IInputFieldPropConfig } from '../../configuration/input.field.config.interface';
import { EloquaFieldConfig } from '../../configuration/eloqua.fields';
import { HtmlFormAttributeFieldConfig } from '../../configuration/html.form.attribute.fields';
import {
    FormBuilderScreen,
    FormBuilderCustomScreenComponent,
    MappedFormPartialAndSchema,
} from '../../screen/form.builder.screen';
import { DeliveryHint } from './edit/delivery.hint';
import {
    MiscContactFieldConfig,
    MiscProgressiveFieldConfig,
} from '../../configuration/misc.fields';
import { ISchemaItem } from '../../domain/model/form/form.schema';
import { FormMeta } from './edit/form.meta';
import { RevisionEditScreen } from '../../screen/revision.edit.screen';
import { CloseButton } from '../close.button';
import { FormPreview } from './edit/form.preview';
import { User } from 'oidc-react';
import { isAdmin } from '../../domain/model/user/user.roles';
import { FormOverrideScreen } from '../../screen/form.override.screen';
import { DeliveryStats } from './edit/delivery.stats';
import { HoneypotFieldNameExtractor } from './edit/honeypot.field.name.extractor';
import { ApiVersion } from '../../api/sdk/api-version';

interface IFormEditProps {
    apiVersion: number;
    canManageTemplates: boolean;
    canPublish: boolean;
    canSave: boolean;
    formData: IForm;
    hasPreview: boolean;
    hasRevisions: boolean;
    isTemplate: boolean;
    locale: string;
    ready: boolean;
    onSubmit: (fieldData: {
        form: Partial<IForm>;
        schema: ISchemaItem[];
    }) => void;
    onPublish: (fieldData: {
        form: Partial<IForm>;
        schema: ISchemaItem[];
    }) => void;
    onToggleTemplate: () => void;
    onWantsRevisions: () => void;
    onCancel();
    user: User;
}

interface FormEditState {
    previewVisible: boolean;
    usePublicRevision: boolean;
    generatedHoneypotFieldName: string;
    honeypotContent: string;
}

const generateInputFields = (
    fieldProps: IInputFieldPropConfig,
    locale: string,
    isTemplate: boolean,
    user: User,
    apiVersion: number
) => {
    const readonly = isTemplate ? !isAdmin(user) : fieldProps.readonly;
    if (!fieldProps.fromApiVersion || fieldProps.fromApiVersion <= apiVersion) {
        return (
            <InputField
                key={fieldProps.name}
                locale={locale}
                {...fieldProps}
                readonly={readonly}
            />
        );
    } else {
        return '';
    }
};

export class FormEdit extends React.Component<IFormEditProps> {
    formBuilderComponent = React.createRef<FormBuilderCustomScreenComponent>();
    iframeContainer: RefObject<HTMLDivElement> = React.createRef();

    state: FormEditState = {
        previewVisible: false,
        usePublicRevision: false,
        generatedHoneypotFieldName: '',
        honeypotContent: '',
    };

    getDisplayAndFieldsData = (): MappedFormPartialAndSchema => {
        return this.formBuilderComponent.current.provideData();
    };

    getOnBeforeSubmit(publish = false) {
        return (): void => {
            const { onPublish, onSubmit } = this.props;
            const currentFieldsData = this.getDisplayAndFieldsData();
            if (publish) {
                onPublish(currentFieldsData);
            } else {
                onSubmit(currentFieldsData);
            }
        };
    }

    generatePreview = (usePublicRevision = false): void => {
        this.setState({
            previewVisible: true,
            usePublicRevision,
        });
    };

    render(): JSX.Element {
        const {
            apiVersion,
            canManageTemplates,
            canPublish,
            canSave,
            formData,
            hasPreview,
            hasRevisions,
            isTemplate,
            locale,
            onCancel,
            onToggleTemplate,
            onWantsRevisions,
            ready,
            user,
        } = this.props;

        const { previewVisible, usePublicRevision } = this.state;
        const showHoneypotHelper =
            apiVersion >= ApiVersion.SPAM_PROTECT &&
            formData.spamProtection?.spamProtectionEnabled;

        return (
            <Form>
                {ready && (
                    <Row>
                        <Col xs="9">
                            <FormCard
                                title={
                                    translations.formElement.section.meta[
                                        locale
                                    ]
                                }
                            >
                                <FormMeta
                                    formData={formData}
                                    canPublish={canPublish}
                                />
                                {MetaFieldConfig.map((props) =>
                                    generateInputFields(
                                        props,
                                        locale,
                                        formData.template,
                                        user,
                                        apiVersion
                                    )
                                )}
                            </FormCard>

                            {showHoneypotHelper && (
                                <HoneypotFieldNameExtractor locale={locale} />
                            )}

                            {(formData.type === FormType.CONTACT ||
                                formData.type === FormType.PROGRESSIVE) && (
                                <FormCard
                                    title={
                                        translations.formElement.section[
                                            formData.type
                                        ].misc[locale]
                                    }
                                    collapsible
                                    name="misc"
                                >
                                    {(formData.type === FormType.CONTACT &&
                                        MiscContactFieldConfig.map((props) =>
                                            generateInputFields(
                                                props,
                                                locale,
                                                formData.template,
                                                user,
                                                apiVersion
                                            )
                                        )) ||
                                        (formData.type ===
                                            FormType.PROGRESSIVE &&
                                            MiscProgressiveFieldConfig.map(
                                                (props) =>
                                                    generateInputFields(
                                                        props,
                                                        locale,
                                                        formData.template,
                                                        user,
                                                        apiVersion
                                                    )
                                            ))}
                                </FormCard>
                            )}

                            <FormCard
                                title={
                                    translations.formElement.section.fields[
                                        locale
                                    ]
                                }
                                name="formBuilder"
                                help={{
                                    body: {
                                        de: 'Bitte nicht vergessen, das Formular nach dem Bearbeiten der Felder zu speichern!',
                                    },
                                }}
                            >
                                <FormBuilderScreen
                                    ref={this.formBuilderComponent}
                                    formData={formData}
                                />
                            </FormCard>

                            <FormCard
                                title={
                                    translations.formElement.section
                                        .formHtmlAttributes[locale]
                                }
                                collapsible
                                name="formAttributes"
                            >
                                {HtmlFormAttributeFieldConfig.map((props) =>
                                    generateInputFields(
                                        props,
                                        locale,
                                        formData.template,
                                        user,
                                        apiVersion
                                    )
                                )}
                            </FormCard>

                            <FormCard
                                title={
                                    translations.formElement.section.eloqua[
                                        locale
                                    ]
                                }
                                collapsible
                                name="eloquaConf"
                            >
                                {EloquaFieldConfig.map((props) =>
                                    generateInputFields(
                                        props,
                                        locale,
                                        formData.template,
                                        user,
                                        apiVersion
                                    )
                                )}
                            </FormCard>

                            {formData.template ? (
                                <FormCard
                                    title={
                                        translations.formElement.section
                                            .overrideEditor[locale]
                                    }
                                >
                                    <FormOverrideScreen formData={formData} />
                                </FormCard>
                            ) : (
                                <FormCard
                                    title={
                                        translations.formElement.section
                                            .delivery[locale]
                                    }
                                >
                                    <DeliveryHint
                                        formId={formData.id}
                                        formSlug={formData.slug}
                                        formType={formData.type}
                                        locale={locale}
                                    />
                                </FormCard>
                            )}

                            <Modal
                                isOpen={previewVisible}
                                size={'xl'}
                                className="text-center modal-dialog-centered"
                            >
                                <ModalHeader
                                    className="d-flex justify-content-between"
                                    close={
                                        <CloseButton
                                            toggleOpen={() =>
                                                this.setState({
                                                    previewVisible: false,
                                                })
                                            }
                                        />
                                    }
                                >
                                    {usePublicRevision
                                        ? translations.formElement.preview
                                              .titlePublic[locale]
                                        : translations.formElement.preview
                                              .title[locale]}
                                    {': '}
                                    <span className={'text-muted'}>
                                        {formData.name}
                                    </span>
                                </ModalHeader>
                                <ModalBody>
                                    <FormPreview
                                        form={formData}
                                        publicRevision={usePublicRevision}
                                    />
                                </ModalBody>
                            </Modal>
                        </Col>
                        <Col xs="3" className="text-center position-relative">
                            <Sidebar
                                canManageTemplates={canManageTemplates}
                                canPublish={canPublish}
                                canSave={canSave}
                                hasPreview={hasPreview}
                                hasRevisions={hasRevisions}
                                isTemplate={isTemplate}
                                onCancel={onCancel}
                                onPublish={this.getOnBeforeSubmit(true)}
                                onSubmit={this.getOnBeforeSubmit()}
                                onToggleTemplate={onToggleTemplate}
                                onWantsPreview={this.generatePreview}
                                onWantsRevisions={onWantsRevisions}
                                currentSlug={formData.slug}
                            >
                                {formData.deliveryStats && (
                                    <DeliveryStats
                                        deliveries={formData.deliveryStats}
                                    />
                                )}
                            </Sidebar>
                        </Col>
                    </Row>
                )}
                <SpinnerModal active={!ready} />
                <RevisionEditScreen />
            </Form>
        );
    }
}
