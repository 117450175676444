import { translations } from '../translations/translations';
import { IInputFieldConfig } from './input.field.config.interface';
import { SelectInput } from '../components/form/edit/input/select.input';

export const HtmlFormAttributeFieldConfig: IInputFieldConfig = [
    {
        name: 'display.attributes.action',
        label: translations.formElement.field.htmlFormAttributes.action,
        readonly: true,
        withCopyButton: false,
        resettable: false,
        help: {
            body: translations.help.fields.htmlFormAttributes.action,
        },
    },
    {
        name: 'display.attributes.method',
        label: translations.formElement.field.htmlFormAttributes.method,
        readonly: false,
        withCopyButton: false,
        resettable: false,
        component: SelectInput,
        selectOptions: [
            { label: 'default', value: '' },
            { label: 'POST (default)', value: 'POST' },
            { label: 'GET', value: 'GET' },
        ],
    },
    {
        name: 'display.attributes.target',
        label: translations.formElement.field.htmlFormAttributes.target,
        readonly: false,
        withCopyButton: false,
        resettable: false,
        component: SelectInput,
        selectOptions: [
            { label: 'default', value: '' },
            { label: 'self (default)', value: 'self' },
            { label: 'blank', value: 'blank' },
            { label: 'parent', value: 'parent' },
            { label: 'top', value: 'top' },
        ],
        help: {
            body: translations.help.fields.htmlFormAttributes.target,
            link: 'https://developer.mozilla.org/en-US/docs/Web/HTML/Element/form#attr-target',
        },
    },
    {
        name: 'display.attributes.enctype',
        label: translations.formElement.field.htmlFormAttributes.enctype,
        readonly: false,
        withCopyButton: false,
        resettable: false,
    },
    {
        name: 'display.attributes.id',
        label: translations.formElement.field.htmlFormAttributes.id,
        readonly: false,
        withCopyButton: false,
        resettable: false,
    },
    {
        name: 'display.attributes.className',
        label: translations.formElement.field.htmlFormAttributes.className,
        readonly: false,
        withCopyButton: false,
        resettable: false,
    },
];
