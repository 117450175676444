import { translations } from '../translations/translations';
import { IInputFieldConfig } from './input.field.config.interface';
import { FormExtension, FormType } from '../domain/model/form/form';
import { SelectInput } from '../components/form/edit/input/select.input';
import { TagInput } from '../components/form/edit/input/tag.input';
import { CheckboxGroupInput } from '../components/form/edit/input/checkbox.group.input';
import { TextAreaInput } from '../components/form/edit/input/textarea.input';
import { SwitchInput } from '../components/form/edit/input/switch.input';
import { ApiVersion } from '../api/sdk/api-version';

export const MetaFieldConfig: IInputFieldConfig = [
    // {
    //     name: 'id',
    //     label: translations.formElement.field.id,
    //     readonly: true,
    //     withCopyButton: true,
    //     resettable: false,
    // },
    {
        name: 'name',
        label: translations.formElement.field.title,
        readonly: false,
        withCopyButton: false,
        resettable: true,
    },
    {
        name: 'slug',
        label: translations.formElement.field.slug,
        readonly: false,
        withCopyButton: true,
        resettable: true,
    },
    {
        name: 'website.name',
        label: translations.formElement.field.website.name,
        readonly: false,
        withCopyButton: false,
        resettable: false,
        component: TextAreaInput,
    },
    {
        name: 'website.url',
        label: translations.formElement.field.website.url,
        readonly: false,
        withCopyButton: false,
        resettable: false,
    },
    {
        name: 'type',
        label: translations.formElement.field.formType,
        readonly: false,
        withCopyButton: false,
        resettable: true,
        component: SelectInput,
        selectOptions: [
            { label: FormType.BASIC, value: FormType.BASIC },
            { label: FormType.CONTACT, value: FormType.CONTACT },
            { label: FormType.PROGRESSIVE, value: FormType.PROGRESSIVE },
        ],
    },
    {
        name: 'language',
        label: translations.formElement.field.language,
        readonly: false,
        withCopyButton: false,
        resettable: false,
        component: SelectInput,
        selectOptions: [
            { label: '--Automatisch--', value: '' },
            { label: 'Deutsch', value: 'de' },
            { label: 'Englisch', value: 'en' },
        ],
        help: {
            body: translations.help.fields.meta.language,
        },
    },
    {
        name: 'extensions',
        label: translations.formElement.field.extensions.group,
        readonly: false,
        withCopyButton: false,
        resettable: false,
        component: CheckboxGroupInput,
        selectOptions: [
            {
                label: translations.formElement.field.extensions.geosuggest,
                value: FormExtension.GOOGLE_GEO_SUGGEST,
            },
        ],
    },
    {
        name: 'styleSheetUrl',
        label: translations.formElement.field.styleSheetUrl,
        readonly: false,
        withCopyButton: false,
        resettable: false,
        help: {
            link: 'http://haufe-eloqua-tests.ghdev.de/themes/',
        },
    },
    {
        name: 'tags',
        label: translations.formElement.field.tags,
        readonly: false,
        withCopyButton: false,
        resettable: false,
        component: TagInput,
    },
    {
        name: 'skipWdl',
        label: translations.formElement.field.skipWdl,
        readonly: false,
        withCopyButton: false,
        resettable: false,
        component: SwitchInput,
    },
    {
        name: 'spamProtection.spamProtectionEnabled',
        label: translations.formElement.field.spamProtection.active,
        readonly: false,
        withCopyButton: false,
        resettable: false,
        component: SwitchInput,
        help: {
            link: translations.formElement.field.spamProtection.activeHelpLink
                .de,
        },
        fromApiVersion: ApiVersion.SPAM_PROTECT,
    },
    {
        name: 'spamProtection.honeypotFieldName',
        label: translations.formElement.field.spamProtection.honeypotFieldName,
        readonly: false,
        withCopyButton: false,
        resettable: false,
        help: {
            link: translations.formElement.field.spamProtection.honeypotHelpLink
                .de,
        },
        fromApiVersion: ApiVersion.SPAM_PROTECT,
    },
];
