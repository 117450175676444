import { FormType } from '../domain/model/form/form';
import helpTranslations from './_help';
import { networkAlerts } from './_network';
import { authTranslations } from './_auth';
import { announcementTranslations } from './_announcements';
import { formBuilderTranslations } from './_form.builder';
import { revisionTranslations } from './_revisions';
import { directoryTranslations } from './_directories';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
type Translation = Record<string, Translation | string>;

export const translations: Translation = {
    formList: {
        tagSearch: {
            de: 'Tags suchen...',
        },
        views: {
            view: {
                de: 'Ansicht',
            },
            table: {
                de: 'Tabellenansicht',
            },
            grid: {
                de: 'Kachelansicht',
            },
            simple_table: {
                de: 'Einfache Tabelle',
            },
        },
    },
    formMeta: {
        created: {
            de: 'Erstellt',
        },
        edited: {
            de: 'Bearbeitet',
        },
        published: {
            de: 'Veröffentlicht',
        },
        by: {
            de: 'von',
        },
        never: {
            de: 'nie',
        },
        unknown: {
            de: 'unbekannt',
        },
        templateForm: {
            de: 'Template-Formular',
        },
    },
    formAction: {
        create: {
            de: 'Neues Formular anlegen',
        },
        submit: {
            de: 'Speichern',
        },
        publish: {
            de: 'Veröffentlichen',
        },
        cancel: {
            de: 'Abbrechen',
        },
        reset: {
            de: 'Zurücksetzen',
        },
        copyToClipboard: {
            de: 'In die Zwischenablage kopieren',
        },
        duplicate: {
            de: 'Duplizieren',
        },
        edit: {
            de: 'Bearbeiten',
        },
        delete: {
            de: 'Löschen',
        },
        move: {
            button: {
                de: 'Verschieben',
            },
            header: {
                de: 'Formularkonfiguration verschieben...',
            },
        },
        save: {
            de: 'Speichern',
        },
        back: {
            de: 'Zurück',
        },
        addField: {
            de: 'Feld hinzufügen',
        },
        preview: {
            de: 'Vorschau',
        },
        previewPublic: {
            de: 'Vorschau (Live)',
        },
        help: {
            de: 'Hilfe',
        },
        details: {
            de: 'Weitere Informationen',
        },
        toggleTemplate: {
            on: {
                de: 'In Template umwandeln',
            },
            off: {
                de: 'In reguläre Konfiguration umwandeln',
            },
        },
    },
    app: {
        title: {
            de: 'ElqConf (beta)',
        },
    },
    formElement: {
        section: {
            meta: {
                de: 'Allgemeine Formularkonfiguration',
            },
            fields: {
                de: 'Formularfelder',
            },
            eloqua: {
                de: 'Eloqua-Konfiguration',
            },
            formHtmlAttributes: {
                de: 'Attribute des HTML-Formularelementes',
            },
            delivery: {
                de: 'Code-Snippets zur Einbindung',
            },
            overrideEditor: {
                de: 'Template-Bearbeitungshilfe: Überschreibungs-Editor',
            },
            [FormType.PROGRESSIVE]: {
                misc: {
                    de: 'Progressive-Profiling-Einstellungen',
                },
            },
            [FormType.CONTACT]: {
                misc: {
                    de: 'Sonstige Konfiguration',
                },
            },
            honeypotHelper: {
                de: 'Spamschutz: Honeypot-Helfer',
            },
        },
        field: {
            id: {
                de: 'Formular-ID',
            },
            name: {
                de: 'Name',
            },
            title: {
                de: 'Titel',
            },
            slug: {
                de: 'Slug / FormularID',
            },
            website: {
                name: {
                    de: 'Beschreibung',
                },
                url: {
                    de: 'Website (URL)',
                },
            },
            styleSheetUrl: {
                de: 'CSS Stylesheet URL',
            },
            updated: {
                de: 'Zuletzt geändert',
            },
            formType: {
                de: 'Formulartyp',
            },
            language: {
                de: 'Sprache',
            },
            extensions: {
                group: {
                    de: 'Add-Ons',
                },
                geosuggest: {
                    de: 'Google Places Geo-Suggest verwenden',
                },
            },
            tags: {
                de: 'Tags',
            },
            skipWdl: {
                de: 'Web Data Lookup überspringen',
            },
            spamProtection: {
                active: {
                    de: 'Spamschutz aktiviert',
                },
                activeHelpLink: {
                    de: 'https://eloqua-docs.ghdev.de/de/allgemeines/spam-protection',
                },
                honeypotFieldName: {
                    de: 'Spamschutz: Honeypot-Feldname',
                },
                honeypotHelpLink: {
                    de: 'https://eloqua-docs.ghdev.de/de/allgemeines/spam-protection#honeypot',
                },
                helper: {
                    helpTitle: {
                        de: 'Spamschutz: Honeypot',
                    },
                    helpLink: {
                        de: 'https://eloqua-docs.ghdev.de/de/allgemeines/spam-protection#honeypot',
                    },
                    placeholder: {
                        de: 'Hier den Eloqua-Formular-HTML-Code einfügen...',
                    },
                    result: {
                        de: 'Ergebnis',
                    },
                },
            },
            eloqua: {
                siteId: {
                    de: 'Eloqua Site-ID',
                },
                visitorKey: {
                    de: 'Web Data Lookup Key "Visitor"',
                },
                contactKey: {
                    de: 'Web Data Lookup Key "Contact"',
                },
                formDataKey: {
                    de: 'Web Data Lookup Key "FormData"',
                },
                extraDataKey: {
                    de: 'Web Data Lookup Key "ExtraData"',
                },
            },
            htmlFormAttributes: {
                action: {
                    de: 'action',
                },
                method: {
                    de: 'method',
                },
                target: {
                    de: 'target',
                },
                enctype: {
                    de: 'enctype',
                },
                id: {
                    de: 'id',
                },
                className: {
                    de: 'class',
                },
            },
            misc: {
                fieldGroups: {
                    de: 'Progressive-Feldgruppen',
                },
                options: {
                    group: {
                        de: 'Formularoptionen',
                    },
                    dynamic: {
                        de: 'Dynamisches Kontaktformular',
                    },
                    hideWhenFilled: {
                        de: 'Verstecken, wenn vollständig',
                    },
                },
            },
        },
        delivery: {
            container: {
                de: 'Formularcontainer',
            },
            script: {
                de: 'Skriptdatei',
            },
        },
        preview: {
            title: {
                de: 'Formular-Vorschau',
            },
            titlePublic: {
                de: 'Formular-Vorschau (Live-Revision)',
            },
        },
    },
    formBuilder: formBuilderTranslations,
    alert: {
        save_success: {
            de: 'Erfolgreich gespeichert.',
        },
        delete_success: {
            de: 'Erfolgreich gelöscht.',
        },
        delete_confirm: {
            de: 'Sind Sie sicher, dass Sie diese Formularkonfiguration löschen wollen?',
        },
        delete_field_confirm: {
            de: 'Sind Sie sicher, dass Sie dieses Feld löschen wollen?',
        },
        cancel_confirm: {
            de: 'Sie haben ungespeicherte Änderungen. Sind Sie sicher, dass Sie abbrechen wollen?',
        },
        formListGetOnFormEditScreenFailed: {
            de: 'Fehler beim Abrufen der Formularliste. Tags und Slug-Validierung funktionieren möglicherweise nicht wie erwartet.',
        },
        slug_validation_failed: {
            de: 'Dieser Slug (Konfigurator - formID) ist bereits vergeben. Bitte wählen Sie einen anderen.',
        },
        network: networkAlerts,
    },
    deliveries: {
        title: {
            de: 'Abfragen',
        },
        current: {
            de: 'Aktueller Monat',
        },
    },
    recycleBin: {
        header: {
            de: 'Papierkorb',
        },
        restore: {
            button: {
                de: 'Formular wiederherstellen',
            },
            success: {
                de: 'Erfolgreich wiederhergestellt',
            },
        },
        purge: {
            button: {
                de: 'Formular endgültig löschen',
            },
            success: {
                de: 'Formular erfolgreich gelöscht.',
            },
        },
        error: {
            de: 'Ein Fehler ist aufgetreten',
        },
    },
    revisions: revisionTranslations,
    help: helpTranslations,
    auth: authTranslations,
    announcements: announcementTranslations,
    directory: directoryTranslations,
};
