import * as React from 'react';
import { ComponentClass, FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { bindActionCreators, Dispatch } from 'redux';
import _merge from 'lodash/merge';

import { formApi } from '../api/sdk/form';
import { FormEdit } from '../components/form/form.edit';
import { IForm } from '../domain/model/form/form';
import { IState } from '../store/reducer';
import { getLocale } from '../duck/localization.duck';
import {
    FORM_EDIT_STATUS,
    formDataReceived,
    formDuplicateIDReset,
    formEdited,
    formFieldsDefinitionChanged,
    formReset,
    getCurrentFormData,
    getEditStatus,
    hasLoaded,
    isRevisionModalOpen,
    loadAnimationToggle,
    toggleRevisionModal,
    updateEditStatus,
} from '../duck/form.edit.duck';
import {
    alertAdd,
    AlertType,
    interceptNetworkErrors,
} from '../duck/alert.duck';
import {
    formListLoaded,
    getSlugs,
    hasListLoaded,
    SlugList,
} from '../duck/form.list.duck';
import { FormRouteParams } from '../routing/route';
import { ISchemaItem } from '../domain/model/form/form.schema';
import { getApiVersion } from '../duck/api.duck';
import { LocalizationContext } from '../components/localization/localization.provider';
import { ApiVersion } from '../api/sdk/api-version';
import { getUser } from '../duck/auth.duck';
import { User } from 'oidc-react';
import { isAdmin } from '../domain/model/user/user.roles';

export interface IFormManagementScreenDispatchProps {
    alertAdd: typeof alertAdd;
    formDataReceived: typeof formDataReceived;
    formDuplicateIDReset?: typeof formDuplicateIDReset;
    formEdited: typeof formEdited;
    formFieldsDefinitionChanged: typeof formFieldsDefinitionChanged;
    formListLoaded: typeof formListLoaded;
    formReset: typeof formReset;
    interceptNetworkErrors: typeof interceptNetworkErrors;
    loadAnimationToggle: typeof loadAnimationToggle;
    revisionModalOpen: boolean;
    toggleRevisionModal: typeof toggleRevisionModal;
    updateEditStatus: typeof updateEditStatus;
}

export interface IFormManagementScreenConnectProps {
    apiVersion: number;
    editStatus: number;
    formData: IForm;
    hasLoaded: boolean;
    locale: string;
    hasListLoaded: boolean;
    slugs: SlugList;
    user: User;
    location?: {
        state?: {
            dir?: number;
        };
    };
}

type FormManagementScreenProps = IFormManagementScreenConnectProps &
    IFormManagementScreenDispatchProps &
    RouteComponentProps<FormRouteParams>;

export abstract class FormManagementScreen extends React.Component<FormManagementScreenProps> {
    static contextType = LocalizationContext;

    async componentDidMount(): Promise<void> {
        const formId = this.props.match.params.id;
        await this.getFormData(formId);
    }

    abstract canPublish(): boolean;

    canSave(): boolean {
        return true;
    }

    async getFormData(formId: string): Promise<void> {
        const {
            formDataReceived,
            hasListLoaded,
            loadAnimationToggle,
            interceptNetworkErrors,
        } = this.props;
        try {
            const formData = await formApi.getFormById(formId);
            await formDataReceived(formData);

            if (!hasListLoaded) {
                /* Load the form list on direct entry to enable slug validation and tag autocomplete */
                await this.loadFormList();
            }
        } catch (exception) {
            interceptNetworkErrors({
                exception,
                locale: this.props.locale,
            });
            loadAnimationToggle(false);
        }
    }

    hasPreview(): boolean {
        return false;
    }

    abstract hasRevisions(): boolean;

    async loadFormList(): Promise<void> {
        const { apiVersion, formListLoaded, interceptNetworkErrors, locale } =
            this.props;

        try {
            const { formList } = await formApi.getFormList(1, apiVersion);
            await formListLoaded({
                forms: formList,
                mapTags: apiVersion < ApiVersion.PAGINATED,
            });
        } catch (exception) {
            interceptNetworkErrors({
                exception,
                locale,
                message: 'alert.formListGetOnFormEditScreenFailed',
            });
        }
    }

    onWantsRevisions = (): void => {
        this.props.toggleRevisionModal(true);
    };

    /**
     * Called when the user clicked "save" before the save action is triggered.
     * Save action is aborted (no data sent to server) if validate() returns false.
     *
     * @param formData
     * @return boolean
     */
    abstract validate(formData: Partial<IForm>): boolean;

    /**
     * While this method returns `false`, a loading spinner is shown instead
     * of the main editing UI.
     *
     * @return boolean
     */
    abstract getReadyState(): boolean;

    abstract async saveAction(
        formData: Partial<IForm>,
        formId?: string
    ): Promise<IForm>;

    abstract async publishAction(
        formData: Partial<IForm>,
        formId?: string
    ): Promise<IForm>;

    protected onAfterSave(): void {
        return;
    }

    saveForm = async (fieldData: {
        form: Partial<IForm>;
        schema: ISchemaItem[];
    }): Promise<void> => {
        return this.persist(fieldData, false);
    };

    publishForm = async (fieldData: {
        form: Partial<IForm>;
        schema: ISchemaItem[];
    }): Promise<void> => {
        return this.persist(fieldData, true);
    };

    persist = async (
        fieldData: {
            form: Partial<IForm>;
            schema: ISchemaItem[];
        },
        publish: boolean
    ): Promise<void> => {
        const {
            locale,
            formData,
            formEdited,
            loadAnimationToggle,
            formFieldsDefinitionChanged,
            alertAdd,
            updateEditStatus,
        } = this.props;
        const { translate } = this.context;
        loadAnimationToggle(true);

        formFieldsDefinitionChanged(fieldData);

        const formId = formData.id || '';
        if (!this.validate(formData)) {
            loadAnimationToggle(false);
            alertAdd({
                type: AlertType.DANGER,
                message: translate('alert.slug_validation_failed'),
                lifeSeconds: 10,
            });
            return;
        }

        try {
            const action = publish ? this.publishAction : this.saveAction;
            const response: IForm = await action(formData, formId);
            // update state with new/updated form
            await formEdited(response);
            alertAdd({
                type: AlertType.SUCCESS,
                message: translate('alert.save_success'),
                lifeSeconds: 10,
            });
            updateEditStatus(FORM_EDIT_STATUS.SAVED);
            this.onAfterSave();
        } catch (e) {
            this.props.interceptNetworkErrors({
                exception: e,
                locale: locale,
            });
        } finally {
            loadAnimationToggle(false);
        }
    };

    toggleTemplate = async (): Promise<void> => {
        const {
            alertAdd,
            formData,
            formEdited,
            loadAnimationToggle,
            locale,
            updateEditStatus,
        } = this.props;
        const { translate } = this.context;

        loadAnimationToggle(true);

        try {
            const response: IForm = await formApi.toggleTemplate(formData.id);
            await formEdited(response);
            alertAdd({
                type: AlertType.SUCCESS,
                message: translate('alert.save_success'),
                lifeSeconds: 10,
            });
            updateEditStatus(FORM_EDIT_STATUS.SAVED);
            this.onAfterSave();
        } catch (e) {
            this.props.interceptNetworkErrors({
                exception: e,
                locale: locale,
            });
        } finally {
            loadAnimationToggle(false);
        }
    };

    render(): JSX.Element {
        const { apiVersion, formData, locale, user } = this.props;
        const ready = this.getReadyState();

        return (
            <FormEdit
                apiVersion={apiVersion}
                canManageTemplates={
                    apiVersion >= ApiVersion.TEMPLATES && isAdmin(user)
                }
                canPublish={this.canPublish()}
                canSave={this.canSave()}
                formData={formData}
                hasPreview={this.hasPreview()}
                hasRevisions={this.hasRevisions()}
                isTemplate={formData.template}
                onWantsRevisions={this.onWantsRevisions}
                locale={locale}
                onSubmit={this.saveForm}
                onPublish={this.publishForm}
                onCancel={() => this.props.formReset}
                onToggleTemplate={this.toggleTemplate}
                ready={ready}
                user={user}
            />
        );
    }
}

export const connectFormManagementScreenComponent = (
    Component: FunctionComponent | ComponentClass,
    additionalActionCreators = null
): React.ComponentClass => {
    const defaultActionCreators = {
        alertAdd,
        formDataReceived,
        formEdited,
        formFieldsDefinitionChanged,
        formListLoaded,
        formReset,
        interceptNetworkErrors,
        loadAnimationToggle,
        toggleRevisionModal,
        updateEditStatus,
    };

    const stateToPropsMapping = (state: IState) => ({
        apiVersion: getApiVersion(state),
        editStatus: getEditStatus(state),
        formData: getCurrentFormData(state),
        hasLoaded: hasLoaded(state),
        locale: getLocale(state),
        hasListLoaded: hasListLoaded(state),
        revisionModalOpen: isRevisionModalOpen(state),
        slugs: getSlugs(state),
        user: getUser(state),
    });

    const actionCreators = _merge(
        {},
        defaultActionCreators,
        additionalActionCreators
    );

    const ConnectedComponent = connect(
        stateToPropsMapping,
        (dispatch: Dispatch) => bindActionCreators(actionCreators, dispatch)
    )(Component);
    return withRouter(ConnectedComponent);
};
