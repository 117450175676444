import React from 'react';
import ReactTags from 'react-tag-autocomplete';
import { connect } from 'react-redux';
import { IState } from '../../../../store/reducer';
import {
    formListLoaded,
    getCurrentPage,
    getCurrentTags,
    getSearchMode,
    getTagSuggestions,
    pageCountChanged,
    SearchMode,
    searchModeChanged,
    tagListChanged,
    tagSelectionChanged,
    tagSelectionOverride,
} from '../../../../duck/form.list.duck';
import { bindActionCreators, Dispatch } from 'redux';
import { ITagValue } from '../../../../domain/model/form/tags';
import { translations } from '../../../../translations/translations';
import { getLocale } from '../../../../duck/localization.duck';
import { getApiVersion } from '../../../../duck/api.duck';
import { tagApi } from '../../../../api/sdk/tag';
import { interceptNetworkErrors } from '../../../../duck/alert.duck';
import { ApiVersion } from '../../../../api/sdk/api-version';

const tagClassNames = {
    root: 'react-tags form-control',
    rootFocused: 'is-focused',
    selected: 'react-tags__selected',
    selectedTag: 'react-tags__selected-tag',
    selectedTagName: 'react-tags__selected-tag-name',
    search: 'react-tags__search',
    searchInput: 'react-tags__search-input',
    suggestions: 'react-tags__suggestions',
    suggestionActive: 'is-active',
    suggestionDisabled: 'is-disabled',
};

interface ITagSearchConnectProps {
    apiVersion: number;
    currentPage: number;
    currentTags: ITagValue[];
    formListLoaded: typeof formListLoaded;
    interceptNetworkErrors: typeof interceptNetworkErrors;
    locale: string;
    pageCountChanged: typeof pageCountChanged;
    searchMode: SearchMode;
    searchModeChanged: typeof searchModeChanged;
    tagSuggestions: ITagValue[];
    tagListChanged: typeof tagListChanged;
    tagSelectionChanged: typeof tagSelectionChanged;
    tagSelectionOverride: typeof tagSelectionOverride;
}

type ITagSearchProps = ITagSearchConnectProps;

export class RemoteTagSearchComponent extends React.Component<ITagSearchProps> {
    componentDidMount(): void {
        if (this.props.apiVersion > ApiVersion.BASE) {
            this.init().then();
        }
    }

    async componentDidUpdate(
        prevProps: Readonly<ITagSearchProps>
    ): Promise<void> {
        const { apiVersion, currentTags, currentPage, searchMode } = this.props;

        if (
            apiVersion !== prevProps.apiVersion &&
            apiVersion > ApiVersion.BASE
        ) {
            await this.init();
        }

        const searchModeChangedToTagSearch =
            searchMode !== prevProps.searchMode &&
            searchMode === SearchMode.TAGS;
        const tagSelectionChanged = currentTags !== prevProps.currentTags;

        if (tagSelectionChanged || searchModeChangedToTagSearch) {
            await this.onTagSelectionChanged();
        }

        if (
            searchMode === SearchMode.TAGS &&
            currentPage !== prevProps.currentPage
        ) {
            await this.onPageChanged();
        }
    }

    async init(): Promise<void> {
        await this.getTagList();
    }

    async getTagList(): Promise<void> {
        try {
            const { tags } = await tagApi.getTagList();
            this.props.tagListChanged(tags);
        } catch (exception) {
            this.props.interceptNetworkErrors({
                exception,
                locale: this.props.locale,
                message: 'alert.network.tag_list_fetch_fail',
            });
        }
    }

    async getFormsForTagsAtPage(
        tags: ITagValue[],
        page: number
    ): Promise<void> {
        const { formListLoaded, pageCountChanged } = this.props;
        const { forms, pages } = await tagApi.getFormsForTags(tags, page);

        formListLoaded({ forms });
        pageCountChanged(pages);
    }

    async onPageChanged(): Promise<void> {
        const { currentPage, currentTags } = this.props;

        await this.getFormsForTagsAtPage(currentTags, currentPage);
    }

    async onTagSelectionChanged(): Promise<void> {
        const { currentTags } = this.props;

        if (currentTags.length) {
            const { currentTags, searchMode, searchModeChanged } = this.props;

            await this.getFormsForTagsAtPage(currentTags, 1);

            if (searchMode !== SearchMode.TAGS) {
                searchModeChanged(SearchMode.TAGS);
            }
        } else {
            searchModeChanged(SearchMode.DEFAULT);
        }
    }

    render(): JSX.Element {
        const { currentTags, tagSuggestions, tagSelectionChanged, locale } =
            this.props;

        return (
            <ReactTags
                tags={currentTags}
                suggestions={tagSuggestions}
                onDelete={(index) => tagSelectionChanged({ index })}
                onAddition={(tag) => tagSelectionChanged({ tag })}
                classNames={tagClassNames}
                delimiters={[',', 'Enter', 'Tab', ' ']}
                allowNew={false}
                autoresize={false}
                placeholderText={translations.formList.tagSearch[locale]}
            />
        );
    }
}

export const RemoteTagSearch = connect(
    (state: IState) => ({
        apiVersion: getApiVersion(state),
        currentPage: getCurrentPage(state),
        currentTags: getCurrentTags(state),
        locale: getLocale(state),
        searchMode: getSearchMode(state),
        tagSuggestions: getTagSuggestions(state),
    }),
    (dispatch: Dispatch) =>
        bindActionCreators(
            {
                formListLoaded,
                interceptNetworkErrors,
                pageCountChanged,
                searchModeChanged,
                tagListChanged,
                tagSelectionChanged,
                tagSelectionOverride,
            },
            dispatch
        )
)(RemoteTagSearchComponent);
