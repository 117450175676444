import React from 'react';
import { connect } from 'react-redux';
import { IState } from '../store/reducer';
import {
    getCurrentFormData,
    isRevisionModalOpen,
    toggleRevisionModal,
} from '../duck/form.edit.duck';
import { bindActionCreators, Dispatch } from 'redux';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { IForm, IFormListItem } from '../domain/model/form/form';
import { interceptNetworkErrors } from '../duck/alert.duck';
import { LocalizationContext } from '../components/localization/localization.provider';
import { revisionApi } from '../api/sdk/revisions';
import { ScaleLoader } from 'react-spinners';
import { ListTableSimple } from '../components/form/list/list.table.simple';
import { LIST_VIEW_MODE } from '../duck/form.list.duck';
import { ButtonsVariant } from '../components/form/form.list';
import { RevisionListBadges } from '../components/form/list/revision.list.badges';
import { CloseButton } from '../components/close.button';
import { getUser } from '../duck/auth.duck';
import { User } from 'oidc-react';
import { isAdmin } from '../domain/model/user/user.roles';

interface RevisionEditScreenComponentProps {
    form: IForm;
    interceptNetworkErrors: typeof interceptNetworkErrors;
    isOpen: boolean;
    toggleRevisionModal: typeof toggleRevisionModal;
    user?: User;
}

interface RevisionEditScreenState {
    loaded: boolean;
    revisions: IFormListItem[];
}

class RevisionEditScreenComponent extends React.Component<RevisionEditScreenComponentProps> {
    static contextType = LocalizationContext;

    state: RevisionEditScreenState = {
        loaded: false,
        revisions: [],
    };

    componentDidUpdate(prevProps: Readonly<RevisionEditScreenComponentProps>) {
        if (!prevProps.isOpen && this.props.isOpen) {
            this.fetchRevisions().then();
        }
    }

    async fetchRevisions(): Promise<void> {
        const { form } = this.props;
        await this.onApiAction(revisionApi.getRevisionsForForm, form.id);
    }

    async onApiAction(
        action: (id: string) => Promise<IFormListItem[]>,
        id: string
    ): Promise<void> {
        const { interceptNetworkErrors } = this.props;
        const { locale } = this.context;

        this.setState({
            loaded: false,
        });

        try {
            const revisions = await action(id);
            this.setState({
                revisions,
                loaded: true,
            });
        } catch (e) {
            interceptNetworkErrors({
                exception: e,
                locale,
            });
            this.setState({
                loaded: true,
            });
        }
    }

    onRestore = async (revisionId: string): Promise<void> => {
        await this.onApiAction(revisionApi.restore, revisionId);
    };

    onStickify = async (revisionId: string): Promise<void> => {
        await this.onApiAction(revisionApi.stickify, revisionId);
    };

    onUnstickify = async (revisionId: string): Promise<void> => {
        await this.onApiAction(revisionApi.unstickify, revisionId);
    };

    render(): JSX.Element {
        const { form, isOpen, toggleRevisionModal, user } = this.props;
        const { translate, locale } = this.context;
        const { loaded, revisions } = this.state;

        return (
            <Modal
                isOpen={isOpen}
                size={'xl'}
                className="text-center modal-dialog-centered"
            >
                <ModalHeader
                    className="d-flex justify-content-between"
                    close={
                        <CloseButton
                            toggleOpen={() => toggleRevisionModal(false)}
                        />
                    }
                >
                    {translate('revisions.header')}
                </ModalHeader>
                <ModalBody>
                    {!loaded && <ScaleLoader />}
                    {loaded && revisions && revisions.length ? (
                        <ListTableSimple
                            apiVersion={2}
                            buttons={ButtonsVariant.REVISION_LIST}
                            currentViewMode={LIST_VIEW_MODE.SIMPLE_TABLE}
                            getBadges={(
                                revision: IFormListItem
                            ): null | JSX.Element => {
                                return (
                                    <RevisionListBadges
                                        revision={revision}
                                        currentForm={form}
                                    />
                                );
                            }}
                            getPrimaryAction={(revision: IFormListItem) => {
                                return revision.revisionId === form.revisionId
                                    ? null
                                    : this.onRestore;
                            }}
                            getSecondaryAction={(form: IFormListItem) => {
                                if (form.sticky) {
                                    return isAdmin(user)
                                        ? this.onUnstickify
                                        : null;
                                }
                                return this.onStickify;
                            }}
                            getSecondaryLabel={(revision: IFormListItem) => {
                                return revision.sticky
                                    ? 'revisions.unstickify.button'
                                    : 'revisions.stickify.button';
                            }}
                            hasPagination={false}
                            items={revisions}
                            locale={locale}
                            onTagClicked={() => null}
                            refresh={() => null}
                            viewModeChanged={() => null}
                        />
                    ) : (
                        <p>Leer</p>
                    )}
                </ModalBody>
            </Modal>
        );
    }
}

export const RevisionEditScreen = connect(
    (state: IState) => ({
        form: getCurrentFormData(state),
        isOpen: isRevisionModalOpen(state),
        user: getUser(state),
    }),
    (dispatch: Dispatch) =>
        bindActionCreators(
            {
                interceptNetworkErrors,
                toggleRevisionModal,
            },
            dispatch
        )
)(RevisionEditScreenComponent);
