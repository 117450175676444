import React from 'react';
import { Button } from 'reactstrap';
import { PrismCode } from 'react-prism';
import 'prismjs';
import 'prismjs/themes/prism.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { translations } from '../../../translations/translations';

interface IDeliverHintProps {
    formId?: string;
    formSlug: string;
    formType: string;
    locale: string;
    overrides?: string;
}

export const DeliveryHint = (props: IDeliverHintProps): JSX.Element => {
    const scriptUrl = process.env.REACT_APP_ELQ_GENERATOR_URL;
    const { formSlug, formType, overrides = null, locale } = props;
    let formContainerString = `<div class="eloqua-form eloqua-form--${formType}" data-eloqua-formid="${formSlug}">`;
    if (overrides) {
        formContainerString += overrides;
    }
    formContainerString += '</div>';
    const formScriptString = `<script src="${scriptUrl}"></script>`;

    return (
        <>
            <div className="mb-4">
                <div style={{ display: 'flex' }}>
                    <label className="input-group-text" style={{ flex: '1' }}>
                        {translations.formElement.delivery.container[locale]}
                    </label>
                    <CopyToClipboard text={formContainerString}>
                        <Button
                            outline={true}
                            color="primary"
                            aria-label={
                                translations.formAction.copyToClipboard[locale]
                            }
                            title={
                                translations.formAction.copyToClipboard[locale]
                            }
                        >
                            <FontAwesomeIcon icon={faCopy} />
                        </Button>
                    </CopyToClipboard>
                </div>
                <PrismCode component="pre" className="language-html">
                    {formContainerString}
                </PrismCode>
            </div>
            <div className="mb-2">
                <div style={{ display: 'flex' }}>
                    <label className="input-group-text" style={{ flex: '1' }}>
                        {translations.formElement.delivery.script[locale]}
                    </label>
                    <CopyToClipboard text={formScriptString}>
                        <Button
                            outline={true}
                            color="primary"
                            aria-label={
                                translations.formAction.copyToClipboard[locale]
                            }
                            title={
                                translations.formAction.copyToClipboard[locale]
                            }
                        >
                            <FontAwesomeIcon icon={faCopy} />
                        </Button>
                    </CopyToClipboard>
                </div>
                <PrismCode component="pre" className="language-html">
                    {formScriptString}
                </PrismCode>
            </div>
        </>
    );
};
