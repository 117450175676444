import React from 'react';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/de';

dayjs.locale('de');
dayjs.extend(relativeTime);

const DateFormatted = ({ dateString }: { dateString: string }): JSX.Element => (
    <time
        title={dayjs(dateString).format('DD.MM.YYYY - HH:mm')}
        dateTime={dateString}
    >
        {dayjs(dateString).fromNow()}
    </time>
);

export default DateFormatted;
