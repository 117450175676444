import React from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import { translations } from '../../../translations/translations';
import { ApiVersion } from '../../../api/sdk/api-version';
import { ITranslatable } from './input/help';

interface FormPreviewButtonProps {
    apiVersion: number;
    currentSlug: string;
    label?: ITranslatable;
    locale: string;
    outline?: boolean;
    onWantsPreview: () => void;
}

export const FormPreviewButton = (
    props: FormPreviewButtonProps
): JSX.Element => {
    const {
        apiVersion,
        currentSlug,
        label = translations.formAction.preview,
        locale,
        outline = false,
        onWantsPreview,
    } = props;

    const buttonProps = {
        color: 'info',
        'aria-label': translations.formAction.preview[locale],
        outline,
    };

    if (apiVersion >= ApiVersion.REVISIONS) {
        buttonProps['onClick'] = onWantsPreview;
    } else {
        buttonProps[
            'href'
        ] = `https://elq.ghdev.de/dynamicconfigurator?formid=${currentSlug}`;
        buttonProps['target'] = '_blank';
    }

    return (
        <Button {...buttonProps}>
            <FontAwesomeIcon icon={faSearch} className="mr-2" />
            <span>{label[locale]}</span>
        </Button>
    );
};
