import React from 'react';
import classnames from 'classnames';

import DateFormatted from '../../date/date.formatted';
import { Badge } from 'reactstrap';
import { ListItemButtons } from './list.item.buttons';
import { FormType, IFormListItem } from '../../../domain/model/form/form';
import { actionCallback } from '../form.list';

export interface ListItemProps {
    index: number;
    item: IFormListItem;
    locale: string;
    getPrimaryAction: (form?: IFormListItem) => null | actionCallback;
    onTagClicked: (tag) => void;
}

export const getTypeBadgeColor = (type: FormType): string => {
    switch (type) {
        case FormType.BASIC:
            return 'success';
        case FormType.CONTACT:
            return 'warning';
        case FormType.PROGRESSIVE:
            return 'info';
    }
};

export const FormTypeBadge = ({
    item,
    className = null,
}: {
    item: IFormListItem;
    className?: string;
}): JSX.Element => (
    <Badge className={className} color={getTypeBadgeColor(item.type)}>
        {item.type}
    </Badge>
);

export const ListGridItem = (props: ListItemProps): JSX.Element => {
    const { index, item, onTagClicked } = props;

    return (
        <div key={index} className="grid-listitem">
            <div
                className={classnames({
                    'grid-listitem__head': true,
                    'gh-bg-medium': index % 2 === 0,
                })}
            >
                <div className="grid-listitem__head__title">
                    <h3>{item.name}</h3>
                </div>
                <div className="grid-listitem__head__changedate">
                    <span>Zuletzt aktualisiert:</span>
                    <b>
                        <DateFormatted dateString={item.updatedAt} />
                    </b>
                </div>
            </div>

            <div className="grid-listitem__body">
                <div className="grid-listitem__body__content grid-listitem__body__content--narrow">
                    <code className="d-block mb-3">{item.slug || '-'}</code>
                    {item.type && (
                        <h5>
                            <FormTypeBadge {...props} />
                        </h5>
                    )}
                </div>

                <div className="grid-listitem__body__content">
                    <a
                        className="d-block mb-3"
                        href={item.website.url}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {item.website.url}
                    </a>
                    <span>{item.website.name}</span>
                </div>

                <div className="grid-listitem__body__buttons">
                    <ListItemButtons {...props} />
                </div>
            </div>

            <div className="grid-listitem__footer">
                {item.tags &&
                    !!item.tags.length &&
                    item.tags.map((tag, i) => (
                        <button
                            key={i}
                            color="info"
                            onClick={() => {
                                onTagClicked(tag);
                            }}
                            className="react-tags__selected-tag"
                        >
                            {tag.name}
                        </button>
                    ))}
            </div>
        </div>
    );
};
