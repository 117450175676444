import { Routes } from '../routing/route';
import { IForm, IFormListItem } from '../../domain/model/form/form';
import { request } from '../../lib/http/request';
import { jsonHeaders } from '../request/headers';
import { UserAwareSdkClient } from './user.aware.sdk.client';
import { UnsupportedApiVersionException } from '../../lib/http/exception';
import { ApiVersion } from './api-version';

class FormApi extends UserAwareSdkClient {
    async getFormList(
        page = 1,
        apiVersion = ApiVersion.PAGINATED,
        directory = 1
    ): Promise<{ formList: IFormListItem[]; pages: number }> {
        let data;
        let pages = 0;

        if (Math.floor(apiVersion) === 2) {
            let res;
            if (apiVersion >= ApiVersion.DIRECTORIES) {
                res = await this.getDirectoryFormList(page, directory);
            } else {
                res = await this.getV2FormList(page);
            }
            const { data: v2data, pages: v2pages } = await res.json();
            data = v2data;
            pages = v2pages;
        } else {
            throw new UnsupportedApiVersionException(
                'Currently only the v2.x API is supported.'
            );
        }

        return {
            formList: data,
            pages,
        };
    }

    getDirectoryFormList(page = 0, directory = 1): Promise<Response> {
        return request(Routes.form.listDirectory(page, directory), {
            method: 'GET',
            headers: jsonHeaders(this.user),
        });
    }

    getV2FormList(page = 0): Promise<Response> {
        return request(Routes.form.list(page), {
            method: 'GET',
            headers: jsonHeaders(this.user),
        });
    }

    async getFormById(id: string): Promise<IForm> {
        return this.getJson(Routes.form.id(id));
    }

    async deleteFormById(id: string): Promise<unknown> {
        return this.deleteJson(Routes.form.id(id));
    }

    async updateFormById(id: string, data: IForm): Promise<IForm> {
        return this.putJson(Routes.form.id(id), data);
    }

    async createForm(data: IForm): Promise<IForm> {
        return this.postJson(Routes.form.base(), data);
    }

    async publishFormById(id: string, data: IForm): Promise<IForm> {
        return this.putJson(Routes.form.publish(id), data);
    }

    async getBinned(): Promise<IFormListItem[]> {
        return this.getJson(Routes.form.binned());
    }

    async restoreForm(id: string): Promise<void> {
        await this.postJson(Routes.form.restore(id), null);
    }

    async purgeForm(id: string): Promise<IFormListItem[]> {
        return this.deleteJson(Routes.form.purge(id), true);
    }

    async toggleTemplate(id: string): Promise<IForm> {
        return this.putJson(Routes.form.toggleTemplate(id), null);
    }

    async moveForm(id: string, targetDirectoryId: number): Promise<void> {
        await this.putJson(Routes.form.move(id, targetDirectoryId), null);
    }
}

export const formApi = new FormApi();
