import React from 'react';
import { connect } from 'react-redux';
import { IState } from '../../store/reducer';
import {
    DEFAULT_LOCALE,
    getLocale,
    translate,
} from '../../duck/localization.duck';

type LocalizationProviderComponentProps = {
    children?: JSX.Element | JSX.Element[];
    locale: string;
};

export const LocalizationContext = React.createContext({
    translate: (keyOrText: string) => keyOrText,
    locale: DEFAULT_LOCALE,
});

class LocalizationProviderComponent extends React.Component<LocalizationProviderComponentProps> {
    context: React.Context<{ translate: (keyOrText: string) => string }>;

    translate = (keyOrText: string): string => {
        return translate(keyOrText, this.props.locale);
    };

    render(): JSX.Element {
        return (
            <LocalizationContext.Provider
                value={{ translate: this.translate, locale: this.props.locale }}
            >
                {this.props.children}
            </LocalizationContext.Provider>
        );
    }
}

export const LocalizationProvider = connect((state: IState) => ({
    locale: getLocale(state),
}))(LocalizationProviderComponent);
