import React from 'react';
import { CustomInput } from 'reactstrap';
import {
    IInputComponentProps,
    ISelectOption,
} from './input.component.props.interface';
import { connect } from 'react-redux';
import { IState } from '../../../../store/reducer';
import { getLocale } from '../../../../duck/localization.duck';

export interface ICheckboxGroupInputProps extends IInputComponentProps {
    name: string;
    value: [];
    locale: string;
    selectOptions: ISelectOption[];
}

interface ICheckboxGroupState {
    initialValue: [];
    currentValue: string[];
}

class CheckboxGroupInputComponent extends React.Component<
    ICheckboxGroupInputProps,
    ICheckboxGroupState
> {
    constructor(props: ICheckboxGroupInputProps) {
        super(props);
        this.state = {
            initialValue: props.value,
            currentValue: props.value,
        };
    }

    onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const checkboxName = event.currentTarget.name;
        const isChecked = event.currentTarget.checked;

        const currentValue = this.state.currentValue;
        const newValue = [...currentValue];
        const currentValueOfOption = currentValue.findIndex(
            (el) => el === checkboxName
        );

        if (currentValueOfOption > -1 && !isChecked) {
            newValue.splice(currentValueOfOption, 1);
        } else if (currentValueOfOption === -1 && isChecked) {
            newValue.push(checkboxName);
        }

        const newEvent = {
            currentTarget: {
                value: newValue,
            },
        };

        this.setState({
            currentValue: newValue,
        });
        this.props.onChange(newEvent);
    };

    render() {
        const { name, readonly, locale, selectOptions } = this.props;
        const { currentValue } = this.state;

        return (
            <div className={'checkbox-group form-control'}>
                {selectOptions &&
                    selectOptions.map((opt) => {
                        const { label, value } = opt;
                        const checked =
                            currentValue.findIndex((el) => el === value) > -1;

                        return (
                            <CustomInput
                                key={value}
                                id={`input-${name}-${value}`}
                                type="switch"
                                name={value}
                                value={value}
                                disabled={readonly || false}
                                checked={checked}
                                onChange={this.onChange}
                                label={label[locale]}
                            />
                        );
                    })}
            </div>
        );
    }
}

export const CheckboxGroupInput = connect((state: IState) => ({
    locale: getLocale(state),
}))(CheckboxGroupInputComponent);
