import { IInputFieldEloquaConfiguration } from './input.field.eloqua.configuration';
import { ILabelParameters } from '../../../duck/form.builder.duck';

export enum InputFieldType {
    TEXT = 'text',
    TEXTAREA = 'textarea',
    HIDDEN = 'hidden',
    CHECKBOX = 'checkbox',
    RADIO = 'radio',
    ADDRESS = 'address',
    SELECT = 'select',
    CHECKBOX_GROUP = 'checkbox-group',
}

export enum InputFieldValidatorType {
    NOT_EMPTY = 'not_empty',
    EMAIL = 'email',
    NUMERIC = 'numeric',
    ALPHABETIC = 'alphabetic',
    PHONE = 'phone',
    REGEXP = 'regexp',
}

export interface InputFieldValidator {
    type: InputFieldValidatorType;
    message?: string;
    context?: Record<string, unknown>;
}

export interface IInputField {
    name: string;
    type: InputFieldType | '';
    inputName: string;
    label?: string;
    placeholder?: string;
    defaultValue?: string;
    validators: InputFieldValidator[];
    validatorOverride?: boolean;
    eloqua?: IInputFieldEloquaConfiguration;
    checkedValue?: string;
    labelParams?: ILabelParameters;
    options?: IOptionsFieldOption[];
    maxCharacters?: number;
    // @TODO: `settings` for special fields with settings
    /* @TODO: at a later point, we will move to isolate this to ProgressiveForm */
    fieldGroup?: string;
    settings: {
        formnames?: string;
        formnamelist?: string;
    };
    overridable: boolean;
    urlParameterName?: string | null;
}

export interface IOptionsFieldOption {
    value: string;
    label: string;
    selected?: boolean;
}
