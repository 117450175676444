import { translations } from '../../translations/translations';
import { InputFieldType } from '../../domain/model/form/input.field';
import { IInputFieldConfigSetupProps } from '../../components/form/edit/formBuilder/input/input.field';

import { SelectInput } from '../../components/form/edit/input/select.input';
import { AssocArrayInput } from '../../components/form/edit/formBuilder/input/assoc.array.input';
import { ValidatorsArrayInput } from '../../components/form/edit/formBuilder/input/validators.array.input';
import { SwitchInput } from '../../components/form/edit/input/switch.input';

export const PickFieldsByFieldType = {
    [InputFieldType.TEXT]: [
        'name',
        'inputType',
        'inputName',
        'value',
        'className',
        'labelText',
        'eloquaName',
        'validators',
        'validatorOverride',
        'className',
        'labelClassName',
        'inputClassName',
        'placeholder',
        'fieldGroup',
        'overridable',
        'urlParameterName',
    ],
    [InputFieldType.TEXTAREA]: [
        'name',
        'inputType',
        'inputName',
        'value',
        'className',
        'labelText',
        'eloquaName',
        'validators',
        'validatorOverride',
        'className',
        'labelClassName',
        'inputClassName',
        'maxChars',
        'fieldGroup',
        'overridable',
        'urlParameterName',
    ],
    [InputFieldType.HIDDEN]: [
        'name',
        'inputType',
        'value',
        'eloquaName',
        'overridable',
        'urlParameterName',
    ],
    [InputFieldType.CHECKBOX]: [
        'name',
        'inputType',
        'inputName',
        'value',
        'className',
        'labelText',
        'eloquaName',
        'validators',
        'validatorOverride',
        'className',
        'labelClassName',
        'inputClassName',
        'checkedValue',
        'fieldGroup',
        'overridable',
    ],
    [InputFieldType.RADIO]: [
        'name',
        'inputType',
        'inputName',
        'value',
        'className',
        'labelText',
        'eloquaName',
        'validators',
        'validatorOverride',
        'className',
        'labelClassName',
        'inputClassName',
        'checkedValue',
        'fieldGroup',
        'overridable',
        'urlParameterName',
    ],
    [InputFieldType.ADDRESS]: [
        'name',
        'inputType',
        'inputName',
        'value',
        'className',
        'labelText',
        'eloquaName',
        'validators',
        'validatorOverride',
        'className',
        'labelClassName',
        'inputClassName',
        'placeholder',
        'fieldGroup',
        'overridable',
        'urlParameterName',
    ],
    [InputFieldType.SELECT]: [
        'name',
        'inputType',
        'inputName',
        'value',
        'className',
        'labelText',
        'eloquaName',
        'validators',
        'validatorOverride',
        'labelClassName',
        'inputClassName',
        'selectOptions',
        'fieldGroup',
        'overridable',
        'urlParameterName',
    ],
    [InputFieldType.CHECKBOX_GROUP]: [
        'name',
        'inputType',
        'value',
        'selectOptions',
    ],
};

export const PickSpecialFieldsByFieldName = {
    request: ['settings.formnames', 'settings.formnamelist'],
    cominform: ['settings.takeValuesFromFields'],
    commentinforms: ['settings.takeValuesFromFields'],
    comform: ['settings.takeValuesFromFields'],
    optInGeneral: [
        'labelParams.privacyPolicyLink',
        'labelParams.privacyPolicyText',
        'labelParams.privacyPolicyProvider',
    ],
    privacyPolicy: [
        'labelParams.link',
        'labelParams.linkText',
        'labelParams.privacyPolicyProvider',
    ],
};

export interface IInputGroupConfigProps {
    title: string;
    collapsed: boolean;
    fields: IInputFieldConfigSetupProps[];
}

export const FieldFieldsConfig: IInputGroupConfigProps[] = [
    {
        title: translations.formBuilder.fieldGroup.basic,
        collapsed: false,
        fields: [
            {
                name: 'name',
                label: translations.formBuilder.field.name,
                readonly: false,
            },
            {
                name: 'inputType',
                label: translations.formBuilder.field.inputType,
                readonly: false,
                component: SelectInput,
                options: [
                    { label: '--', value: '' },
                    { label: InputFieldType.TEXT, value: InputFieldType.TEXT },
                    {
                        label: InputFieldType.TEXTAREA,
                        value: InputFieldType.TEXTAREA,
                    },
                    {
                        label: InputFieldType.HIDDEN,
                        value: InputFieldType.HIDDEN,
                    },
                    {
                        label: InputFieldType.CHECKBOX,
                        value: InputFieldType.CHECKBOX,
                    },
                    {
                        label: InputFieldType.RADIO,
                        value: InputFieldType.RADIO,
                    },
                    {
                        label: InputFieldType.ADDRESS,
                        value: InputFieldType.ADDRESS,
                    },
                    {
                        label: InputFieldType.SELECT,
                        value: InputFieldType.SELECT,
                    },
                    {
                        label: InputFieldType.CHECKBOX_GROUP,
                        value: InputFieldType.CHECKBOX_GROUP,
                    },
                ],
            },
            {
                name: 'inputName',
                label: translations.formBuilder.field.inputName,
            },
            {
                name: 'labelText',
                label: translations.formBuilder.field.labelText,
            },
            {
                name: 'value',
                label: translations.formBuilder.field.value,
            },
            {
                name: 'eloquaName',
                label: translations.formBuilder.field.eloquaName,
            },
            {
                name: 'overridable',
                label: translations.formBuilder.field.overridable,
                readonly: false,
                component: SwitchInput,
                help: {
                    body: translations.help.formBuilder.fields.overridable,
                },
            },
            {
                name: 'urlParameterName',
                label: translations.formBuilder.field.urlParameterName,
                readonly: false,
                help: {
                    body: translations.help.formBuilder.fields.urlParameterName,
                },
            },
        ],
    },
    {
        title: translations.formBuilder.fieldGroup.styling,
        collapsed: true,
        fields: [
            {
                name: 'className',
                label: translations.formBuilder.className,
            },
            {
                name: 'labelClassName',
                label: translations.formBuilder.field.labelClassName,
            },
            {
                name: 'inputClassName',
                label: translations.formBuilder.field.inputClassName,
            },
        ],
    },
    {
        title: translations.formBuilder.fieldGroup.typeSpecific,
        collapsed: true,
        fields: [
            {
                name: 'placeholder',
                label: translations.formBuilder.field.placeholder,
            },
            {
                name: 'selectOptions',
                label: translations.formBuilder.field.selectOptions,
                component: AssocArrayInput,
            },
            {
                name: 'maxChars',
                label: translations.formBuilder.field.maxChars,
            },
            {
                name: 'checkedValue',
                label: translations.formBuilder.field.checkedValue,
            },
            {
                name: 'labelParams.privacyPolicyLink',
                label: translations.formBuilder.field.labelParams
                    .privacyPolicyLink,
                isSpecialField: true,
            },
            {
                name: 'labelParams.privacyPolicyText',
                label: translations.formBuilder.field.labelParams
                    .privacyPolicyText,
                isSpecialField: true,
            },
            {
                name: 'labelParams.privacyPolicyProvider',
                label: translations.formBuilder.field.labelParams
                    .privacyPolicyProvider,
                isSpecialField: true,
            },
            {
                name: 'labelParams.link',
                label: translations.formBuilder.field.labelParams.link,
                isSpecialField: true,
            },
            {
                name: 'labelParams.linkText',
                label: translations.formBuilder.field.labelParams.linkText,
                isSpecialField: true,
            },
        ],
    },
    {
        title: translations.formBuilder.fieldGroup.validation,
        collapsed: true,
        fields: [
            {
                name: 'validators',
                label: translations.formBuilder.field.validators,
                component: ValidatorsArrayInput,
            },
            {
                name: 'validatorOverride',
                label: translations.formBuilder.field.validatorOverride,
                component: SwitchInput,
            },
        ],
    },
    {
        title: translations.formBuilder.fieldGroup.formTypeSpecific,
        collapsed: true,
        fields: [
            {
                name: 'fieldGroup',
                label: translations.formBuilder.field.fieldGroup,
                readonly: false,
                help: {
                    body: translations.help.formBuilder.fields.fieldGroup,
                },
            },
            {
                name: 'settings.formnames',
                label: translations.formBuilder.field.requestDynamicOptions,
                help: {
                    link: 'https://haufe-eloqua-tests.ghdev.de/docs/v3/de/extras/dynamic-contact-form#elqformname',
                },
                component: SelectInput,
                options: [
                    { label: 'default (Umantis)', value: '' },
                    { label: 'Aus (kein Mapping)', value: 'static' },
                    { label: 'suite', value: 'suite' },
                    { label: 'Custom-Liste', value: 'list' },
                ],
                isSpecialField: true,
            },
            {
                name: 'settings.formnamelist',
                label: translations.formBuilder.field.requestDynamicCustomList,
                help: {
                    body: translations.help.formBuilder.fields
                        .requestDynamicCustomList,
                },
                isSpecialField: true,
            },
            {
                name: 'settings.takeValuesFromFields',
                label: translations.formBuilder.field.takeValuesFromFields,
                help: {
                    body: translations.help.formBuilder.fields
                        .takeValuesFromFields,
                },
                isSpecialField: true,
            },
        ],
    },
];
