import * as Routes from '../routing/route';
import { formApi } from '../api/sdk/form';
import { IForm } from '../domain/model/form/form';
import { formDuplicateIDReset } from '../duck/form.edit.duck';
import {
    connectFormManagementScreenComponent,
    FormManagementScreen,
} from './form.management.screen';

export class FormDuplicateScreenComponent extends FormManagementScreen {
    async componentDidMount(): Promise<void> {
        await super.componentDidMount();
        await this.props.formDuplicateIDReset();
    }

    canPublish(): boolean {
        return false;
    }

    async saveAction(formData: Partial<IForm>): Promise<IForm> {
        return formApi.createForm(formData);
    }

    async publishAction(): Promise<IForm> {
        return Promise.resolve(this.props.formData);
    }

    getReadyState(): boolean {
        return this.props.hasLoaded;
    }

    onAfterSave(): void {
        const formId = this.props.formData.id;
        this.props.history.push({
            pathname: Routes.FORM_URL(formId),
        });
    }

    validate(formData: Partial<IForm>): boolean {
        return !(!formData.slug || this.props.slugs[formData.slug]);
    }

    hasRevisions(): boolean {
        return false;
    }
}

export const FormDuplicateScreenWithRouter =
    connectFormManagementScreenComponent(FormDuplicateScreenComponent, {
        formDuplicateIDReset,
    });
