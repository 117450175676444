import { applyMiddleware, createStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { rootReducer } from './reducer';

export class StoreFactory {
    static createComposeEnhancers(): (unknown) => unknown {
        return composeWithDevTools;
    }

    static createStore(): Store {
        const composeEnhancers = StoreFactory.createComposeEnhancers();

        return createStore(
            rootReducer,
            composeEnhancers(applyMiddleware(thunk))
        );
    }
}
