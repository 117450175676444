import React from 'react';
import { LocalizationContext } from '../localization/localization.provider';
import { Button } from 'reactstrap';
import { announcementApi } from '../../api/sdk/announcement';
import {
    alertAdd,
    AlertType,
    interceptNetworkErrors,
} from '../../duck/alert.duck';
import dayjs from 'dayjs';
import { AnnouncementType } from '../../domain/model/announcement/announcement.type';
import {
    InputValue,
    UnconnectedInputField,
} from '../form/input/unconnected.input.field';
import { announcementFormFields } from '../../configuration/announcement.form.fields';
import { Announcement } from '../../domain/model/announcement/announcement';

enum EditMode {
    UPDATE,
    CREATE,
}

interface AnnouncementFormState {
    fields: Map<string, InputValue>;
    mode: EditMode;
}

interface AnnouncementFormProps {
    alertAdd: typeof alertAdd;
    announcement: Announcement | null;
    onEditFinished: () => void;
    interceptNetworkErrors: typeof interceptNetworkErrors;
}

export class AnnouncementForm extends React.Component<AnnouncementFormProps> {
    static contextType = LocalizationContext;

    state: AnnouncementFormState = {
        fields: new Map(),
        mode: null,
    };

    constructor(props: AnnouncementFormProps) {
        super(props);

        const fields = new Map();
        let mode = null;

        if (props.announcement === null) {
            announcementFormFields.forEach((field) => {
                if (typeof field.value !== 'undefined') {
                    fields.set(field.name, field.value);
                }
            });
            mode = EditMode.CREATE;
        } else {
            Object.keys(props.announcement).forEach((field) => {
                let value = props.announcement[field];
                if (field === 'dateStart' || field === 'dateEnd') {
                    value = value ? new Date(value) : '';
                }
                fields.set(field, value);
            });
            mode = EditMode.UPDATE;
        }

        this.state = {
            fields,
            mode,
        };
    }

    formatDate(rawDate: InputValue): string | null {
        if (rawDate) {
            return dayjs(rawDate.toString()).format() || null;
        } else {
            return null;
        }
    }

    onFieldChange = (fieldName: string, newValue: InputValue): void => {
        const fields = new Map(this.state.fields);
        fields.set(fieldName, newValue);
        this.setState({
            fields,
        });
    };

    onSubmit = async (): Promise<void> => {
        const { alertAdd, interceptNetworkErrors, onEditFinished } = this.props;
        const { fields, mode } = this.state;

        const announcement: Announcement = {
            title: fields.get('title') as string,
            type: fields.get('type') as AnnouncementType,
            message: fields.get('message') as string,
            dateStart: this.formatDate(fields.get('dateStart')),
            dateEnd: this.formatDate(fields.get('dateEnd')),
            url: fields.get('url') as string,
        };

        let method = 'createAnnouncement';

        if (mode === EditMode.UPDATE) {
            method = 'updateAnnouncement';
            announcement.id = this.props.announcement.id;
        }

        try {
            await announcementApi[method](announcement);
            alertAdd({
                type: AlertType.SUCCESS,
                message: this.context.translate(
                    'announcements.form.submit.success'
                ),
                lifeSeconds: 10,
            });
            onEditFinished();
        } catch (e) {
            interceptNetworkErrors({
                exception: e,
                locale: this.context.locale,
                message: 'announcements.error.submit_fail',
            });
        }
    };

    render(): JSX.Element {
        const { fields } = this.state;
        const { translate } = this.context;

        return (
            <>
                <form name="announcements" className={'announcements-form'}>
                    {announcementFormFields.map((field) => (
                        <UnconnectedInputField
                            key={field.name}
                            onChange={this.onFieldChange}
                            value={fields.get(field.name)}
                            name={field.name}
                            component={field.component}
                            labelKey={field.label}
                            options={field.options || null}
                        />
                    ))}
                </form>
                <Button color={'success'} onClick={this.onSubmit}>
                    {translate('announcements.form.submit.button')}
                </Button>
            </>
        );
    }
}
