import React from 'react';
import BillboardChart from 'react-billboardjs';
import 'billboard.js/dist/billboard.css';
import 'billboard.js/dist/theme/insight.css';

import { DeliveryStat } from '../../../domain/model/form/form';
import { LocalizationContext } from '../../localization/localization.provider';

interface DeliveryStatsProps {
    deliveries: DeliveryStat[];
}

export const DeliveryStats = ({
    deliveries,
}: DeliveryStatsProps): JSX.Element => {
    const currentTotal = deliveries[deliveries.length - 1]?.deliveries || 0;
    return (
        <LocalizationContext.Consumer>
            {({ translate }) => (
                <div className={'delivery-stats'}>
                    <h4>{translate('deliveries.title')}</h4>
                    <BillboardChart
                        size={{ height: 160 }}
                        data={{
                            json: deliveries,
                            keys: {
                                x: 'month',
                                value: ['deliveries'],
                            },
                            type: 'area-spline',
                            xFormat: '%Y-%m',
                            colors: {
                                deliveries: 'rgba(0, 139, 139, 1)',
                            },
                        }}
                        axis={{
                            x: {
                                type: 'timeseries',
                                tick: {
                                    culling: {
                                        max: 5,
                                    },
                                    fit: true,
                                    count: 5,
                                    format: '%Y-%m',
                                },
                            },
                            y: {
                                show: false,
                            },
                        }}
                        point={{
                            focus: {
                                only: true,
                            },
                        }}
                        legend={{ show: false }}
                    />
                    <div className={'delivery-stats__current'}>
                        <p className={'lead d-flex justify-content-between'}>
                            <span>{translate('deliveries.current')}</span>
                            <span>{currentTotal}</span>
                        </p>
                    </div>
                </div>
            )}
        </LocalizationContext.Consumer>
    );
};
