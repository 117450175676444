import { FormDuplicateScreenComponent } from './form.duplicate.screen';
import { connectFormManagementScreenComponent } from './form.management.screen';

export class FormCreateScreenComponent extends FormDuplicateScreenComponent {
    async componentDidMount(): Promise<void> {
        const { formData, formEdited, formReset, location } = this.props;

        formReset();

        if (location.state && location.state.dir) {
            const updatedForm = {
                ...formData,
                directoryId: location.state.dir,
            };
            formEdited(updatedForm);
        }
    }

    getReadyState(): boolean {
        return true;
    }

    hasRevisions(): boolean {
        return false;
    }
}

export const FormCreateScreenWithRouter = connectFormManagementScreenComponent(
    FormCreateScreenComponent
);
