import { formApi } from '../api/sdk/form';
import { IForm } from '../domain/model/form/form';
import {
    connectFormManagementScreenComponent,
    FormManagementScreen,
} from './form.management.screen';
import { ApiVersion } from '../api/sdk/api-version';
import { isAdmin } from '../domain/model/user/user.roles';

export class FormEditScreenComponent extends FormManagementScreen {
    canPublish(): boolean {
        const { apiVersion, formData, user } = this.props;

        if (apiVersion < ApiVersion.REVISIONS) {
            return false;
        }

        return !(formData.template && !isAdmin(user));
    }

    canSave(): boolean {
        const { formData, user } = this.props;

        return !(formData.template && !isAdmin(user));
    }

    hasPreview(): boolean {
        return true;
    }

    hasRevisions(): boolean {
        return this.props.apiVersion >= ApiVersion.REVISIONS;
    }

    async saveAction(
        formData: Partial<IForm>,
        formId?: string
    ): Promise<IForm> {
        return formApi.updateFormById(formId, formData);
    }

    async publishAction(
        formData: Partial<IForm>,
        formId?: string
    ): Promise<IForm> {
        return formApi.publishFormById(formId, formData);
    }

    getReadyState(): boolean {
        return this.props.hasLoaded;
    }

    onWantsRevisions = (): void => {
        const { formData, toggleRevisionModal, user } = this.props;

        if (!(formData.template && !isAdmin(user))) {
            toggleRevisionModal(true);
        }
    };

    validate(formData: Partial<IForm>): boolean {
        return !(
            !formData.slug ||
            (this.props.slugs[formData.slug] &&
                this.props.slugs[formData.slug] !== formData.id)
        );
    }
}

export const FormEditScreenWithRouter = connectFormManagementScreenComponent(
    FormEditScreenComponent
);
