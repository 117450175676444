export interface FormRouteParams {
    id: string;
}

const UUID_PARAM = (paramName: string) =>
    paramName +
    '([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})';

export const APP_BASE_PATH = process.env.REACT_APP_BASE_PATH;

export const INDEX_PATH = '/';

export const FORM_PATH = '/form/edit/' + UUID_PARAM(':id');

export const FORM_URL = (id: string): string => '/form/edit/' + id;

export const FORM_DUPLICATE_PATH = '/form/duplicate/' + UUID_PARAM(':id');

export const FORM_DUPLICATE_URL = (id: string): string =>
    '/form/duplicate/' + id;

export const FORM_CREATE_PATH = '/form/create';

export const ERROR_404_PATH = '/404';
