export const revisionTranslations = {
    header: {
        de: 'Revisionen',
    },
    restore: {
        button: {
            de: 'Revision wiederherstellen',
        },
        success: {
            de: 'Erfolgreich wiederhergestellt',
        },
    },
    stickify: {
        button: {
            de: 'Revision anheften',
        },
        success: {
            de: 'Formular erfolgreich angeheftet.',
        },
    },
    unstickify: {
        button: {
            de: 'Anheftung entfernen',
        },
        success: {
            de: 'Formularanheftung erfolgreich entfernt.',
        },
    },
    error: {
        de: 'Ein Fehler ist aufgetreten',
    },
    help: {
        restore: {
            content: {
                de: `Bei der Wiederherstellung einer Revision wird die entsprechende Konfiguration auf deren Stand
                    zurückgesetzt. Der Publikationsstatus wird dadurch nicht beeinflusst.
                    `,
            },
            title: {
                de: 'Wiederherstellen von Revisionen',
            },
        },
        stickify: {
            content: {
                de: `Ist die maximale Anzahl an Revisionen pro Konfiguration (zehn) erreicht, wird die älteste 
                    Revision gelöscht bevor eine neue erstellt wird. Anheften schützt eine spezifische Revision vor
                    dieser automatischen Löschung. Das Anheften von Revisionen kann nur durch Nutzer*innen mit
                    Administrator-Rechten rückgängig gemacht werden.
                    `,
            },
            title: {
                de: 'Anheften von Revisionen',
            },
        },
    },
    badges: {
        latest: {
            de: 'Aktuell bearbeitete Revision',
        },
        published: {
            de: 'Aktuell veröffentlicht',
        },
        sticky: {
            de: 'Angeheftete Revision',
        },
        unsavedChanges: {
            de: 'Nicht-publizierte Änderungen',
        },
    },
};
