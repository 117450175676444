/*
 * Helpers: MAP FORM BUILDER DATA TO BACKEND SERVER DATA MODEL
 */
import {
    ISchemaItem,
    SchemaItemType,
} from '../../domain/model/form/form.schema';
import {
    IInputField,
    InputFieldType,
} from '../../domain/model/form/input.field';
import { IForm } from '../../domain/model/form/form';
import {
    IFormBuilderItem,
    IFormBuilderItemType,
    SUPPORTED_ITEM_TYPES,
} from '../form.builder.duck';

export const isFormBuilderItemSupported = (item: IFormBuilderItem): boolean => {
    return SUPPORTED_ITEM_TYPES.includes(item.data.type);
};

export const findSchemaItemType = (
    item: IFormBuilderItem
): SchemaItemType | null => {
    if (item.data.type === IFormBuilderItemType.SUBMIT) {
        return SchemaItemType.SUBMIT;
    }

    if (item.data.type === IFormBuilderItemType.FIELD) {
        return SchemaItemType.INPUT_FIELD;
    }

    if (item.data.type === IFormBuilderItemType.GROUP) {
        return SchemaItemType.GROUP;
    }

    if (item.data.type === IFormBuilderItemType.HTML) {
        return SchemaItemType.HTML;
    }

    return null;
};

export const findInputFieldType = (
    item: IFormBuilderItem
): InputFieldType | null => {
    return item.data.props.inputType as InputFieldType;
};

const parseTreeItemToFieldData = (item: IFormBuilderItem): IInputField => {
    /*
     *  @NB
     *      careful with empty arrays or strings etc. because we have to
     *      consider the pre-configurations that might break
     */
    return {
        type: findInputFieldType(item) || '',
        name: item.data.props.name,
        inputName: item.data.props.inputName || '',
        label: item.data.props.labelText || '',
        placeholder: item.data.props.placeholder || '',
        defaultValue: item.data.props.value ?? '',
        validators: item.data.props.validators || [],
        validatorOverride: item.data.props.validatorOverride ?? undefined,
        eloqua: {
            name: item.data.props.eloquaName || '',
        },
        fieldGroup: item.data.props.fieldGroup || null,

        options: item.data.props.selectOptions || [],
        maxCharacters: item.data.props.maxChars || undefined,
        checkedValue: item.data.props.checkedValue ?? '',
        labelParams: item.data.props.labelParams || {},
        settings: item.data.props.settings || null,
        overridable: item.data.props.overridable || false,
        urlParameterName: item.data.props.urlParameterName?.length
            ? item.data.props.urlParameterName
            : null,
    };
};

const filterSchema = (items: (ISchemaItem | undefined)[]): ISchemaItem[] => {
    return items.filter((el: ISchemaItem) => el !== undefined);
};

export const mapToFormModel = (
    formBuilderData: IFormBuilderItem[]
): { form: Partial<IForm>; schema: ISchemaItem[] } => {
    const fields = [];
    const controls = [];

    const recurseTreeToSchema = (item): ISchemaItem | undefined => {
        if (!isFormBuilderItemSupported(item)) return undefined;
        const children = formBuilderData.filter((e) => e.parent === item.id);
        let childItems = [];
        if (children && children.length) {
            childItems = children.map(recurseTreeToSchema);
            childItems = filterSchema(childItems);
        }
        const schemaItemType = findSchemaItemType(item);

        if (schemaItemType === SchemaItemType.INPUT_FIELD) {
            const parsedField = parseTreeItemToFieldData(item);
            fields.push(parsedField);
        }

        if (schemaItemType === SchemaItemType.SUBMIT) {
            controls.push({
                type: 'submit',
                name: item.data.props.name,
                text: item.data?.props?.text || undefined,
                textWhenFilled: item?.textWhenFilled || undefined,
            });
        }

        return {
            name: item.data.props.name || '',
            type: schemaItemType,
            attributes: {
                wrapper: {
                    className: item.data.props.className,
                },
                input: {
                    className: item.data.props.inputClassName,
                },
                label: {
                    className: item.data.props.labelClassName,
                },
            },
            items: childItems && childItems.length ? childItems : undefined,
            fieldName:
                item.data.type === IFormBuilderItemType.FIELD
                    ? item.data.props.name
                    : undefined,
            html:
                item.data.type === IFormBuilderItemType.HTML
                    ? item.data.props.value
                    : undefined,
        };
    };

    let schema = formBuilderData
        .filter((e) => e.parent === 0)
        .map(recurseTreeToSchema);
    schema = filterSchema(schema);

    return {
        form: {
            fields,
            controls,
        },
        schema,
    };
};
