import { handleActions, createAction, Action } from 'redux-actions';
import { IState } from '../store/reducer';
import { createSelector } from 'reselect';
import { v4 as uuid } from 'uuid';
import {
    FetchErrorException,
    HttpException,
    NetworkException,
} from '../lib/http/exception';
import { debug } from '../util/debug';
import { DEFAULT_LOCALE, translate } from './localization.duck';
import { Announcement } from '../domain/model/announcement/announcement';

/*
 * Constants
 */

/*
 * Model - duck specific model, domain models go to `domain` folder
 */
export enum AlertType {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    SUCCESS = 'success',
    DANGER = 'danger',
    WARNING = 'warning',
    INFO = 'info',
    LIGHT = 'light',
    DARK = 'dark',
}

export interface IAlertListItem {
    id?: string;
    type: AlertType;
    message: string;
    lifeSeconds?: number;
}

export enum AnnouncementFetchStatus {
    INIT,
    FETCHING,
    RECEIVED,
}

/*
 * Module properties - singletons and other module specific vars
 */

/*
 * State
 */
export const MOUNT_POINT = 'alertList';

export interface IAlertListState {
    items: IAlertListItem[];
    announcements: Announcement[];
    announcementFetchStatus: AnnouncementFetchStatus;
}

export const DEFAULT_STATE = {
    items: [],
    announcements: [],
    announcementFetchStatus: AnnouncementFetchStatus.INIT,
};

/*
 * Selectors
 */
export const getState = (state: IState): IAlertListState => state[MOUNT_POINT];

export const getAlertList = createSelector(
    getState,
    (state: IAlertListState) => state.items
);

export const getAnnouncements = createSelector(
    getState,
    (state: IAlertListState) => state.announcements
);

export const getAnnouncementFetchStatus = createSelector(
    getState,
    (state: IAlertListState) => state.announcementFetchStatus
);

/*
 * Actions
 */
export const alertAdd = createAction<IAlertListItem>(
    MOUNT_POINT + '/ALERT_ADDED'
);
export const alertRemove = createAction<string>(MOUNT_POINT + '/ALERT_REMOVED');
export const alertClear = createAction(MOUNT_POINT + '/ALERT_CLEARED');
export const interceptNetworkErrors = createAction<{
    exception: NetworkException;
    message?: string;
    locale?: string;
}>(MOUNT_POINT + '/INTERCEPT_NETWORK_ERRORS');
export const setAnnouncements = createAction<Announcement[]>(
    MOUNT_POINT + '/SET_ANNOUNCEMENTS'
);
export const announcementsFetchStarted = createAction(
    MOUNT_POINT + '/ANNOUNCEMENTS_FETCH_STARTED'
);

/*
 * Reducers
 */
/* eslint-disable-next-line */
export const alertListReducer = handleActions<IAlertListState, any, any>(
    {
        [alertAdd.toString()]: (
            state: IAlertListState,
            action: Action<IAlertListItem>
        ) => {
            const id = uuid();
            const newAlert = { id, ...action.payload };

            const newState = { ...state };
            newState.items = [newAlert, ...newState.items];

            return newState;
        },
        [alertRemove.toString()]: (
            state: IAlertListState,
            action: Action<string>
        ) => {
            const id = action.payload;
            const newState = { ...state };

            newState.items = state.items.filter((alert) => alert.id !== id);

            return newState;
        },
        [alertClear.toString()]: () => ({ ...DEFAULT_STATE }),
        [interceptNetworkErrors.toString()]: (
            state: IAlertListState,
            action: Action<{
                exception: NetworkException;
                message?: string;
                locale?: string;
            }>
        ) => {
            const newState = { ...state };
            const {
                exception,
                message: baseMessage = '',
                locale = DEFAULT_LOCALE,
            } = action.payload;

            debug.error(exception);

            let errorMessage = '';
            if (exception instanceof HttpException) {
                errorMessage = exception.message;

                if (exception.response.url) {
                    errorMessage += `: ${exception.response.url}`;
                }

                switch (exception.code) {
                    case 401:
                        errorMessage += translate(
                            'alert.network.err401',
                            locale
                        );
                        break;
                    case 403:
                        errorMessage += translate(
                            'alert.network.err403',
                            locale
                        );
                }
            } else if (exception instanceof FetchErrorException) {
                errorMessage = translate('alert.network.request_fail', locale);
            }

            const translatedBaseMessage = translate(baseMessage, locale);

            let message = '';
            if (baseMessage) {
                message += `${translatedBaseMessage}: `;
            }
            message += '' + errorMessage;

            const id = uuid();
            const newAlert = {
                id,
                type: AlertType.DANGER,
                message,
                lifeSeconds: 10,
            };
            newState.items = [newAlert, ...newState.items];

            return newState;
        },
        [setAnnouncements.toString()]: (
            state: IAlertListState,
            action: Action<Announcement[]>
        ) => {
            return {
                ...state,
                announcements: action.payload,
                announcementFetchStatus: AnnouncementFetchStatus.RECEIVED,
            };
        },
        [announcementsFetchStarted.toString()]: (state: IAlertListState) => {
            return {
                ...state,
                announcementFetchStatus: AnnouncementFetchStatus.FETCHING,
            };
        },
    },
    { ...DEFAULT_STATE }
);

/*
 * Sagas
 */

/*
 * Helpers
 */
