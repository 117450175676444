import { Routes } from '../../api/routing/route';
import { request } from '../../lib/http/request';
import { jsonHeaders } from '../request/headers';
import { Announcement } from '../../domain/model/announcement/announcement';
import { AnnouncementType } from '../../domain/model/announcement/announcement.type';
import { UserAwareSdkClient } from './user.aware.sdk.client';
import { isAnnouncementActive } from '../../util/announcements';

class AnnouncementApi extends UserAwareSdkClient {
    async getActiveAnnouncements(): Promise<Announcement[]> {
        const route = Routes.announcement.active();

        if (route.length) {
            try {
                const response = await request(route, {
                    method: 'GET',
                    headers: jsonHeaders(this.user),
                });
                if (!response.ok)
                    return AnnouncementApi.getStaticAnnouncements();
                return response.json();
            } catch (e) {
                return AnnouncementApi.getStaticAnnouncements();
            }
        } else {
            return AnnouncementApi.getStaticAnnouncements();
        }
    }

    async getAllAnnouncements(): Promise<Announcement[]> {
        const route = Routes.announcement.all();

        if (!route.length) return [];

        return this.getJson(route);
    }

    async createAnnouncement(
        announcement: Announcement
    ): Promise<Announcement> {
        return this.postJson(Routes.announcement.active(), announcement);
    }

    async deleteAnnouncement(announcement: Announcement): Promise<unknown> {
        return this.deleteJson(Routes.announcement.id(announcement.id));
    }

    async updateAnnouncement(announcement: Announcement): Promise<unknown> {
        return this.putJson(
            Routes.announcement.id(announcement.id),
            announcement
        );
    }

    /**
     * Fallback solution until some public backend can properly handle and serve
     * announcements: A static array of Announcement objects, filtered by their
     * start & end dates at runtime.
     */
    static getStaticAnnouncements(): Announcement[] {
        return [
            {
                id: 1,
                title: 'Wartungsarbeiten',
                type: AnnouncementType.WARNING,
                message:
                    'Am Montag, 14.03.2022 finden zwischen ca. 13.00 und 16.00 Uhr Wartungsarbeiten am Eloqua-Configurator statt. In dieser Zeit kann es temporär zu Ausfällen an Eloqua-Formularen und dem Konfigurator-Interface kommen. Wir bitten darum, in diesem Zeitraum keine Formulare zu bearbeiten.',
                dateEnd: '2022-03-14T16:00:00',
                dateStart: null,
                url: null,
            },
            {
                id: 1,
                title: 'Wichtig: Änderung des Anmeldeverfahrens',
                type: AnnouncementType.URGENT,
                message: `
                    Ab dem 02. Mai wird der Zugriff auf den Eloqua-Konfigurator nur noch für eingeloggte Nutzer über 
                    den Haufe Azure SSO möglich sein. Der Zugriff über die generische Nutzer-/Passwort-Kombination wird 
                    dann nicht mehr möglich sein. Um auch zukünftig deinen Zugriff zu gewährleisten, lies Dir bitte 
                    folgende Informationen durch und kontaktiere ggf. einen der darin erwähnten Ansprechpartner.
                    `,
                dateEnd: '2022-05-01T23:59:59',
                dateStart: null,
                url: 'https://eloqua-docs.ghdev.de/de/announcements/aenderung-des-anmeldeverfahrens-ab-02-05-2022',
            },
        ].filter(isAnnouncementActive);
    }
}

export const announcementApi = new AnnouncementApi();
