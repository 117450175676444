import React from 'react';
import {
    DndProvider,
    getBackendOptions,
    MultiBackend,
    Tree,
} from '@minoru/react-dnd-treeview';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCaretDown,
    faCaretRight,
    faShieldHalved,
} from '@fortawesome/free-solid-svg-icons';

import { LocalizationContext } from '../localization/localization.provider';
import { Directory } from '../../domain/model/directory/directory';
import { TreeItem } from '../../domain/model/lib/tree.item';

interface DirectoryTreeProps {
    getOnNodeClick: (TreeItem) => (Event) => Promise<void>;
    onMoveDrop: (newTree, { dragSourceId, dropTargetId }) => Promise<void>;
    currentDirectoryId: number;
    directoryTree: Directory;
}

interface DirectoryTreeItem extends TreeItem {
    data: {
        hasChildren: boolean;
        adminProtection: boolean;
    };
}

export class DirectoryTree extends React.Component<DirectoryTreeProps> {
    static contextType = LocalizationContext;

    mapDirectoryTree(rootDirectory: Directory): DirectoryTreeItem[] {
        let tree: DirectoryTreeItem[] = [];
        tree.push({
            text: rootDirectory.name,
            id: 1,
            parent: 0,
            droppable: true,
            data: {
                hasChildren: true,
                adminProtection: true,
            },
        });

        rootDirectory.children?.forEach((child) => {
            tree = this.mapDirectoryChildrenRecursive(tree, child, 1);
        });

        return tree;
    }

    mapDirectoryChildrenRecursive(
        tree: DirectoryTreeItem[],
        child: Directory,
        parentId: number
    ): DirectoryTreeItem[] {
        tree.push({
            text: child.name,
            id: child.id,
            parent: parentId,
            droppable: true,
            data: {
                hasChildren: child.children.length > 0,
                adminProtection: child.adminProtection,
            },
        });

        child.children.forEach(
            (grandchild) =>
                (tree = this.mapDirectoryChildrenRecursive(
                    tree,
                    grandchild,
                    child.id
                ))
        );

        return tree;
    }

    renderNode = (node: DirectoryTreeItem, { depth, isOpen, onToggle }) => {
        const { currentDirectoryId, getOnNodeClick } = this.props;
        const { translate } = this.context;

        const isActive = node.id === currentDirectoryId;
        const icon = isOpen ? faCaretDown : faCaretRight;
        return (
            <div
                style={{ marginLeft: depth * 10 }}
                className={classnames('directory-node', {
                    active: isActive,
                })}
            >
                {node.droppable && node.data.hasChildren && (
                    <span className="directory-node__icon" onClick={onToggle}>
                        <FontAwesomeIcon icon={icon} />
                    </span>
                )}
                <span
                    className="directory-node__name"
                    onClick={getOnNodeClick(node)}
                >
                    {node.text}
                </span>
                {node.data.adminProtection && (
                    <span className="directory-node__badge-protected d-flex justify-content-center bg-warning px-1 align-items-center">
                        <FontAwesomeIcon
                            icon={faShieldHalved}
                            title={translate('directory.protect.badge')}
                        />
                    </span>
                )}
            </div>
        );
    };

    render() {
        const { directoryTree, onMoveDrop } = this.props;
        let treeData = [];
        if (directoryTree !== null) {
            treeData = this.mapDirectoryTree(directoryTree);
        }

        return (
            <div className="directories">
                <DndProvider
                    backend={MultiBackend}
                    options={getBackendOptions()}
                >
                    <Tree
                        tree={treeData}
                        rootId={0}
                        render={this.renderNode}
                        onDrop={onMoveDrop}
                        initialOpen={[1]}
                        placeholderRender={(node, { depth }) => (
                            <div
                                className="tree-placeholder"
                                style={{ left: `${depth * 10}px` }}
                            >
                                <span>{node.text}</span>
                            </div>
                        )}
                        dropTargetOffset={8}
                    />
                </DndProvider>
            </div>
        );
    }
}
