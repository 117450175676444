import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import {
    alertAdd,
    AlertType,
    interceptNetworkErrors,
} from '../../duck/alert.duck';
import { LocalizationContext } from '../localization/localization.provider';
import { IFormListItem } from '../../domain/model/form/form';
import { formApi } from '../../api/sdk/form';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ListTableSimple } from '../form/list/list.table.simple';
import { LIST_VIEW_MODE } from '../../duck/form.list.duck';
import { ButtonsVariant } from '../form/form.list';
import { ScaleLoader } from 'react-spinners';
import { ApiVersion } from '../../api/sdk/api-version';
import { CloseButton } from '../close.button';

interface RecycleBinProps {
    alertAdd: typeof alertAdd;
    isOpen: boolean;
    toggleOpen: () => void;
    interceptNetworkErrors: typeof interceptNetworkErrors;
}

interface RecycleBinState {
    forms: IFormListItem[];
    loaded: boolean;
}

class RecycleBinComponent extends React.Component<RecycleBinProps> {
    static contextType = LocalizationContext;

    state: RecycleBinState = {
        forms: [],
        loaded: false,
    };

    componentDidMount() {
        this.getRecycleBinList().then();
    }

    async getRecycleBinList(): Promise<void> {
        const { interceptNetworkErrors } = this.props;
        const { locale } = this.context;

        try {
            const list = await formApi.getBinned();
            if (list && list.length) {
                this.setState({
                    forms: list,
                    loaded: true,
                });
            } else {
                this.setState({
                    loaded: true,
                });
            }
        } catch (e) {
            interceptNetworkErrors({
                exception: e,
                message: 'recycleBin.error',
                locale,
            });
        }
    }

    onDelete = async (formId: string): Promise<void> => {
        const { alertAdd, interceptNetworkErrors } = this.props;
        const { locale } = this.context;

        this.setState({
            loaded: false,
        });

        try {
            const list = await formApi.purgeForm(formId);
            alertAdd({
                message: 'recycleBin.purge.success',
                lifeSeconds: 10,
                type: AlertType.SUCCESS,
            });
            this.setState({
                // forms: _reject(this.state.forms, ['id', formId]),
                forms: list || [],
                loaded: true,
            });
        } catch (e) {
            interceptNetworkErrors({
                exception: e,
                message: 'recycleBin.error',
                locale,
            });
            this.setState({
                loaded: false,
            });
        }
    };

    onRestore = async (formId: string): Promise<void> => {
        const { alertAdd, interceptNetworkErrors } = this.props;
        const { locale } = this.context;

        try {
            await formApi.restoreForm(formId);
            alertAdd({
                message: 'recycleBin.restore.success',
                lifeSeconds: 10,
                type: AlertType.SUCCESS,
            });
        } catch (e) {
            interceptNetworkErrors({
                exception: e,
                message: 'recycleBin.error',
                locale,
            });
        }
    };

    render(): JSX.Element {
        const { isOpen, toggleOpen } = this.props;
        const { translate, locale } = this.context;
        const { forms, loaded } = this.state;

        return (
            <Modal
                size="xl"
                isOpen={isOpen}
                className="text-center modal-dialog-centered"
            >
                <ModalHeader
                    className="d-flex justify-content-between"
                    close={<CloseButton toggleOpen={toggleOpen} />}
                >
                    {translate('recycleBin.header')}
                </ModalHeader>
                <ModalBody>
                    {!loaded && <ScaleLoader />}
                    {loaded && forms.length ? (
                        <ListTableSimple
                            apiVersion={ApiVersion.REVISIONS}
                            hasPagination={false}
                            getBadges={() => null}
                            items={forms}
                            locale={locale}
                            currentViewMode={LIST_VIEW_MODE.SIMPLE_TABLE}
                            getPrimaryAction={() => this.onDelete}
                            getSecondaryAction={() => this.onRestore}
                            onTagClicked={() => null}
                            refresh={() => null}
                            viewModeChanged={() => null}
                            buttons={ButtonsVariant.RECYCLE_BIN}
                        />
                    ) : (
                        <p>Leer</p>
                    )}
                </ModalBody>
            </Modal>
        );
    }
}

export const RecycleBin = connect(null, (dispatch: Dispatch) =>
    bindActionCreators(
        {
            alertAdd,
            interceptNetworkErrors,
        },
        dispatch
    )
)(RecycleBinComponent);
