import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCaretDown,
    faCaretRight,
    faEdit,
    faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { Badge, Button, ButtonGroup } from 'reactstrap';
import {
    Tree,
    MultiBackend,
    getBackendOptions,
    DndProvider,
    useDragOver,
    TreeMethods,
} from '@minoru/react-dnd-treeview';

import { InputFieldValidatorType } from '../../domain/model/form/input.field';
import { translations } from '../../translations/translations';
import {
    IFormBuilderItem,
    IFormBuilderItemType,
} from '../../duck/form.builder.duck';
import { FormBuilderActionBar } from './edit/formBuilder/form.builder.action.bar';
import { FormBuilderForm } from './edit/formBuilder/formBuilderForm';
import { CompactButton } from '../compact.button';
import { AlertType } from '../../duck/alert.duck';
import { TreeItem } from '../../domain/model/lib/tree.item';

interface IFormEditProps {
    actionBarProps;
    editingOverlayVisible: boolean;
    formOverlayProps;
    handleChange;
    items: TreeItem[];
    locale: string;
    onFieldDeleteRequested;
    onFieldEditRequested;
}

interface ButtonProps {
    id: string;
}

interface NodeProps {
    node: IFormBuilderItem;
}

export class FormBuilder extends React.Component<IFormEditProps> {
    ref = React.createRef<TreeMethods>();

    getButtons = (props: ButtonProps): JSX.Element => (
        <ButtonGroup>
            {this.getEditButton(props)}
            {this.getDeleteButton(props)}
        </ButtonGroup>
    );

    getDeleteButton = (props: ButtonProps): JSX.Element => (
        <Button
            outline
            color="danger"
            aria-label={translations.formAction.delete[this.props.locale]}
            title={translations.formAction.delete[this.props.locale]}
            onClick={() => {
                this.props.onFieldDeleteRequested(props.id);
            }}
        >
            <FontAwesomeIcon icon={faTrash} />
        </Button>
    );

    getEditButton = (props: ButtonProps): JSX.Element => (
        <Button
            outline={true}
            color="primary"
            aria-label={translations.formAction.edit[this.props.locale]}
            title={translations.formAction.edit[this.props.locale]}
            onClick={() => {
                this.props.onFieldEditRequested(props.id);
            }}
        >
            <FontAwesomeIcon icon={faEdit} className="mr-2" />
            {translations.formAction.edit[this.props.locale]}
        </Button>
    );

    renderNode = (node: IFormBuilderItem, { depth, isOpen, onToggle }) => {
        const icon = isOpen ? faCaretDown : faCaretRight;

        let Component: ({ node }: NodeProps) => JSX.Element;

        switch (node.data.type) {
            case IFormBuilderItemType.FIELD:
                Component = this.FieldNode;
                break;
            case IFormBuilderItemType.GROUP:
                Component = this.GroupNode;
                break;
            case IFormBuilderItemType.HTML:
                Component = this.HTMLNode;
                break;
            case IFormBuilderItemType.SUBMIT:
                Component = this.ControlNode;
                break;
        }

        const dragOverProps = useDragOver(node.id, isOpen, onToggle);
        const cn = 'fb-node--' + node.data.type;

        return (
            <div
                style={{ marginLeft: depth * 10 }}
                className={`fb-node ${cn}`}
                {...dragOverProps}
            >
                {node.droppable && (
                    <span className="fb-node__icon" onClick={onToggle}>
                        <FontAwesomeIcon icon={icon} />
                    </span>
                )}
                <Component node={node} />
                <div className="fb-node__actions">
                    <CompactButton
                        id={`fb-node-${node.id}-edit`}
                        color={AlertType.INFO}
                        icon={faEdit}
                        label={translations.formAction.edit[this.props.locale]}
                        onClick={() => this.props.onFieldEditRequested(node.id)}
                        small
                    />
                    <CompactButton
                        id={`fb-node-${node.id}-delete`}
                        color={AlertType.DANGER}
                        icon={faTrash}
                        label={
                            translations.formAction.delete[this.props.locale]
                        }
                        onClick={() =>
                            this.props.onFieldDeleteRequested(node.id)
                        }
                        small
                    />
                </div>
            </div>
        );
    };

    ControlNode = (): JSX.Element => {
        return (
            <>
                <Button outline color="secondary" onClick={() => null}>
                    Submit
                </Button>
            </>
        );
    };

    FieldNode = (props: NodeProps): JSX.Element => {
        const { node } = props;

        const Subtitle = (props) => (
            /* ESLint thinks we're not going to give the <h> any content */
            // eslint-disable-next-line
            <h5 className="tree__subtitle d-inline-block ml-2" {...props} />
        );
        const hasNotEmptyValidation =
            node.data.props.validators &&
            node.data.props.validators.find(
                (e) => e.type === InputFieldValidatorType.NOT_EMPTY
            ) !== undefined;
        const inputType =
            node.data.props.inputType ||
            translations.formBuilder.unknownInputType[this.props.locale];

        return (
            <>
                <h4 className="d-inline-block">{node.text}</h4>
                <Subtitle>
                    <Badge>{inputType}</Badge>
                    {hasNotEmptyValidation && (
                        <Badge color="warning">
                            {
                                translations.formBuilder.validatorType[
                                    InputFieldValidatorType.NOT_EMPTY
                                ][this.props.locale]
                            }
                        </Badge>
                    )}
                    {node.data.props.value && (
                        <Badge color="info">
                            {
                                translations.formBuilder.hasValue[
                                    this.props.locale
                                ]
                            }
                        </Badge>
                    )}
                </Subtitle>
            </>
        );
    };

    GroupNode = ({ node }: NodeProps): JSX.Element => {
        return (
            <>
                <h4>{node.text}</h4>
            </>
        );
    };

    HTMLNode = (): JSX.Element => {
        return <>{translations.formBuilder.itemType.html[this.props.locale]}</>;
    };

    render(): JSX.Element {
        const {
            actionBarProps,
            editingOverlayVisible,
            formOverlayProps,
            handleChange,
            items,
        } = this.props;

        return (
            <div className="eloqua-formBuilder">
                <FormBuilderActionBar
                    onExpand={() => {
                        const ids = items
                            .filter((e) => e.parent === 0)
                            .map((e) => e.id);
                        if (this.ref && this.ref.current) {
                            this.ref.current.open(ids);
                        }
                    }}
                    onCloseAll={() => {
                        if (this.ref && this.ref.current) {
                            this.ref.current.closeAll();
                        }
                    }}
                    {...actionBarProps}
                />
                <DndProvider
                    backend={MultiBackend}
                    options={getBackendOptions()}
                >
                    <Tree
                        ref={this.ref}
                        tree={items}
                        rootId={0}
                        render={this.renderNode}
                        onDrop={handleChange}
                        sort={false}
                        classes={{
                            placeholder: 'tree-placeholder-container',
                        }}
                        initialOpen={[0]}
                        placeholderRender={(node, { depth }) => (
                            <div
                                className="tree-placeholder"
                                style={{ left: `${depth * 10}px` }}
                            >
                                <span>{node.text}</span>
                            </div>
                        )}
                        insertDroppableFirst={false}
                        dropTargetOffset={8}
                        canDrop={(currentTree, { dropTarget }) => {
                            return (
                                typeof dropTarget === 'undefined' ||
                                dropTarget.droppable
                            );
                        }}
                    />
                </DndProvider>
                {editingOverlayVisible && (
                    <FormBuilderForm {...formOverlayProps} />
                )}
            </div>
        );
    }
}
