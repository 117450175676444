import React, { FunctionComponent } from 'react';
import {
    Button,
    InputGroupAddon,
    PopoverBody,
    PopoverHeader,
    UncontrolledPopover,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import classnames from 'classnames';

import { translations } from '../../../../translations/translations';

export interface ITranslatable {
    [languageIso: string]: string;
}

export interface IHelpSetupProps {
    body?: ITranslatable;
    link?: string;
}

export interface IHelpProps extends IHelpSetupProps {
    name: string;
    locale: string;
    isInputAddon?: boolean;
    title?: ITranslatable;
}

export class Help extends React.Component<IHelpProps> {
    nameSlug = this.props.name.replace(/\./g, '-');

    getButton(): FunctionComponent {
        const { locale, isInputAddon } = this.props;

        return () => (
            <Button
                id={'help_' + this.nameSlug}
                outline={isInputAddon}
                className={classnames({
                    'float-right': !isInputAddon,
                })}
                color="success"
                aria-label={translations.formAction.help[locale]}
                title={translations.formAction.help[locale]}
                /* eslint-disable-next-line */
                onClick={() => {}}
            >
                <FontAwesomeIcon icon={faQuestionCircle} />
            </Button>
        );
    }

    getPopover(): FunctionComponent {
        const { locale, body, link, title } = this.props;

        return () => (
            <UncontrolledPopover
                target={'help_' + this.nameSlug}
                trigger="click"
            >
                {title && <PopoverHeader>{title[locale]}</PopoverHeader>}
                {body && <PopoverBody>{body[locale]}</PopoverBody>}
                {link && (
                    <PopoverBody>
                        <a
                            href={link}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {translations.formAction.details[locale]}
                        </a>
                    </PopoverBody>
                )}
            </UncontrolledPopover>
        );
    }

    wrap(
        HelpButton: FunctionComponent,
        HelpPopover: FunctionComponent
    ): FunctionComponent {
        if (this.props.isInputAddon) {
            return () => (
                <InputGroupAddon addonType="append">
                    <HelpButton />
                    <HelpPopover />
                </InputGroupAddon>
            );
        } else {
            return () => (
                <>
                    <HelpButton />
                    <HelpPopover />
                </>
            );
        }
    }

    render(): JSX.Element {
        const { body, link } = this.props;
        if (!(body || link)) return null;

        const HelpButton = this.getButton();
        const HelpPopover = this.getPopover();
        const HelpElement = this.wrap(HelpButton, HelpPopover);

        return <HelpElement />;
    }
}
