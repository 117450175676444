import { handleActions } from 'redux-actions';
import _get from 'lodash/get';

/*
 * Constants
 */
import { createSelector } from 'reselect';
import { IState } from '../store/reducer';
import { translations } from '../translations/translations';

export const DEFAULT_LOCALE = 'de';

/*
 * Model - duck specific model, domain models go to `domain` folder
 */

/*
 * Module properties - singletons and other module specific vars
 */

/*
 * State
 */

export const MOUNT_POINT = 'localization';

export interface ILocalizationState {
    locale: string;
}

export const DEFAULT_STATE: ILocalizationState = {
    locale: DEFAULT_LOCALE,
};

/*
 * Selectors
 */
export const getState = (state: IState): ILocalizationState =>
    state[MOUNT_POINT];

export const getLocale = createSelector(
    getState,
    (state: ILocalizationState) => state.locale
);

/*
 * Actions
 */

/*
 * Reducers
 */
/* eslint-disable-next-line */
export const localizationReducer = handleActions<ILocalizationState, any, any>(
    {
        // TODO add actions
    },
    DEFAULT_STATE
);

/*
 * Sagas
 */

/*
 * Helpers
 */

export function translate(
    keyOrText: string,
    locale: string = DEFAULT_LOCALE
): string {
    return _get(translations, [keyOrText, locale].join('.'), keyOrText);
}
