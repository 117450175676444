import React from 'react';
import { ButtonGroup } from 'reactstrap';
import { faUndo, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import { IAppProps } from '../form.list';
import { ListItemButtonsProps } from './list.item.buttons';
import { CompactButton } from '../../compact.button';

export const RubbishListItemButtons = (
    props: ListItemButtonsProps & IAppProps
): JSX.Element => {
    const { item, getSecondaryAction, getPrimaryAction } = props;
    const primaryAction = getPrimaryAction();
    const secondaryAction = getSecondaryAction();

    return (
        <ButtonGroup>
            <CompactButton
                id={`rubbish-action-restore-${item.id}`}
                color="info"
                label={'recycleBin.restore.button'}
                onClick={() => secondaryAction(item.id)}
                icon={faUndo}
            />

            <CompactButton
                id={`rubbish-action-purge-${item.id}`}
                color="danger"
                onClick={() => primaryAction(item.id)}
                label={'recycleBin.purge.button'}
                icon={faTrashAlt}
            />
        </ButtonGroup>
    );
};
