import { AdminOnly } from '../utility/admin.only';
import { Button, ButtonGroup, Container } from 'reactstrap';
import { AnnouncementEditor } from '../announcements/announcement.editor';
import { LocalizationContext } from '../localization/localization.provider';
import React from 'react';
import { RecycleBin } from './recycle.bin';
import { ApiVersion } from '../../api/sdk/api-version';

interface ToolbarProps {
    apiVersion: number;
}

export const Toolbar = (props: ToolbarProps): JSX.Element => {
    const { apiVersion } = props;

    const [announcementEditorVisible, setAnnouncementEditorState] =
        React.useState(false);
    const [recycleBinVisible, setRecycleBinVisible] = React.useState(false);

    const toggleAnnouncementEditor = () => {
        setAnnouncementEditorState(!announcementEditorVisible);
    };

    const toggleRecycleBin = () => {
        setRecycleBinVisible(!recycleBinVisible);
    };

    const hasRecycleBin = apiVersion >= ApiVersion.REVISIONS;

    return (
        <AdminOnly>
            <LocalizationContext.Consumer>
                {({ translate }) => (
                    <div className="admin-toolbar bg-dark">
                        <Container>
                            <ButtonGroup>
                                <Button
                                    color="secondary"
                                    onClick={toggleAnnouncementEditor}
                                >
                                    {translate('announcements.manage')}
                                </Button>
                                {hasRecycleBin && (
                                    <Button
                                        color="secondary"
                                        onClick={toggleRecycleBin}
                                    >
                                        {translate('recycleBin.header')}
                                    </Button>
                                )}
                            </ButtonGroup>
                            {announcementEditorVisible && (
                                <AnnouncementEditor
                                    isOpen={announcementEditorVisible}
                                    toggleOpen={toggleAnnouncementEditor}
                                />
                            )}
                            {hasRecycleBin && recycleBinVisible && (
                                <RecycleBin
                                    isOpen={recycleBinVisible}
                                    toggleOpen={toggleRecycleBin}
                                />
                            )}
                        </Container>
                    </div>
                )}
            </LocalizationContext.Consumer>
        </AdminOnly>
    );
};
