import React from 'react';
import {
    Button,
    ButtonDropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCompressAlt,
    faExpandAlt,
    faPlus,
} from '@fortawesome/free-solid-svg-icons';

import { IFormBuilderItemType } from '../../../../duck/form.builder.duck';
import { translations } from '../../../../translations/translations';
import { CompactButton } from '../../../compact.button';
import { AlertType } from '../../../../duck/alert.duck';

export interface IFormBuilderActionBarProps {
    dropdownOpen: boolean;
    hidden?: boolean;
    locale: string;
    onFieldCreateRequested;
    toggle;
    onExpand: () => void;
    onCloseAll: () => void;
}

export function FormBuilderActionBar(
    props: IFormBuilderActionBarProps
): JSX.Element {
    const {
        hidden = false,
        locale,
        onFieldCreateRequested,
        onExpand,
        onCloseAll,
    } = props;

    return (
        !hidden && (
            <div className="eloqua-formBuilder__actions mb-4 d-flex justify-content-between">
                <div className="d-flex eloqua-formBuilder__expansion">
                    <CompactButton
                        id={'tree-open-first-level'}
                        onClick={onExpand}
                        label={'Alle Gruppen ausklappen'}
                        color={AlertType.PRIMARY}
                        icon={faExpandAlt}
                        small
                    />
                    <CompactButton
                        id={'tree-close-all'}
                        onClick={onCloseAll}
                        label={'Alles einklappen'}
                        color={AlertType.PRIMARY}
                        icon={faCompressAlt}
                        small
                    />
                </div>
                <div className="d-flex justify-content-end">
                    <ButtonDropdown
                        isOpen={props.dropdownOpen}
                        toggle={props.toggle}
                    >
                        <Button
                            id="caret"
                            outline
                            color="success"
                            onClick={() =>
                                onFieldCreateRequested(
                                    IFormBuilderItemType.FIELD
                                )
                            }
                        >
                            <FontAwesomeIcon icon={faPlus} className="mr-2" />
                            {translations.formAction.addField[locale]}
                        </Button>
                        <DropdownToggle caret outline color="success" />
                        <DropdownMenu>
                            <DropdownItem
                                onClick={() =>
                                    onFieldCreateRequested(
                                        IFormBuilderItemType.GROUP
                                    )
                                }
                            >
                                <FontAwesomeIcon
                                    icon={faPlus}
                                    className="mr-2"
                                />
                                {
                                    translations.formBuilder.itemType.group[
                                        locale
                                    ]
                                }
                            </DropdownItem>
                            <DropdownItem
                                onClick={() =>
                                    onFieldCreateRequested(
                                        IFormBuilderItemType.SUBMIT
                                    )
                                }
                            >
                                <FontAwesomeIcon
                                    icon={faPlus}
                                    className="mr-2"
                                />
                                {
                                    translations.formBuilder.itemType.submit[
                                        locale
                                    ]
                                }
                            </DropdownItem>
                            <DropdownItem
                                onClick={() =>
                                    onFieldCreateRequested(
                                        IFormBuilderItemType.HTML
                                    )
                                }
                            >
                                <FontAwesomeIcon
                                    icon={faPlus}
                                    className="mr-2"
                                />
                                {translations.formBuilder.itemType.html[locale]}
                            </DropdownItem>
                        </DropdownMenu>
                    </ButtonDropdown>
                </div>
            </div>
        )
    );
}
