import { TextInput } from '../components/form/edit/input/text.input';
import { TextAreaInput } from '../components/form/edit/input/textarea.input';
import { SelectInput } from '../components/form/edit/input/select.input';
import { DatetimeInput } from '../components/form/edit/input/datetime.input';
import { AnnouncementType } from '../domain/model/announcement/announcement.type';

export const announcementFormFields = [
    {
        name: 'title',
        label: 'announcements.form.title.label',
        component: TextInput,
        value: '',
    },
    {
        name: 'message',
        label: 'announcements.form.message.label',
        component: TextAreaInput,
        value: '',
    },
    {
        name: 'type',
        label: 'announcements.form.type.label',
        component: SelectInput,
        value: AnnouncementType.DEFAULT,
        options: [
            {
                label: 'announcements.form.type.options.default',
                value: AnnouncementType.DEFAULT,
            },
            {
                label: 'announcements.form.type.options.warning',
                value: AnnouncementType.WARNING,
            },
            {
                label: 'announcements.form.type.options.urgent',
                value: AnnouncementType.URGENT,
            },
        ],
    },
    {
        name: 'url',
        label: 'announcements.form.url.label',
        value: '',
    },
    {
        name: 'dateStart',
        component: DatetimeInput,
        label: 'announcements.form.dateStart.label',
    },
    {
        name: 'dateEnd',
        component: DatetimeInput,
        label: 'announcements.form.dateEnd.label',
    },
];
