import React from 'react';
import { IInputComponentProps } from './input.component.props.interface';

export const TextAreaInput = (props: IInputComponentProps): JSX.Element => {
    return (
        <textarea
            id={`input-${props.name}`}
            name={props.name}
            value={props.value}
            onChange={props.onChange}
            className="form-control"
            disabled={props.readonly || false}
            placeholder={props.placeholder || null}
            rows={4}
        />
    );
};
