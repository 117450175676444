import React from 'react';
import {
    Modal,
    ModalBody,
    Spinner,
} from 'reactstrap/lib'; /* @HACK WebStorm doesn't recognize the Spinner export */

interface ISpinnerModalProps {
    active: boolean;
}

export const SpinnerModal = (props: ISpinnerModalProps): JSX.Element => {
    return (
        <Modal
            isOpen={props.active}
            className="text-center modal-dialog-centered"
        >
            <ModalBody>
                <Spinner color="primary" className="p-3 m-5" />
            </ModalBody>
        </Modal>
    );
};
