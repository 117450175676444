import React from 'react';
import { FormCard } from './card';
import { TextAreaInput } from './input/textarea.input';
import { Button, InputGroup, InputGroupAddon } from 'reactstrap';
import classnames from 'classnames';
import { TextInput } from './input/text.input';
import { Help } from './input/help';
import { translations } from '../../../translations/translations';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';

interface HoneypotFieldNameExtractorProps {
    locale: string;
}

interface HoneypotFieldNameExtractorState {
    honeypotContent: string;
    generatedHoneypotFieldName: string;
}

export class HoneypotFieldNameExtractor extends React.Component<HoneypotFieldNameExtractorProps> {
    state: HoneypotFieldNameExtractorState = {
        honeypotContent: '',
        generatedHoneypotFieldName: '',
    };

    onHoneypotExtractorChange = (e) => {
        this.setState({
            honeypotContent: e.currentTarget.value,
        });
        const re = /.*id="elq-FormLastRow.*?<input.*?name="(.*?)".*/s;
        const results = e.currentTarget.value.match(re);
        this.setState({
            generatedHoneypotFieldName:
                (results && results.length && results[1]) || '',
        });
    };

    render(): JSX.Element {
        const { locale } = this.props;

        return (
            <FormCard
                title={translations.formElement.section.honeypotHelper[locale]}
                collapsible
                name="honeypotExtractor"
            >
                <TextAreaInput
                    name={'honeypot-extractor'}
                    value={this.state.honeypotContent}
                    onChange={this.onHoneypotExtractorChange}
                    placeholder={
                        translations.formElement.field.spamProtection.helper
                            .placeholder[locale]
                    }
                />
                <InputGroup className={classnames('mb-2', 'readonly')}>
                    <InputGroupAddon addonType="prepend">
                        <label
                            className="input-group-text"
                            style={{ minWidth: '180px' }}
                            htmlFor={'honeypot-result'}
                        >
                            {
                                translations.formElement.field.spamProtection
                                    .helper.result[locale]
                            }
                        </label>
                    </InputGroupAddon>

                    <TextInput
                        name={'honeypot-result'}
                        readonly
                        onChange={() => null}
                        value={this.state.generatedHoneypotFieldName}
                    />

                    <Help
                        name={'honeypot-result'}
                        title={
                            translations.formElement.field.spamProtection.helper
                                .helpTitle
                        }
                        isInputAddon
                        locale={this.props.locale}
                        link={
                            translations.formElement.field.spamProtection.helper
                                .helpLink[locale]
                        }
                    />
                    <InputGroupAddon addonType="append">
                        <CopyToClipboard
                            text={this.state.generatedHoneypotFieldName}
                        >
                            <Button
                                outline={true}
                                color="primary"
                                aria-label={
                                    translations.formAction.copyToClipboard[
                                        this.props.locale
                                    ]
                                }
                                title={
                                    translations.formAction.copyToClipboard[
                                        this.props.locale
                                    ]
                                }
                            >
                                <FontAwesomeIcon icon={faCopy} />
                            </Button>
                        </CopyToClipboard>
                    </InputGroupAddon>
                </InputGroup>
            </FormCard>
        );
    }
}
