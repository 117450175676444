import { connect } from 'react-redux';
import React from 'react';
import { faPlus, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Badge,
    Button,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    UncontrolledDropdown,
    Navbar as BSNavbar,
} from 'reactstrap';
import { Link } from 'react-router-dom';

import logo from '../../logo.png';
import { IState } from '../../store/reducer';
import * as Routes from '../../routing/route';
import { FORM_EDIT_STATUS, getEditStatus } from '../../duck/form.edit.duck';
import { getUser, isAuthenticated } from '../../duck/auth.duck';
import { User } from 'oidc-react';
import { isAdmin } from '../../domain/model/user/user.roles';
import { AuthProviderContext } from '../../screen/auth.provider';
import { LocalizationContext } from '../localization/localization.provider';
import { alertAdd, AlertType } from '../../duck/alert.duck';
import { bindActionCreators } from 'redux';
import { AnnouncementList } from './announcement.list';

interface INavbarConnectProps {
    alertAdd: typeof alertAdd;
    editStatus: FORM_EDIT_STATUS;
    isAuthenticated: boolean;
    user: User | null;
}

type INavbarProps = INavbarConnectProps;

export class NavbarComponent extends React.Component<INavbarProps> {
    static contextType = LocalizationContext;

    getOnRenewLogin = (silentRenew: () => Promise<boolean>) => {
        return async (): Promise<void> => {
            const { translate } = this.context;

            const success = await silentRenew();
            const message = success
                ? 'auth.refresh.success'
                : 'auth.refresh.failure';
            const type = success ? AlertType.SECONDARY : AlertType.DANGER;

            this.props.alertAdd({
                message: translate(message),
                type,
                lifeSeconds: 5,
            });
        };
    };

    AuthControl = ({ userTypeColor, userTypeHint }) => {
        const adminEndpoint = process.env.REACT_APP_AUTH_ADMINISTRATION_URL;

        return (
            <AuthProviderContext.Consumer>
                {({ renewLogin }) => (
                    <UncontrolledDropdown>
                        <DropdownToggle tag={'span'} className={''}>
                            <Badge
                                color={userTypeColor}
                                className={'mr-4 clickable'}
                                title={userTypeHint}
                            >
                                <FontAwesomeIcon icon={faCheckCircle} />
                            </Badge>
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem header>
                                {this.props.user.profile.email}
                            </DropdownItem>
                            <DropdownItem
                                onClick={this.getOnRenewLogin(renewLogin)}
                            >
                                {this.context.translate('Renew login')}
                            </DropdownItem>
                            {adminEndpoint && (
                                <DropdownItem>
                                    <a
                                        href={adminEndpoint}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {this.context.translate(
                                            'Account management'
                                        )}
                                    </a>
                                </DropdownItem>
                            )}
                        </DropdownMenu>
                    </UncontrolledDropdown>
                )}
            </AuthProviderContext.Consumer>
        );
    };

    render(): JSX.Element {
        const { isAuthenticated, user } = this.props;
        const { translate } = this.context;

        let userTypeHint = '';
        let userTypeColor = null;

        userTypeHint = 'No authentication.';

        if (user === null) {
            userTypeHint = 'auth.user.badge.hint.fake';
            userTypeColor = AlertType.WARNING;
        } else if (isAdmin(user)) {
            userTypeHint = 'auth.user.badge.hint.admin';
            userTypeColor = AlertType.INFO;
        } else {
            userTypeHint = 'auth.user.badge.hint.user';
            userTypeColor = AlertType.SUCCESS;
        }

        userTypeHint =
            translate(userTypeHint) + translate('auth.user.badge.hint.suffix');

        return (
            <LocalizationContext.Consumer>
                {({ translate }) => (
                    <BSNavbar color="dark" dark={true} expand="md">
                        <div className="container">
                            <Link
                                className="navbar-brand"
                                to={{
                                    pathname: Routes.INDEX_PATH,
                                    state: { clearFormData: true },
                                }}
                            >
                                <img
                                    src={logo}
                                    height="15"
                                    width="80"
                                    className="d-inline-block align-baseline"
                                    alt="Haufe"
                                />{' '}
                                <span className="text-light">
                                    {translate('app.title')}
                                </span>
                            </Link>

                            <form className="form-inline text-right">
                                {isAuthenticated && (
                                    <this.AuthControl
                                        userTypeColor={userTypeColor}
                                        userTypeHint={userTypeHint}
                                    />
                                )}
                                <AnnouncementList />
                                <Link to={Routes.FORM_CREATE_PATH}>
                                    <Button
                                        color="success"
                                        aria-label={translate(
                                            'formAction.create'
                                        )}
                                        title={translate('formAction.create')}
                                    >
                                        <FontAwesomeIcon icon={faPlus} />
                                    </Button>
                                </Link>
                            </form>
                        </div>
                    </BSNavbar>
                )}
            </LocalizationContext.Consumer>
        );
    }
}

export const Navbar = connect(
    (state: IState) => ({
        editStatus: getEditStatus(state),
        isAuthenticated: isAuthenticated(state),
        user: getUser(state),
    }),
    (dispatch) =>
        bindActionCreators(
            {
                alertAdd,
            },
            dispatch
        )
)(NavbarComponent);
