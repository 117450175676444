import React from 'react';
import { ButtonGroup } from 'reactstrap';
import { faClone, faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import * as Routes from '../../../routing/route';
import { IFormListItem } from '../../../domain/model/form/form';
import { IAppProps } from '../form.list';
import { FormMove } from '../../directories/form.move';
import { CompactButton } from '../../compact.button';

export interface ListItemButtonsProps {
    compact?: boolean;
    item: IFormListItem;
}

const SmallButtons = (props: ListItemButtonsProps & Partial<IAppProps>) => {
    const { getPrimaryAction, item } = props;
    const primaryAction = getPrimaryAction();
    return (
        <>
            <CompactButton
                id={`form-duplicate-btn-${item.id}`}
                color="secondary"
                label={'formAction.duplicate'}
                icon={faClone}
                href={Routes.FORM_DUPLICATE_URL(item.id)}
            />

            <FormMove {...props} />

            <CompactButton
                id={`form-delete-btn-${item.id}`}
                color="danger"
                onClick={() => primaryAction(item.id)}
                label={'formAction.delete'}
                icon={faTrashAlt}
            />
        </>
    );
};

export const ListItemButtons = (
    props: ListItemButtonsProps & Partial<IAppProps>
): JSX.Element => {
    const { compact = false, item } = props;

    return (
        <ButtonGroup vertical={!compact}>
            <CompactButton
                id={`form-edit-btn-${item.id}`}
                color="info"
                label={'formAction.edit'}
                href={Routes.FORM_URL(item.id)}
                icon={faEdit}
            />

            {compact ? (
                <SmallButtons {...props} />
            ) : (
                <ButtonGroup>
                    <SmallButtons {...props} />
                </ButtonGroup>
            )}
        </ButtonGroup>
    );
};
