export const announcementTranslations = {
    manage: {
        de: 'Ankündigungen verwalten',
    },
    delete: {
        button: {
            de: 'Löschen',
        },
        confirm: {
            de: 'Ankündigung löschen? Diese Aktion kann nicht rückgängig gemacht werden.',
        },
    },
    edit: {
        button: {
            de: 'Bearbeiten',
        },
    },
    add: {
        de: 'Ankündigung hinzufügen',
    },
    form: {
        title: {
            label: {
                de: 'Titel',
            },
        },
        message: {
            label: {
                de: 'Textinhalt',
            },
        },
        type: {
            label: {
                de: 'Typ / Level',
            },
            options: {
                default: {
                    de: 'Standard (grün)',
                },
                warning: {
                    de: '"Warnung" (gelb)',
                },
                urgent: {
                    de: 'Dringend (rot)',
                },
            },
        },
        dateStart: {
            label: {
                de: 'Anzeigen ab',
            },
        },
        dateEnd: {
            label: {
                de: 'Anzeigen bis',
            },
        },
        url: {
            label: {
                de: 'URL für weiterführende Infos',
            },
        },
        submit: {
            button: {
                de: 'Speichern',
            },
            success: {
                de: 'Ankündigung erfolgreich gespeichert.',
            },
        },
    },
    error: {
        submit_fail: {
            de: 'Ankündigung konnte nicht angelegt / aktualisiert werden. Bitte Eingaben überprüfen.',
        },
    },
};
