import React from 'react';
import { IInputComponentProps } from './input.component.props.interface';

export const SelectInput = (props: IInputComponentProps): JSX.Element => {
    const { name, selectOptions, onChange, readonly, value } = props;

    return (
        <select
            id={`input-${name}`}
            name={name}
            value={value || ''}
            onChange={onChange}
            className="form-control custom-select"
            disabled={readonly || false}
        >
            {selectOptions.map((opt) => {
                const { label, value } = opt;
                return (
                    <option key={label} value={value}>
                        {label}
                    </option>
                );
            })}
        </select>
    );
};
